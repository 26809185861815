import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import OrderAPI from "../../BL";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { typeOf } from "mathjs";
var moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");
export default function OrderEntryForm({
  order,
  hscodes,
  handleClose,
  isAddMore,
}) {
  var obj = new OrderAPI();
  const {
    order_customer_id,
    order_customer_name,
    order_item_code,
    order_vendor_code,
    order_id,
  } = typeof order.order_id === "object" ? order.order_id : order;
  const intialState = {
    hscodes: hscodes,
    order_number: "",
    order_date: today,
    order_demand_date: today,
    order_item_code: order_item_code,
    order_vendor_code: order_vendor_code,
    orderMsg: "",
    order_generated_by: "Test",
    order_updated_by: "Test",
    order_particullars: "",
    order_customer_id: order_customer_id,
    order_id: order_id === undefined ? 0 : order_id,
    order_hscode: -1,
    order_customer_name: order_customer_name,
    InvBuyerAddr: "",
    InvBuyerPhone: "",
    InvBuyerSTRN: "",
    InvBuyerNTN: "",
    order_qty_kg: "Pcs",
    order_price:0,
    schedule: [{ order_demand_date: today, order_qty: "" }],
  };
  const classes = useStyles();
  const [values, setValues] = useState(intialState);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (values.InvBuyerName == "") {
      alert("Please Select Client!");
      event.preventDefault();
      return false;
    }
    if (values.order_hscode == "-1") {
      alert("Please Select HSCode!");
      event.preventDefault();
      return false;
    }

    setValues({
      ...values,
      orderMsg: values.order_id != 0 ? "Updating..." : "Generating...",
      prevOrderDate: values.orderDate1,
    });
    if (values.order_id != 0) {
      obj
        .UpdateOrder({
          ...values,
          order_schedule: values.schedule
            .map((m) => m.order_demand_date + "|" + m.order_qty)
            .join(","),
          order_qty: values.schedule.reduce(
            (m, n) => parseFloat(m) + parseFloat(n.order_qty),
            0
          ),
        })
        .then(() => handleClose(true))
        .catch((err) => alert(err));
    } else {
      obj
        .AddOrder({
          ...values,
          order_schedule: values.schedule
            .map((m) => m.order_demand_date + "|" + m.order_qty)
            .join(","),
          order_qty: values.schedule.reduce(
            (m, n) => parseFloat(m) + parseFloat(n.order_qty),
            0
          ),
        })
        .then(() => handleClose(true))
        .catch((err) => alert(err));
    }
  };

  const handleSelectHSCode = (event) => {
    const hscode = values.hscodes.filter(
      (c) => c.value == event.target.value
    )[0];
    if (event.target.value !== -1) {
      setValues({
        ...values,
        order_particullars: `${hscode.text}`,
        order_hscode: hscode.value,
      });
    }
  };

  const handleChange = (name) => (event) => {
    try {
      values[name] = event.target.value;

      setValues({ ...values, [name]: event.target.value });
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleChangeSch = (name, idx) => (event) => {
    try {
      const _temp = [...values.schedule];
      _temp[idx][name] = event.target.value;

      setValues({ ...values, schedule: [..._temp] });
    } catch (ex) {
      console.log(ex);
    }
  };
  const addDate = () => {
    const _temp = [...values.schedule];

    _temp.push({ order_demand_date: today, order_qty: "" });

    setValues({ ...values, schedule: [..._temp] });
  };
  const deleteDate = (idx) => {
    const _temp = [...values.schedule];

    _temp.splice(idx);

    setValues({ ...values, schedule: [..._temp] });
  };
  useEffect(() => {
    console.log("useEffect");
    //  var c = [];
    // var h = [];
    //  fetchHSCodes();
    if (values.order_id != 0) {
      fetchorderByID();
    } else {
    }

    async function fetchorderByID() {
      console.log("fetchorderByID");
      obj
        .getorderByID(order_id)
        .then((result) => {
          setValues({
            ...values,
            order_price:
            typeof order.order_id === "object" ? "" : result.order_price,
            order_qty:
              typeof order.order_id === "object" ? "" : result.order_qty,
            order_qty_kg:
              typeof order.order_id === "object" ? "" : result.order_qty_kg,
            order_number: result.order_number,
            order_date: result.order_date.split("T")[0],
            order_demand_date: result.order_demand_date.split("T")[0],
            order_particullars:
              typeof order.order_id === "object"
                ? ""
                : result.order_particullars,
            order_item_code: result.order_item_code,
            order_hscode:
              typeof order.order_id === "object" ? -1 : result.order_hscode,
            order_id: typeof order.order_id === "object" ? 0 : result.order_id,
            schedule:
              typeof order.order_id === "object"
                ? [{ order_demand_date: today, order_qty: "" }]
                : result.order_schedule.split(",").map((m) => ({
                    order_demand_date: m.split("|")[0],
                    order_qty: m.split("|")[1],
                  })),
          });
        })
        .catch((err) => alert(err));
    }
  }, []);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={true}

      //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        New Order For: {order_customer_name}{" "}
        <Avatar
          alt="Remy Sharp"
          style={{ float: "right" }}
          className={classes.orange}
        >
          O
        </Avatar>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "lightblue" }} dividers>
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <TextField
                  required
                  id="orderNumber"
                  name="orderNumber"
                  label="Order Number"
                  fullWidth
                  value={values.order_number}
                  onChange={handleChange("order_number")}
                />
              </Grid>
              <Grid item xs={4} sm={3}>
                <TextField
                  required
                  label="Order Date"
                  fullWidth
                  type="date"
                  value={values.order_date}
                  onChange={handleChange("order_date")}
                />
              </Grid>

              <Grid item xs={4} sm={3}>
                <TextField
                  label="Item Code"
                  fullWidth
                  autoComplete="order_item_code"
                  value={values.order_item_code}
                  onChange={handleChange("order_item_code")}
                />
              </Grid>

              <Grid item xs={2} sm={2}>
                <TextField
                  required
                  label="Pcs or KG"
                  value={values.order_qty_kg}
                  onChange={handleChange("order_qty_kg")}
                />
              </Grid>
              <Grid item xs={4} sm={4} style={{ paddingTop: "28px" }}>
                <NativeSelect
                  value={values.order_hscode}
                  required
                  onChange={handleSelectHSCode}
                >
                  <option value="-1">Please Select HSCode</option>
                  {values.hscodes.map((d, idx) => {
                    return (
                      <option value={d.value} key={idx}>
                        {d.text}
                      </option>
                    );
                  })}
                </NativeSelect>
              </Grid>

              <Grid item xs={4} sm={4}>
                <TextField
                  required
                  label="Particullars"
                  fullWidth
                  value={values.order_particullars}
                  onChange={handleChange("order_particullars")}
                />
              </Grid>

              <Grid item xs={4} sm={4}>
                        <TextField
                          required
                          inputProps={{
                            style: { textAlign: "right" },
                          }}
                          label="Rate"
                          fullWidth
                          type="number"
                          value={values.order_price}
                          onChange={handleChange("order_price")}
                        />
                      </Grid>
              <Grid item xs={8} sm={8}>
                <Grid container spacing={3}>
                  {values.schedule.map((m, idx) => (
                    <>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          required
                          label="Delivery Date"
                          fullWidth
                          type="date"
                          value={m.order_demand_date}
                          onChange={handleChangeSch("order_demand_date", idx)}
                        />
                      </Grid>

                      <Grid item xs={5} sm={5}>
                        <TextField
                          required
                          inputProps={{
                            style: { textAlign: "right" },
                          }}
                          label="Qty/KG"
                          fullWidth
                          type="number"
                          value={m.order_qty}
                          onChange={handleChangeSch("order_qty", idx)}
                        />
                      </Grid>
              
                      <Grid item xs={1} sm={1}>
                        <IconButton
                          color="primary"
                          aria-label="Delete Date."
                          component="span"
                          size="small"
                          title="Delete Date."
                          onClick={() => deleteDate(idx)}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4}>
                <IconButton
                  color="primary"
                  aria-label="Add More"
                  component="span"
                  size="small"
                  title="Add More."
                  onClick={() => addDate()}
                >
                  <Add />
                </IconButton>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                >
                  {values.order_id != 0 && typeof order.order_id !== "object"
                    ? "Update"
                    : "Submit"}
                </Button>
                &nbsp;&nbsp;
                <FormLabel>{values.orderMsg}</FormLabel>
              </Grid>
            </Grid>
          </form>
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
