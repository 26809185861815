import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import OrderList from "./order_list";
import OrderEntryForm from "./entryform";
import ChallanPopup from "../challan/challan_popup";
import BillPopup from "../bill/bill_popup";
import OrderAPI, { ChallanAPI, CustomersAPI, HSCodesAPI } from "../../BL";
export default function Order({}) {
  const objOrderAPI = new OrderAPI();
  const objChallanAPI = new ChallanAPI();
  const objCustomersAPI = new CustomersAPI();
  const objHSCodesAPI = new HSCodesAPI();
  
  const [loadAgain, setloadAgain] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [hscodes, setHscodes] = useState([]);
  const intialState = {
    //customer_id:-1,// 163, //-1
    order: {
      order_customer_id: -1,
      order_customer_name: "",
      order_item_code: "",
      order_vendor_code: "",
      order_id: 0,
      order_number: 0,
      order_qty_kg:"Pcs"
    },
    customer: {
      InvBuyerName: "",
      InvBuyerAddr: "",
      InvBuyerPhone: "",
      InvBuyerSTRN: "",
      InvBuyerNTN: "",
    },
    bill_id: 0,
    show: false,
    showChallan: false,
    loadAgain: false,
    showInvoice: false,
    popup:'new'
  };

  const [values, setValues] = useState(intialState);
// useEffect(()=>{
//   if(customers.length>0)
//  setCustomerValues(132);
// },[customers])

  useEffect(() => {
    fetchCustomer();
    fetchHSCodes();
   
  }, []);
  function fetchCustomer() {
    objCustomersAPI
      .getCustomers()
      .then((customers) => {
        setCustomers(customers);
      })
      
      .catch((err) => alert(err));
  }
  function fetchHSCodes() {
    objHSCodesAPI
      .getHSCodes()
      .then((hscode) => setHscodes(hscode))
      .catch((err) => alert(err));
  }
  const handleClose = (isLoadAgain) => {
    
    setValues({
      ...values,
      show: false,
      order: { ...values.order, order_id: 0 },
      showChallan: false,
      showInvoice: false,
    });
    if (isLoadAgain) {
      setloadAgain(!loadAgain);
    }
  };
  const handleSelect = (event) => {
    //console.log(custs[event.target.value])
    if (event.target.value !== -1) {
      setCustomerValues(event.target.value);
    }
  };
  const setCustomerValues = (cust_id) => {
    if(cust_id==-1){
      setValues({...values,order:{...values.order, order_customer_id:-1}});
      return false;
    }
    

    const cust = customers.filter((c) => c.cust_id == cust_id)[0];

    setValues({
      ...values,
      order: {
        order_item_code:
          values.order.order_id != 0
            ? values.order.order_item_code
            : cust.cust_itemcode,
        order_vendor_code: cust.cust_vendorcode,
        order_customer_name: cust.cust_name,
        order_customer_id: cust.cust_id,
        challan_id: 0,
        order_number: 0,
        order_qty_kg:"Pcs"
      },
      customer: {
        InvBuyerName: cust.cust_name,
        InvBuyerAddr: cust.cust_addr,
        InvBuyerPhone: cust.cust_phone,
        InvBuyerSTRN: cust.cust_strn,
        InvBuyerNTN: cust.cust_ntn,
      },
    });
  };
  const handleEdit = (order_id) => {
    //alert(order_id)
    
    setValues({ ...values, show: true, order: { ...values.order, order_id } });
  };
  const handleAddChallan = (order) => {
    //alert(order_id)

    setValues({ ...values, showChallan: true, order });
  };
  const handleAddInvoice = (order) => {
    console.log(order)
    setValues({ ...values,bill_id:0 ,showInvoice: true, order });
  };

  const handleUpdateChallan = (challan_id, order_number) => {
    setValues({
      ...values,
      showChallan: true,
      order: { ...values.order, challan_id, order_number },
    });
  };
  const handleUpdateBill = (bill_id, order) => {
    setValues({
      ...values,
      showInvoice: true,
      bill_id: bill_id,
      order: order,
      
    });
  };
  const openPopup = () => {
   
    if (values.order.order_customer_id <1) {
      alert("Please select customer!");
      return false;
    }
    setValues({
      ...values,
      show: true,
      order: { ...values.order, order_id: 0 },
    
    });
  };
  return (
    <div>
      {customers.length == 0 || hscodes.length == 0 ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <NativeSelect
                value={values.order_customer_id}
                required
                onChange={handleSelect}
              >
                <option value="-1" >Please Select Client</option>
                {customers.map((d, idx) => {
                  return (
                    <option value={d.cust_id} key={idx}>
                      {d.cust_name}
                    </option>
                  );
                })}
              </NativeSelect>
            </Grid>
            <Grid item xs={1} >
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={openPopup}
              >
                Add Order
              </Button>
            </Grid>
          </Grid>

          {values.show && (
            <OrderEntryForm
              handleClose={handleClose}
              order={values.order}
              hscodes={hscodes}
            ></OrderEntryForm>
          )}
          {values.showChallan && (
            <ChallanPopup
              handleClose={handleClose}
              order={values.order}
            ></ChallanPopup>
          )}
          {values.showInvoice && (
            <BillPopup
              handleClose={handleClose}
              order={values.order}
              customer={values.customer}
              billID={values.bill_id}
            ></BillPopup>
          )}
          <hr />

          <OrderList
            loadAgain={loadAgain}
            order_customer_id={values.order.order_customer_id}
            handleEdit={handleEdit}
            handleAddChallan={handleAddChallan}
            handleUpdateChallan={handleUpdateChallan}
            handleAddInvoice={handleAddInvoice}
            handleUpdateBill={handleUpdateBill}
          ></OrderList>
        </div>
      )}
    </div>
  );
}
