import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Popup } from "./emailpopup";
import { Sum } from "../util/mathUtil";
import moment from "moment";
import numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import API from '../../config';
const useStyles = makeStyles({
  colorH: {
    backgroundColor: "#795548",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  colorH1: {
    backgroundColor: "#d26936",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  color1: {
    backgroundColor: "#d7ccc8"
  },
  color2: {
    backgroundColor: "#efebe9"
  },
  color3: {
    backgroundColor: "white"
  },
  color4: {
    backgroundColor: "#e5e872"
  },
  Hyperlink: {
    textDecoration: "underline",
    color: "blue",
    fontSize: "10px",
    cursor: "pointer"
  }
});
function makeLink(lnk) {
  return "/server/PDF/" + lnk + ".pdf";
}
export default function Payments({customers}) {
  const [show, setShow] = React.useState(false);
  const [values, setValues] = useState({
    billDate1: moment().subtract(1, 'year').format("YYYY-07-01"),
      billDate2: moment().format("YYYY-06-30"), 
    incomeTaxVal: 4,
    selectedCustomer: "",
    custs: customers,
    list: [],
    cheques: [],
    loading: false,
    selectedCheques: [],
    filename: "",
    subject: "",
    summary: {
      total: 0,
      tax: 0,
      received: 0,
      remain: 0
    },
    tempRow: {
      bill_invoice_num: "",
      bill_total: 0,
      bill_tax: 0,
      bill_paid: 0,
      bill_qty: 0,
      bill_hscode: ""
    }
  });
  const classes = useStyles();

  const fetchData = () => {
    setValues({ ...values, loading: true });

    axios
      .all([
        axios.post(
          `${API}/cheque/getChequesByCust`,
          {
            cust_id: values.selectedCustomer
          }
        ),
        axios.post(
         `${API}/reports/getPaymentsReport`,
          {
            cust_id: values.selectedCustomer,
            from_date: values.billDate1,
            to_date: values.billDate2
          }
        )
      ])
      .then(
        axios.spread((res1, res2) => {
          let cust = values.custs.filter(
            f => f.cust_id == values.selectedCustomer
          )[0];
          var cheques = res1.data;
          var list = res2.data.map((m)=>{
            m.bill_total = m.chbill_description==null || m.chbill_description=="" ?m.bill_total:0;
         return  m;
          });
          var tempItem = {};
          tempItem.bill_id = 0;
          tempItem.bill_invoice_num = 0;
          tempItem.bill_total = 0;
          tempItem.bill_tax = 0;
          tempItem.bill_paid = 0;
          tempItem.bill_qty = 0;
          tempItem.bill_price = 0;
          // tempItem.bill_generated_on=0;
          tempItem.bill_pdf_path = "";
          // tempItem.bill_date1=0;
          // tempItem.bill_date2=0;
          tempItem.bill_client_name = cust.cust_name;
          tempItem.bill_cust_id = cust.cust_id;
          tempItem.ch_id = -1;
          tempItem.chbill_id = -1;
          tempItem.paid = 0;
          tempItem.chbill_bill_id = -1;
          tempItem.chbill_tax = 0;
          tempItem.ch_id = -1;
          list.unshift(tempItem);
          var selectedCheques = cheques.filter(
            c => c.ch_cust_id == values.selectedCustomer && c.remaining != 0
          );

          setValues({
            ...values,
            list,
            loading: false,
            selectedCheques,
            cheques,
            summary: {
              total: Sum(list, "bill_total", true),
              tax: Sum(list, "bill_tax", true),
              received: Sum(list, "bill_paid", true),
              remain:
                Sum(list, "bill_total", false) -
                Sum(list, "bill_paid", false) -
                Sum(list, "bill_tax", false)
            }
          });
        })
      );
  };

  const handleSubmit = () => {
    fetchData();
  };

  const handlePDF = () => {
    // setShow(true);
    let cust = values.custs.filter(
      f => f.cust_id == values.selectedCustomer
    )[0];

    axios
    .post(`${API}/reports/getPaymentPDF`, {
     // .post("https://admin.nafeesenter.com/server/reports/getPaymentPDF", {
        data: values.list,
        cust,
        cheques: values.cheques,
        from_date: values.billDate1,
        to_date: values.billDate2
      })
      .then(res => {
        window.open(
          `${API}/PDF/reports/${res.data.filename}.pdf`,
          "_blank"
        );
        // setValues({...values, filename:res.data.filename, subject:`Ledger Nafees Enterprises [${values.billDate1} - ${values.billDate2}]`})
      })
      .catch(err => alert(err));
  };
  const handleReceive = idx => {
    var b = values.list[idx];
    var ch = values.selectedCheques.filter(f => f.ch_id == b.ch_id)[0];

    if (b.ch_id == null && b.bill_invoice_num < 0) {
      alert("Please select cheque!!!");
      return;
    }
    if (b.bill_invoice_num > 0) {
      if (b.bill_paid > ch.remaining) {
        alert(
          `You entered more than the remaining amount (${ch.remaining}) !!!`
        );
        return;
      }

      if (b.bill_paid == 0) {
        alert("Please enter amount!!!");
        return;
      }
    }

    var numbers = /^[0-9]+$/;
var a=b.bill_invoice_num;


    if (b.bill_total < b.bill_paid && a.replace('-','').match(numbers)) {
      alert(`Please enter amount less than bill amount (${b.bill_total})!!!`);
      return;
    }
    if (b.bill_id != 0) {
      axios
        .post(`${API}/cheque/chequePayment`, {
          paid: b.bill_paid,
          ch_id: b.ch_id,
          chbill_bill_id: b.bill_id,
          chbill_cheque_id: b.ch_id,
          chbill_amount: b.bill_paid,
          chbill_status: 1,
          chbill_description: "",
          chbill_tax: b.bill_tax
        })
        .then(() => {
          fetchData();
        });
    } else {
      axios
        .post(
          `${API}/cheque/addBillAndChequePayment`,
          {
            paid: b.bill_paid,
            bill_total: b.bill_total,
            bill_invoice_num: b.bill_invoice_num,
            bill_cust_id: b.bill_cust_id,
            bill_client_name: b.bill_client_name,
            bill_qty: b.bill_qty,
            bill_hscode: b.bill_hscode,
            ch_id: b.ch_id,
            chbill_cheque_id: b.ch_id,
            chbill_amount: b.bill_paid,
            chbill_status: 1,
            chbill_description: "",
            chbill_tax: b.bill_tax
          }
        )
        .then(d => {
          console.log(d);
          fetchData();
        });
    }
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };



  const handleSelect = event => {
    if (event.target.value !== -1) {
      setValues({ ...values, selectedCustomer: event.target.value });
    }
  };
  const handleCheque = idx => event => {
    var d = values.list;
    if (idx === -1) {
    } else if (event.target.value !== -1) {
      d[idx].ch_id = event.target.value;
      setValues({ ...values, list: d });
    }
  };
  const setTax = idx => event => {
    var d = values.list;

    d[idx].bill_tax = d[idx].bill_total - d[idx].bill_paid;
    setValues({ ...values, list: d });
  };
  const setPaid = idx => event => {
    var d = values.list;

    d[idx].bill_paid = d[idx].bill_total - d[idx].bill_tax;
    setValues({ ...values, list: d });
  };
  const setPaidandTax = idx => event => {
    var d = values.list;
    var ch = values.selectedCheques.filter(f => f.ch_id == d[idx].ch_id)[0];
    d[idx].bill_paid = ch.remaining;
    d[idx].bill_tax = d[idx].bill_total - d[idx].bill_paid;
    setValues({ ...values, list: d });
  };
  function setReceived(idx) {
    var d = values.list;

    d[idx].bill_paid = d[idx].bill_total - d[idx].bill_tax;
    setValues({ ...values, list: d });
  }

  const updatebill_tax = idx => event => {
    var d = values.list;
    d[idx].bill_tax = event.target.value;
    setValues({ ...values, list: d });
  };

  const updatebill_invoice_num = idx => event => {
    var d = values.list;
    d[idx].bill_invoice_num = event.target.value;
    setValues({ ...values, list: d });
  };
  const updatebill_total = idx => event => {
    var d = values.list;
    d[idx].bill_total = event.target.value;
    setValues({ ...values, list: d });
  };
  const updatebill_hscode = idx => event => {
    var d = values.list;
    d[idx].bill_hscode = event.target.value;
    setValues({ ...values, list: d });
  };
  const updatebill_qty = idx => event => {
    var d = values.list;
    d[idx].bill_qty = event.target.value;
    setValues({ ...values, list: d });
  };
  const updatebill_paid = idx => event => {
    var d = values.list;
    d[idx].bill_paid = event.target.value;
    setValues({ ...values, list: d });
  };

  const deletePayment = idx => event => {
    if (!window.confirm("Do you want to delete this payment?")) {
      return false;
    }

    var b = values.list[idx];

    axios
      .post(`${API}/invoice/deletePayment/`, {
        chbill_id: b.chbill_id
      })
      .then(function(response) {
        // fetchCheques()
        fetchData();
      })
      .catch(function(error) {
        // message = error;
        console.log(error);
      });
    //  d[idx].bill_paid = event.target.value;
    //  setValues({ ...values, list: d });
  };
  function handleClose(newValue) {
    setShow(false);
  }
  const openPopup = () => {
    setShow(true);
  };

  return (
    <div>
      {show && (
        <Popup
          handleClose={handleClose}
          filename={values.filename}
          subject={values.subject}
        ></Popup>
      )}
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <NativeSelect className="width50" required onChange={handleSelect}>
            <option key={-1} value={-1}>
              Please Select Client
            </option>
            {values.custs.map((d, idx) => {
              return (
                <option value={d.cust_id} key={idx}>
                  {d.cust_name}
                </option>
              );
            })}
          </NativeSelect>
        </Grid>
        <Grid item xs={2}>
          <TextField
            style={{}}
            required
            label="From"
            fullWidth
            type="date"
            value={values.billDate1}
            onChange={handleChange("billDate1")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            style={{}}
            required
            label="To"
            fullWidth
            type="date"
            value={values.billDate2}
            onChange={handleChange("billDate2")}
          />
        </Grid>

        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}

            // className={classes.button}
          >
            Generate
          </Button>
          &nbsp;&nbsp;
          
          {values.loading && <b>Loading...</b>}
        </Grid>
      </Grid>
      <hr></hr>

      <Grid item xs={12}>
        {values.list.length > 0 ? (
          <Paper>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handlePDF}

              // className={classes.button}
            >
              Show PDF
            </Button>
            <hr></hr>
            <Table size="small">
              <TableHead>
                <TableRow className={classes.colorH}>
                  <TableCell align="center" style={{ width: "70px" }}>
                    Inv. #
                  </TableCell>
                  <TableCell align="center">Inv. Amount</TableCell>
                  <TableCell align="center">Income Tax</TableCell>
                  <TableCell align="center">Received</TableCell>
                  <TableCell align="center">Cheque #</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Bank</TableCell>
                  <TableCell align="center">Cheque Amount</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
                <TableRow className={classes.colorH1}>
                  <TableCell style={{ width: "70px" }}>
                    {values.list.length}
                  </TableCell>
                  <TableCell align="right">{values.summary.total}</TableCell>
                  <TableCell align="right">{values.summary.tax}</TableCell>
                  <TableCell align="right">
                    {values.summary.received} |{" "}
                    <span style={{ color: "#795548" }}>
                      {numeral(values.summary.remain).format("0,0")}
                    </span>{" "}
                  </TableCell>
                  <TableCell>{values.cheques.length}</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                    {Sum(values.cheques, "ch_amount", true)}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.list.map((rowData, idx) =>
                  rowData.ch_number != null ? (
                    <TableRow key={rowData.bill_id}>
                      <TableCell className={classes.color2}>
                        <a
                          rel="noopener noreferrer"
                          href={makeLink(rowData.bill_pdf_path)}
                          target="_blank">
                          {rowData.bill_invoice_num}
                        </a>
                      </TableCell>
                      <TableCell className={classes.color2} align="right">
                        {numeral(rowData.bill_total).format("0,0")}
                      </TableCell>
                      <TableCell className={classes.color2} align="right">
                        {numeral(rowData.bill_tax).format("0,0")}
                      </TableCell>
                      <TableCell className={classes.color2} align="right">
                        {numeral(rowData.bill_paid).format("0,0")}
                      </TableCell>

                      <TableCell className={classes.color1}>
                        {rowData.ch_number}
                      </TableCell>
                      <TableCell className={classes.color1}>
                        {moment(rowData.ch_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell className={classes.color1}>
                        {rowData.ch_bank}
                      </TableCell>
                      <TableCell className={classes.color1} align="right">
                        {numeral(rowData.ch_amount).format("0,0")}
                      </TableCell>
                      <TableCell className={classes.color1}>
                        <b
                          className={classes.Hyperlink}
                          onClick={deletePayment(idx)}
                        >
                          {" "}
                          Delete
                        </b>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={rowData.bill_id}>
                      <TableCell className={classes.color3}>
                        {rowData.bill_id === 0 ? (
                          <TextField
                            style={{ textAlign: "right", width: "50px" }}
                            value={rowData.bill_invoice_num}
                            onChange={updatebill_invoice_num(idx)}
                          />
                        ) : (
                          <a
                            rel="noopener noreferrer"
                            href={makeLink(rowData.bill_pdf_path)}
                            target="_blank"
                          >
                            {rowData.bill_invoice_num}
                          </a>
                        )}
                      </TableCell>
                      <TableCell className={classes.color3} align="right">
                        {rowData.bill_id === 0 ? (
                          <TextField
                            style={{ textAlign: "right", width: "100px" }}
                            fullWidth
                            value={rowData.bill_total}
                            onChange={updatebill_total(idx)}
                          />
                        ) : (
                          <span
                            align="right"
                            onClick={() => setReceived(idx)}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer"
                            }}
                          >
                            {numeral(rowData.bill_total).format("0,0")}
                          </span>
                        )}
                      </TableCell>
                      <TableCell className={classes.color3} align="right">
                        <TextField
                          style={{ textAlign: "right", width: "100px" }}
                          fullWidth
                          value={rowData.bill_tax}
                          onChange={updatebill_tax(idx)}
                        />
                        <b onClick={setTax(idx)} style={{ cursor: "pointer" }}>
                          ?
                        </b>
                      </TableCell>
                      <TableCell className={classes.color3} align="right">
                        <TextField
                          style={{ textAlign: "right", width: "100px" }}
                          fullWidth
                          value={rowData.bill_paid}
                          onChange={updatebill_paid(idx)}
                        />
                        <b onClick={setPaid(idx)} style={{ cursor: "pointer" }}>
                          ?
                        </b>
                        &nbsp;&nbsp;
                        <b
                          onClick={setPaidandTax(idx)}
                          style={{
                            fontSize: "18px",
                            color: "blue",
                            cursor: "pointer"
                          }}
                        >
                          ?
                        </b>
                      </TableCell>

                      <TableCell className={classes.color3} colSpan="5">
                        {rowData.bill_id === 0 ? (
                          <span>
                            QTY:
                            <TextField
                              style={{ textAlign: "right", width: "100px" }}
                              value={rowData.bill_qty}
                              onChange={updatebill_qty(idx)}
                            />
                            HSC:
                            <TextField
                              style={{ textAlign: "right", width: "100px" }}
                              value={rowData.bill_hscode}
                              onChange={updatebill_hscode(idx)}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                        <NativeSelect
                          className="width50"
                          required
                          onChange={handleCheque(idx)}
                        >
                          <option key={-1} value={-1}>
                            Please Select Cheque
                          </option>
                          {values.selectedCheques.map(d => {
                            return (
                              <option value={d.ch_id} key={d.ch_id}>
                                {`${d.ch_number} | Amount: ${d.ch_amount} | Remaining: ${d.remaining}`}
                              </option>
                            );
                          })}
                        </NativeSelect>

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleReceive(idx)}

                          // className={classes.button}
                        >
                          Receive
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </Paper>
        ) : (
          <b></b>
        )}
      </Grid>
    </div>
  );
}
