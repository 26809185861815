import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import { useInput } from '../hooks/input-hook';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import API from '../../config';


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export const Popup = ({ filename,subject,handleClose }) => {
  const classes = useStyles();
  const { value: toEmail, bind: bindToEmail, reset: resetToEmail } = useInput('nafeesenter@gmail.com');
  const [open, setOpen] = useState(true);

  function handleCancel(newValue) {

    setOpen(false);
    handleClose()
  }
  function handleUpdate(value) {
    // updateList(value)
    // setOpen(false);
    handleClose()
  }
  const handleSend = (evt) => {
    evt.preventDefault()
   // 

  axios.post( `${API}/reports/sendEmailPaymentsReport`,{
    subject:subject,
    toEmail:toEmail,
    pdfName:filename
  }).then((d)=>{console.log(d.data)}).catch((ex)=>{alert(ex);console.log(ex)})
  }
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Send Email
      </DialogTitle>
      <DialogContent dividers>
        <form className={classes.form} noValidate={false} onSubmit={handleSend}>
        <Grid container justify="space-between" spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="toEmail"
            label="To Email"
            name="toEmail"
            autoFocus
            {...bindToEmail}></TextField>
        
        </Grid>
        <Grid item xs={8}>
          <a href={`${API}/PDF/Reports/${filename}.pdf`} target="blank">Download PDF</a>
        </Grid>
        <Grid item xs={4} justify="flex-end" alignContent="flex-end" alignItems="baseline" >
          <Button 
          type="submit"
            variant="contained"
            color="primary"
            size="small" 
            fullWidth
         >
            Send
            </Button>
            </Grid>
       </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="primary">
          Cancel
        </Button>
        {/* <Button
        //  onClick={handleOk} 
         color="primary">
          Ok
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
