import React, { useCallback} from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useInput } from './hooks/input-hook';
import axios from 'axios';
import { useStore } from "../views/store/useStore";
import { FormLabel } from "@material-ui/core";
import history from './store/history';
import API from '../config';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://nafeesenter.com/">
        Nafees Enterprises
      </Link>{' '}
      {new Date().getFullYear()}
      {/* {'. Built with '} */}
      {/* <Link color="inherit" href="https://material-ui.com/">
        Material-UI.
      </Link> */}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const { value:LoginId, bind:bindLoginId, reset:resetLoginId } = useInput('');
  const { value:Password, bind:bindPassword, reset:resetPassword } = useInput('');
  const { value:message, setValue:setValueMessage} = useInput('');
  const { state, dispatch } = useStore();
  const login = useCallback(() => dispatch({ type: "login" }), [dispatch]);
  const logout = useCallback(() => dispatch({ type: "logout" }), [dispatch]);
  const handleSubmit = (evt) => {
    
    evt.preventDefault();
    const innerHTML = evt.target?.innerHTML;
    setValueMessage("Signing...");
     // alert(`Submitting Name ${LoginId} ${Password}`);
     axios.post(`${API}/auth/login/`, {

      loginid: LoginId,
    password: Password,
    })
    .then(function (response) {
     // console.log(localStorage.getItem( 'loggedIn' ) )
      if(response.data.length){
       // history.push('/checkout');
       state.user.loggedIn = logout();
    //   localStorage.setItem( 'loggedIn', "Out" );
        setValueMessage("Wrong ID/Pssword!");
     //   console.log(message)
      }
      if(response.data){
        //localStorage.setItem( 'loggedIn', "In" );
        state.user.loggedIn = login();
   if(innerHTML==="Sign In (Non-Tax)"){
    history.push('/main_nontax');
   }
   else {
    history.push('/main');
   }
      
        setValueMessage("good!");
        resetLoginId();
        resetPassword();
      }
    //  console.log(response);
    })
    .catch(function (error) {
      setValueMessage(""+error);
     console.log(error);
    });
     
}

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate={false}  onSubmit={handleSubmit} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userid"
            label="User ID"
            name="userid"
            autoFocus
            {...bindLoginId}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...bindPassword}
          />
         

         <FormLabel>{message}</FormLabel>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          
          >
            Sign In
          </Button>
          
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="success"
            onClick={handleSubmit}
          
          >
          Sign In (Non-Tax)
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
       <Box mt={8}>
        <Copyright />
      </Box> 
    </Container>
  );
}