import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import API from '../../config';
import moment from "moment";
import numeral from "numeral";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});
function makeLink(lnk) {
  return "/server/PDF/" + lnk + ".pdf";
}
export default function BillList({ cheque, handleCancel }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState("");

  const [IsPending, setIsPending] = useState(true);
  const remain = parseFloat(cheque.remaining).toFixed(2);
  useEffect(() => {
    fetchList();
  }, []);

  const updateData = idx => event => {
   
    var d = data;
    d[idx].paid = event.target.value;
    setData([...d]);
  };
  const updateTax = idx => event => {

    var d = data;
    d[idx].tax = event.target.value;
    setData([...d]);
  };
  async function fetchList() {
    setLoading("Loading...");
    const result = await axios.post(
      `${API}/invoice/getbillsByCust`,
      {
        bill_cust_id: cheque.ch_cust_id
      }
    );
    setLoading("");

    setData(result.data);
  }

  function setReceived(idx, event) {
    var d = data;
    d[idx].paid = d[idx].bill_total - d[idx].tax;
  
    setData([...d]);
  }
 
  function paymentReceive(){
   var d= data.filter(f=>f.paid!=0);
var res = d.map(m=>parseFloat(m.paid)).reduce((sum,x)=>sum+x);
if(res>remain){
  alert("Your entered amount is exceed the cheque's remain amount!!!");
  return;
}
setLoading("Saving...");
   var cnt = 0;
d.forEach((b)=>{

   axios
  .post(`${API}/cheque/chequePayment`, {
    paid: b.paid,
    ch_id: cheque.ch_id,
    chbill_bill_id:b.bill_id,
    chbill_cheque_id: cheque.ch_id,
    chbill_amount: b.paid,
    chbill_status: 1,
    chbill_description: "",
    chbill_tax:b.tax
  })
  .then(() => {
    cnt++;
    if(cnt==d.length){
      
      handleCancel();
      return;

    }

  });
});

  }

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox color="secondary" name="IsPending" checked={IsPending} />
        }
        label="Show Only Pending Invoices"
        onChange={() => {
          setIsPending(!IsPending);
        }}
      />
      <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => paymentReceive()}
                    >
                      Receive
                    </Button>
      {/* {<b>Remaining Amount: {numeral(remain).format("0,0")}</b>} */}
      
      {loading!="" && <h3>{loading}</h3>}
      {/* <Button color="primary" onClick={() => billPaymnet()}>Receive</Button> */}
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {/* <TableCell></TableCell>  */}
            <TableCell>Invoice Number </TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Tax</TableCell>
            <TableCell align="center">Receive</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(
            (row, idx) =>
              (row.bill_total > row.received || !IsPending) && (
                <TableRow key={row.bill_id}>
                  {/* <TableCell align="right">
                 <Checkbox onChange={handleChangeChk(idx)}></Checkbox> 
              </TableCell>  */}
                  <TableCell component="th" scope="row">

                    <a
                      rel="noopener noreferrer"
                      href={makeLink(row.bill_pdf_path)}
                      target="_blank"
                    >
                      {" "}
                      {row.bill_invoice_num}
                    </a>
                  </TableCell>
                  <TableCell align="right">
                    {moment(row.bill_date1).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() => setReceived(idx)}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {numeral(row.bill_total).format("0,0")}
                  </TableCell>
                  <TableCell align="right">
                  <TextField
                      style={{'textAlign':'right'}}
                      fullWidth
                      value={row.tax}
                      onChange={updateTax(idx)}
                    />
                  </TableCell>
  
                  <TableCell align="right">
                    <TextField
                     style={{'textAlign':'right'}}
                      fullWidth
                      value={row.paid}
                      onChange={updateData(idx)}
                    />
                  
                  </TableCell>
                  {/* <TableCell align="right">
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={() => chequePayment(idx)}
                    >
                      Receive
                    </Button>
                  </TableCell> */}
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </div>
  );
}
