import React, { useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Cancel from "@material-ui/icons/Cancel";
import moment from "moment";
import numeral from "numeral";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pdf from "@material-ui/icons/PictureAsPdf";
import Link from "@material-ui/core/Link";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import OrderAPI, { ChallanAPI, BillAPI } from "../../BL";
import { transpose, values } from "underscore";
import { yellow } from "@material-ui/core/colors";
import { $CombinedState } from "redux";
const useStyles = makeStyles({
  colorH: {
    backgroundColor: "#795548",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  colorH1: {
    backgroundColor: "#d26936",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  color1: {
    backgroundColor: "#d7ccc8",
  },
  color2: {
    backgroundColor: "#efebe9",
  },
  color3: {
    backgroundColor: "white",
  },
  color4: {
    backgroundColor: "#e5e872",
  },
  Hyperlink: {
    textDecoration: "underline",
    color: "blue",
    fontSize: "10px",
    cursor: "pointer",
  },
  done: {
    backgroundColor: 'lightgreen',
    padding: '2px 2px 2px 2px'
  },
  pending: {
    backgroundColor: 'lightpink',
    padding: '2px 2px 2px 2px'
  }
});
function makeLink(lnk) {
  return "/server/PDF/Challan/" + lnk + ".pdf";
}
function makeLinkNonTax(lnk) {
  return "/server/PDF/NonTax/" + lnk + ".pdf";
}
function makeLink2(lnk) {
  return "/server/PDF/" + lnk + ".pdf";
}
export default function OrderList(props) {
  const obj = new OrderAPI();
  const objChallan = new ChallanAPI();
  const objBill = new BillAPI();
  const classes = useStyles();

  const [state, setState] = React.useState({
    data: [],
    deletedChallan: [],
    isLoading: false
  });
  function deleteOrder(ont_id, ont_order_number) {

    console.log(ont_id)
    if (
      window.confirm("Do you want to delete this order (" + ont_order_number + ")?")
    ) {
      obj
        .deleteNonTaxOrderById(ont_id)
        .then(() => {
          alert("Deleted successfully!");
          fetchList();
        })
        .catch((err) => alert(err));
    }
  }
  function editOrder(ont_id) {

    console.log(ont_id)
    props.handleEdit(ont_id);
  }

  function openInvoicePopup(rowData) {

   
    let table = document.getElementById(rowData.ont_id);

    let selected = table.dataset.selected.replace(/^,|,$/g, "").split(',');
    console.log(selected)
if(selected[0]===""){

  alert("Please select atleast one challan! (double click on challan date)"); return;
}
    const _temp = { ...rowData }
    _temp.order_qty = 0;
    _temp.order_price = 0;
    _temp.challan_numbers = selected.join("+").toString();
    if ( selected[0] === "") {
      rowData.challan_numbers.split(',').map((m) => {

        _temp.order_qty += parseInt(m.split("|")[4]);
        _temp.order_price = parseFloat(m.split("|")[5]);
      })
    }
    else {



      rowData.challan_numbers.split(',').filter(f => f.split('|')[0] == selected.filter(s => s === f.split('|')[0])).map((m) => {

        _temp.order_qty += parseInt(m.split("|")[4]);
        _temp.order_price = parseFloat(m.split("|")[5]);
      })



    }
    props.handleAddInvoice(_temp);
  }
  function openInvoicePopupChallan(rowData, m) {
    console.log('openInvoicePopupChallan')
    const _temp = { ...rowData }
    _temp.order_qty = m.split("|")[4];
    _temp.order_price = m.split("|")[5];
    _temp.challan_numbers = m;
    props.handleAddInvoice(_temp);



  }



  function openOrderPopupAdd(rowData) {
    props.handleEdit(rowData);
  }
  function openChallanPopup(rowData) {
    props.handleAddChallan(rowData);
  }
  function openChallanUpdatePopup(challan_id, order_number) {
    props.handleUpdateChallan(challan_id, order_number);
  }
  function openInvoiceUpdatePopup(bill_id, order) {
    props.handleUpdateBill(bill_id, order);
  }
  function deleteChallan(challan_id, challan_number) {
    if (
      window.confirm(
        "Do you want to delete this challan (" + challan_number + ")?"
      )
    ) {
      objChallan
        .deleteChallanByID(challan_id)
        .then(() => fetchList())
        .catch((err) => alert(err));
    }
  }
  function deleteBill(bill_id, bill_inv_num) {
    if (
      window.confirm("Do you want to delete this bill (" + bill_inv_num + ")?")
    ) {
      objBill
        .deleteBillByID(bill_id)
        .then(() => fetchList())
        .catch((err) => alert(err));
    }
  }
  function cancelBill(bill_id, bill_inv_num, bill_status) {
    if (
      window.confirm(
        `Do you want to ${bill_status == 0 ? "un-cancel" : "cancel"
        } this bill (${bill_inv_num})?`
      )
    ) {
      objBill
        .cancelBillByID(bill_id, bill_status == 0 ? 1 : 0)
        .then(() => fetchList())
        .catch((err) => alert(err));
    }
  }
  function fetchList() {
    
    if (props.ont_cust_id > 0) {
      setState({ ...state, isLoading: true });
      obj
        .fetchNonTaxListById(props.ont_cust_id)
        .then((res) => setState({ ...state, data: res, isLoading: false }))
        .catch((err) => {
          alert(err);
        });

      //
    }
  }
  useEffect(() => {
    fetchList();
  }, [props.ont_cust_id, props.loadAgain]);
  const changeColor = (e) => {

    try {
    let challan_number = e.target.parentNode.id;
    let table = document.getElementById(challan_number).closest('table');//.parentElement.parentElement.parentElement.parentElement.nextSibling
    let selected = table.dataset.selected.split(',');
    let idx = selected.indexOf(challan_number);
    if (idx != -1) { selected.splice(idx, 1) }
    else {

      selected.push(challan_number)
    }
    table.dataset.selected = selected.toString();

    let tr = document.getElementById(challan_number).closest('tbody');
    tr.style.background = tr.style.background == 'orange' ? 'transparent' : 'orange';

  }
  catch(e)
{
  
}
    //table.style.background =    table.style.background =='orange'?'transparent' :'orange';

    // e.target.parentNode.nextElementSibling.style.background =e.target.parentNode.nextElementSibling.style.background =='yellow'?'transparent' :'yellow'
  }

const createPDF=(id)=>{
alert(id)

}

const showInvoicePopup = (ont_id)=>{



 props.handleAddInvoice(state.data.filter(f=>f.ont_id==ont_id)[0]);
   
}

  function isCreated(inv_arr,_challan_number) {

   var _inv_number =""
    try{
    for(var i=0; i<inv_arr.length;i++){
      try{
      var inv = inv_arr[i];
      var inv_challan =inv.split("|")[6];
      if(_challan_number == inv_challan){
        _inv_number= inv.split("|")[0]
      }
      else{
console.log(inv_challan)
        inv_challan.split("+").map(elm=>{
          console.log(elm)
          if(_challan_number==elm){
            _inv_number= inv.split("|")[0]
          }
        })
      }
     
  
    }
    catch(ex){
   console.log(ex)
    }
    }
  }
  catch(ex){}
return _inv_number;
  }

  return (
    <div>
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          This is a success message!
        </Alert>
      </Snackbar> */}

      {state.isLoading ? (
        <h2>Loading...</h2>
      ) : (
        <Table size="small"  padding="none" >
        <TableHead>
          <TableRow className={classes.colorH}>
            <TableCell align="center" className="action" padding="none" size="small" width="100">
              Action
            </TableCell>
            <TableCell width="150">
              Order#
            </TableCell>
            <TableCell align="center"  width="120">
              Order Date
            </TableCell>
            <TableCell align="center"  width="120">
              Delivery Month
            </TableCell>
            <TableCell align="center"  width="100">Bags</TableCell>
            <TableCell align="center" width="100">Total</TableCell>
            <TableCell align="center">Invoice#</TableCell>
            <TableCell align="center"  width="100"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            state.data.map((rowData, idx) => (
              <TableRow key={idx}>
                <TableCell style={{ border: '1px solid gray' , padding:'3px'}} className="action">
         
                  <IconButton
                    color="primary"
                    title="Delete Order."
                    aria-label="Delete Order."
                    component="span"
                    size="small"
                    onClick={() =>
                      deleteOrder(rowData.ont_id, rowData.ont_order_number)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="Edit Order"
                    component="span"
                    size="small"
                    onClick={() => editOrder(rowData.ont_id)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell style={{ border: '1px solid gray', padding:'3px' }}><a target="_blank" href={makeLinkNonTax(rowData.ont_pdf_path)}>{rowData.ont_order_number}</a></TableCell>
                <TableCell style={{ border: '1px solid gray', padding:'3px' }} >
                  {moment(rowData.ont_order_date)
                    .tz("Asia/Karachi")
                    .format("DD-MM-YY")}
                </TableCell>
                <TableCell style={{ border: '1px solid gray', padding:'3px' }}>
                  {moment(rowData.ont_order_date2)
                    .tz("Asia/Karachi")
                    .format("MMM, YYYY")}
                </TableCell>
              
                <TableCell style={{ border: '1px solid gray', padding:'3px' }}align="right">{rowData.ont_bag}</TableCell>
                <TableCell style={{ border: '1px solid gray', padding:'3px' }}align="right">{ numeral(rowData.ont_order_total).format('0,0')}</TableCell>
                <TableCell style={{ border: '1px solid gray', padding:'3px' }}>
                <IconButton
                    color="primary"
                    aria-label="Edit Order"
                    component="span"
                    size="small"
                    onClick={() => showInvoicePopup(rowData.ont_id)}
                  >
                    <Pdf />
                  </IconButton>

                  <span>
                 {rowData.ntb_paths?.split(',').map(m=><span>&nbsp;<a target="_blank" href={makeLinkNonTax(m)}>{m}</a>&nbsp;</span>)} 
                    </span>
                </TableCell>
                <TableCell style={{ border: '1px solid gray', padding:'3px' }}align="center">
                
                </TableCell>
             
                
              </TableRow>
            ))}
         
        </TableBody>
      </Table>
      )}
    </div>
  )
}
