import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Switch, Route,Router  } from 'react-router-dom';
import './App.css';
import SignIn from './views/SignIn'
import Main from './views/Main'
import MainNonTax from './views/MainNonTax'
import history from './views/store/history';


function App() {



  return (
    
    <Router  history={history}>
  <Switch>
    {/* <Route path="/checkout" component={Checkout} />
    <Route path="/customer" component={Customer} /> */}
    <Route path="/main" component={Main} />
    <Route path="/main_nontax" component={MainNonTax} />
    <Route path="/" component={SignIn} />
  </Switch>
  </Router >

  );
}


export default hot(App);

