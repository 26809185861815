import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DeleteIcon from "@material-ui/icons/Delete";

import OrderAPI,{CustomersAPI,BillAPI} from "../../BL";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { typeOf } from "mathjs";
import numeral from "numeral";
var moment = require("moment");
const formatDate =(dt)=>{
  var _dt = dt.split('-')
  return _dt[2].substr(0,2)+"-"+_dt[1]+"-"+_dt[0]
  }
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export  function OrderEntryForm({
handleClose,
customer,
previousMonth,
}) {


  var objOrderAPI = new OrderAPI();
  var objCust = new CustomersAPI();
  var objBillAPI = new BillAPI();
  const classes = useStyles();
  //const [values, setValues] = useState(intialState);
    // Function to convert "052024" to "May, 2024"
    const formatMonthYear = (dateStr) => {
      return moment(dateStr, 'YYYY-MM').format('MMMM, YYYY');
    };

  const [paymentDetails,setPaymentDetails] = useState([])
    const [ntp,setNtp] = useState({ntp_cust_id:customer.cust_id, ntp_amount:0, ntp_payment_date:moment().format('YYYY-MM-DD'), ntp_ref:'', ntp_enter_by:'test'})

  const [selectedMonthYear, setSelectedMonthYear] = useState(previousMonth);
  const [loading, setLoading] = useState("");
  const[detail,setDetail]=useState({'notes':'N/A',adjustment:0,payable:0,payable:0,orders:[],payments:[],prevBal:0,currentTotal:0,paidTotal:0,customer,previousMonth:formatMonthYear(previousMonth),previousMonthInt:previousMonth})
// Function to convert month in format 'MMYYYY' to the previous month
const convertToPreviousMonth = (monthYear) => {
  return moment(monthYear, 'MMYYYY').format('MMYYYY');
  //return moment(monthYear, 'MMYYYY').subtract(1, 'months').format('MMYYYY');
};

const getNonTaxInvoiceByCustID = ()=>{
  setLoading("Loading...")
  var temp= selectedMonthYear.split('-')[1]+""+selectedMonthYear.split('-')[0]
  objOrderAPI.getNonTaxInvoiceByCustID(customer.cust_id,temp,convertToPreviousMonth(temp))
      
  .then((orders) => {

   var currentTotal = orders.orderData.reduce((o,curr) => o+curr.ont_order_total,0);
   var paidTotal = orders.PaymentData.reduce((o,curr) => o+curr.ntp_amount,0);
   var prevBal =orders.PrevInvoice.length>0?orders.PrevInvoice[0].ntb_payable : 0;
   var payable = currentTotal+prevBal - paidTotal;
    setDetail({...detail,orders:orders.orderData, prevBal,currentTotal,payments:orders.PaymentData, paidTotal,payable});     
    setLoading("")

   // setCustomers(customers);
  })
  
  .catch((err) => {alert(err)

    setLoading("")
  }
 
  );
}
  useEffect(() => {
    getNonTaxInvoiceByCustID();
   

  }, [selectedMonthYear]);
  useEffect(()=>{
    var payable = detail.prevBal+detail.orders?.reduce((o,curr) => o+curr.ont_order_total,0)-detail.paidTotal;
    setDetail({...detail, payable})
  },[detail.orders]);

const handleDetail =(e)=>{
if(e.target.name=='adjustment'){
  var payable = detail.prevBal+detail.orders?.reduce((o,curr) => o+curr.ont_order_total,0)+parseInt(e.target.value)-parseInt(detail.paidTotal);
  var adjustment =e.target.value
  setDetail({...detail,payable, adjustment})


}
else {
  setDetail({...detail,[e.target.name]:e.target.value})
 
}
 
}
  const generateNonTaxInvoice =()=>{

  var data = {
// orders,
// total:orders?.reduce((o,curr) => o+curr.ont_order_total,0),
// prevBal,
// payable: prevBal+orders?.reduce((o,curr) => o+curr.ont_order_total,0)

  }
console.log(detail)
  objBillAPI.generateNonTaxInvoice(detail).then(t=>handleClose(true)).catch(e=>console.log(e))

  }


  const handleMonthYearChange = (event) => {
    console.log('handleMonthYearChange')
    setSelectedMonthYear(event.target.value);

  };
useEffect(()=>{
  //console.log('getNonTaxInvoiceByCustID')
 // getNonTaxInvoiceByCustID();
},[])

const handleNtp =(e)=>{
  setNtp({...ntp,[e.target.name]:e.target.value})


}

const submitPayment =()=>{
  
  var data = {
// orders,
// total:orders?.reduce((o,curr) => o+curr.ont_order_total,0),
// prevBal,
// payable: prevBal+orders?.reduce((o,curr) => o+curr.ont_order_total,0)

  }
console.log(ntp)

  objBillAPI.submitNonTaxPayment(ntp).then(t=>{

    getNonTaxInvoiceByCustID()
  }).catch(e=>console.log(e))

  }

  const removeOrder = (index) => {
   var _tempOrders = detail.orders.filter((_, idx) => idx !== index);
    var currentTotal = _tempOrders.reduce((o,curr) => o+curr.ont_order_total,0);
    
    
    var payable = currentTotal+detail.prevBal - detail.paidTotal;
     setDetail({...detail,orders:_tempOrders,currentTotal,payable});  

    
  };
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={true}

      //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
       Invoice For: <b style={{color:'blue'}}> <input type='month'  value={selectedMonthYear}
        onChange={handleMonthYearChange} /> </b><span color="primary">{loading}</span>
        <Avatar
          alt="Remy Sharp"
          style={{ float: "right" }}
          className={classes.orange}>
          B
        </Avatar>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "lightblue" }} dividers>
      <table id="tblInvoice" width="500px" border="1" cellSpacing={0}  cellPadding={3}> 
      <tr><td colSpan={2}><b>{customer.cust_name}</b> </td><td colSpan={2} align={"right"}><b>{formatMonthYear(selectedMonthYear)}</b></td></tr>
      <tr style={{backgroundColor:'silver'}} ><td>Order #</td><td>Date</td><td>Bags</td><td>Amount</td></tr>{ 
      detail.orders.map((m,idx)=>
      <tr>
        <td>&nbsp;<b style={{color:'red', cursor:'pointer'}} onClick={()=>removeOrder(idx)} >X</b>&nbsp; {m.ont_order_number}</td>
        <td>{formatDate(m.ont_order_date)}</td>        
        <td  align='right'>{m.ont_bag}</td>
        <td align='right'>{m.ont_order_total}</td>
      </tr>
    ) 
    
      }
<tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Bills Total:</b></td><td align={"right"}> <b>{detail.currentTotal}</b></td></tr>
<tr style={{backgroundColor:'#777a9d', height:'3px'}} ><td colspan="4"></td></tr>
<tr style={{backgroundColor:'silver'}} ><td>Date</td><td colSpan={2} >Reference</td><td>Paid Amount</td></tr>
<tr style={{backgroundColor:'silver'}} ><td><input type="date" name="ntp_payment_date" onChange={handleNtp} value={ ntp.ntp_payment_date}></input> </td>
<td colSpan={2}><input name="ntp_ref" style={{width:'300px'}} autoComplete="off"  onChange={handleNtp} value={ntp.ntp_ref}></input></td>
<td><input name="ntp_amount"  autoComplete="off" style={{width:'126px'}} onChange={handleNtp} value={ntp.ntp_amount}></input>&nbsp;

<input type='button'  onClick={() => submitPayment(false)}  value="Save" />



</td></tr>



{ 
      detail.payments.map(m=>
      <tr>
        
        <td>{formatDate(m.ntp_payment_date)}</td>
        <td colSpan={2}>{m.ntp_ref}</td>
       
        <td align='right'>{m.ntp_amount}</td>
      </tr>
    ) 
    
      }
      <tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Paid Total:</b></td><td align={"right"}><b>{detail.paidTotal}</b></td></tr>
      <tr style={{backgroundColor:'#777a9d', height:'3px'}} ><td colspan="4"></td></tr>
      <tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Dues Total:</b></td><td align={"right"}><b>{detail.prevBal}</b></td></tr>

      <tr style={{backgroundColor:'silver'}}><td><b>Adjustment:</b></td><td colSpan={2}><input style={{width:'300px'}} type="text" value={detail.notes} name="notes" onChange={handleDetail} autoComplete="off" ></input></td><td align={"right"}><input name="adjustment" autoComplete="off" value={detail.adjustment}  onChange={handleDetail}></input></td></tr>
     
      <tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Payable Total:</b></td><td align={"right"}><b>{detail.payable}</b></td></tr>
      </table>
     
        
      </DialogContent>
      <DialogActions>

      <Button color="primary"  variant="contained" onClick={() => generateNonTaxInvoice(false)} >Generate Bill</Button>
        <Button  variant="contained" onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export  function PaymentEntryForm({
  handleClose,
  customer,
  previousMonth,
  }) {
  
  
    var objOrderAPI = new OrderAPI();
    var objCust = new CustomersAPI();
    var objBillAPI = new BillAPI();
    const classes = useStyles();
    const [paymentDetails,setPaymentDetails] = useState([])
    const [ntp,setNtp] = useState({ntp_cust_id:customer.cust_id, ntp_amount:0, ntp_payment_date:moment().format('YYYY-MM-DD'), ntp_ref:'', ntp_enter_by:'test'})
    //const [values, setValues] = useState(intialState);
      // Function to convert "052024" to "May, 2024"
      const formatMonthYear = (dateStr) => {
        // Parse the date string
        const month = dateStr.substring(0, 2);
        const year = dateStr.substring(2);
        
        // Create a moment date object
        const date = moment(`${year}-${month}-01`);
        
        // Format the date
        return date.format('MMMM, YYYY');
      };
    const formatDate =(dt)=>{
    var _dt = dt.split('-')
    return _dt[2].substr(0,2)+"-"+_dt[1]+"-"+_dt[0]
    }
    
    const [loading, setLoading] = useState("");
    const[detail,setDetail]=useState({'notes':'N/A',adjustment:0,payable:0,orders:[],prevBal:0,currentTotal:0,customer,previousMonth:formatMonthYear(previousMonth),previousMonthInt:previousMonth})
  // Function to convert month in format 'MMYYYY' to the previous month
  const convertToPreviousMonth = (monthYear) => {
    return moment(monthYear, 'MMYYYY').subtract(1, 'months').format('MMYYYY');
  };

  const loadPayments=()=>{

    setLoading("Loading...")
    objBillAPI.getNonTaxPaymentsByCustID(customer.cust_id)
        
    .then((payments) => {

      setLoading("")
     // console.log(payments)
      setPaymentDetails(payments)
     // setCustomers(customers);
    })
    
    .catch((err) => {alert(err)

      setLoading("")
    }
  
    );
  }
    useEffect(() => {
  
      loadPayments()
     
    },[]);
      
    const deleteNonTaxPayment =(ntp_id)=>{
if(window.confirm("Do you want to delete this payment?"))
{
  objBillAPI.deleteNonTaxPaymentByID(ntp_id).then((res)=>{
    loadPayments();


  }).catch(err=>{
    console.log(err)
    alert(err)})


}
   



    }
  
  const handleNtp =(e)=>{
      setNtp({...ntp,[e.target.name]:e.target.value})
    
   
  }
    const submitPayment =()=>{
  
    var data = {
  // orders,
  // total:orders?.reduce((o,curr) => o+curr.ont_order_total,0),
  // prevBal,
  // payable: prevBal+orders?.reduce((o,curr) => o+curr.ont_order_total,0)
  
    }
  console.log(ntp)
    objBillAPI.submitNonTaxPayment(ntp).then(t=>loadPayments()).catch(e=>console.log(e))
  
    }
  
  
  
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        //   onEntering={handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={true}
  
        //   {...other}
      >
        <DialogTitle id="confirmation-dialog-title">
         Payment For: <b style={{color:'blue'}}>{customer.cust_name}{" "}</b><span color="primary">{loading}</span>
          
          <Button   style={{ float: "right" }}
 variant="contained" onClick={() => handleClose(false)} color="primary">
            Close
          </Button>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "lightblue" }} dividers>
        <table id="tblInvoice" width="500px" border="1" cellSpacing={0}  cellPadding={3}> 
        <tr><td>Payment Date:</td><td><input type="date" name="ntp_payment_date" onChange={handleNtp} value={ ntp.ntp_payment_date}></input></td></tr>
        <tr><td>Amount:</td><td><input name="ntp_amount" autoComplete="off" onChange={handleNtp} value={ntp.ntp_amount}></input></td></tr>
        <tr><td>Reference::</td><td><input name="ntp_ref" autoComplete="off"  onChange={handleNtp} value={ntp.ntp_ref}></input></td></tr>
        
                </table>
                <h3>Payments</h3>
       <table border="1" cellPadding={5} cellSpacing={0} width={"100%"}>
        <thead><tr><th></th><th>Payment Date</th><th>Amount</th><th>Reference</th></tr></thead>
       {
       paymentDetails.map(m=>
        <tr><td><IconButton
        color="primary"
        title="Delete Order."
        aria-label="Delete Order."
        component="span"
        size="small"
        onClick={() =>{
          deleteNonTaxPayment(m.ntp_id)
        }}
      >
        <DeleteIcon />
      </IconButton>
</td><td>{moment(m.ntp_payment_date).format("DD-MM-YYYY") }</td><td align="right">  {numeral(m.ntp_amount).format("0,0")}</td><td>{m.ntp_ref}</td></tr>
       )
       }
        </table>
          
        </DialogContent>
        <DialogActions>
  
        <Button color="primary"  variant="contained" onClick={() => submitPayment(false)} >Submit</Button>
          <Button  variant="contained" onClick={() => handleClose(false)} color="primary">
            CLose
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

 export function NonTaxInvoiceEntryForm({
  handleClose,
order,
customer
  }) {
    var objOrderAPI = new OrderAPI();
    var objCust = new CustomersAPI();
    var objBillAPI = new BillAPI();
    const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");
    const classes = useStyles();
    const [loading, setLoading] = useState("");
    const[detail,setDetail]=useState({'notes':'N/A',adjustment:0,payable:order.ont_order_total,orders:[order],payments:[],prevBal:0,currentTotal:order.ont_order_total,paidTotal:0,customer,previousMonth:'',previousMonthInt:'',selectedDate:today})
    const [ntp,setNtp] = useState({ntp_cust_id:order.ont_cust_id, ntp_amount:0, ntp_payment_date:moment().format('YYYY-MM-DD'), ntp_ref:'', ntp_enter_by:'test'})
    const [paymentDetails,setPaymentDetails] = useState([])
    useEffect(()=>{
      
    },[])
    const handleNtp =(e)=>{
      setNtp({...ntp,[e.target.name]:e.target.value})
    
   
  }
  const loadPayments=()=>{

    setLoading("Loading...")
    objBillAPI.getNonTaxPaymentsByCustID(customer.cust_id)
        
    .then((payments) => {

      setLoading("")
     // console.log(payments)
      setPaymentDetails(payments)
     // setCustomers(customers);
    })
    
    .catch((err) => {alert(err)

      setLoading("")
    }
  
    );
  }

  useEffect(()=>{

    var paidTotal =  detail.payments.reduce((o,curr) => o+ parseInt(curr.ntp_amount),0);
    
    var payable =   parseInt(detail.currentTotal)+parseInt(detail.adjustment)-paidTotal;
    setDetail({...detail,paidTotal,payable});
 
  },[detail.payments])
  const submitPayment =()=>{
  
    var data = {
  // orders,
  // total:orders?.reduce((o,curr) => o+curr.ont_order_total,0),
  // prevBal,
  // payable: prevBal+orders?.reduce((o,curr) => o+curr.ont_order_total,0)
  
    }
  console.log(ntp)
 
    objBillAPI.submitNonTaxPayment(ntp).then(t=> setDetail({...detail,payments:[...detail.payments,ntp]})).catch(e=>console.log(e))
  
    }



    const handleDateChange =(e)=>{
     var selectedDate = e.target.value;
      setDetail({...detail, selectedDate})
    }
    const handleDetail =(e)=>{
      if(e.target.name=='adjustment'){
        var adjustment =e.target.value;
        var payable = detail.prevBal+detail.orders?.reduce((o,curr) => o+curr.ont_order_total,0)+parseInt(adjustment)-parseInt(detail.paidTotal);

        setDetail({...detail,payable, adjustment})
      
      
      }
      else {
        setDetail({...detail,[e.target.name]:e.target.value})
       
      }
       
      }

      const generateNonTaxInvoice =()=>{

        var data = {
      // orders,
      // total:orders?.reduce((o,curr) => o+curr.ont_order_total,0),
      // prevBal,
      // payable: prevBal+orders?.reduce((o,curr) => o+curr.ont_order_total,0)
      
        }
      console.log(detail)
        objBillAPI.generateNonTaxInvoice(detail).then(t=>handleClose(true)).catch(e=>console.log(e))
      
        }
return ( <Dialog
  disableBackdropClick
  disableEscapeKeyDown
  maxWidth="md"
  //   onEntering={handleEntering}
  aria-labelledby="confirmation-dialog-title"
  open={true}

  //   {...other}
>
  <DialogTitle id="confirmation-dialog-title">
   Invoice For Order: <b style={{color:'blue'}}>{order.ont_order_number} &nbsp;<input type='date'  value={detail.selectedDate}
        onChange={handleDateChange} /></b><span color="primary">{loading}</span>
    <Avatar
      alt="Remy Sharp"
      style={{ float: "right" }}
      className={classes.orange}>
      B
    </Avatar>
  </DialogTitle>
  <DialogContent style={{ backgroundColor: "lightblue" }} dividers>
  <table id="tblInvoice" width="500px" border="1" cellSpacing={0}  cellPadding={3}> 
  <tr><td colSpan={2}><b>{customer.cust_name}</b> </td><td colSpan={2} align={"right"}><b>{}</b></td></tr>
  <tr style={{backgroundColor:'silver'}} ><td>Order #</td><td>Date</td><td>Bags</td><td>Amount</td></tr>{ 
  detail.orders.map((m,idx)=>
  <tr>
    <td>{m.ont_order_number}</td>
    <td>{formatDate(m.ont_order_date)}</td>        
    <td  align='right'>{m.ont_bag}</td>
    <td align='right'>{m.ont_order_total}</td>
  </tr>
) 

  }
<tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Bills Total:</b></td><td align={"right"}> <b>{detail.currentTotal}</b></td></tr>
<tr style={{backgroundColor:'#777a9d', height:'3px'}} ><td colspan="4"></td></tr>
<tr style={{backgroundColor:'silver'}} ><td>Date</td><td colSpan={2} >Reference</td><td>Paid Amount</td></tr>
<tr style={{backgroundColor:'silver'}} ><td><input type="date" name="ntp_payment_date" onChange={handleNtp} value={ ntp.ntp_payment_date}></input> </td>
<td colSpan={2}><input name="ntp_ref" style={{width:'300px'}} autoComplete="off"  onChange={handleNtp} value={ntp.ntp_ref}></input></td>
<td><input name="ntp_amount"  autoComplete="off" style={{width:'126px'}} onChange={handleNtp} value={ntp.ntp_amount}></input>&nbsp;

<input type='button'  onClick={() => submitPayment(false)}  value="Save" />



</td></tr>



{ 
  detail.payments.map(m=>
  <tr>
    
    <td>{formatDate(m.ntp_payment_date)}</td>
    <td colSpan={2}>{m.ntp_ref}</td>
   
    <td align='right'>{m.ntp_amount}</td>
  </tr>
) 

  }
  <tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Paid Total:</b></td><td align={"right"}><b>{detail.paidTotal}</b></td></tr>
  <tr style={{backgroundColor:'#777a9d', height:'3px'}} ><td colspan="4"></td></tr>
  {/* <tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Dues Total:</b></td><td align={"right"}><b>{detail.prevBal}</b></td></tr> */}

  <tr style={{backgroundColor:'silver'}}><td><b>Adjustment:</b></td><td colSpan={2}><input style={{width:'300px'}} type="text" value={detail.notes} name="notes" onChange={handleDetail} autoComplete="off" ></input></td><td align={"right"}><input name="adjustment" autoComplete="off" value={detail.adjustment}  onChange={handleDetail}></input></td></tr>
 
  <tr style={{backgroundColor:'#c1e9d8'}}><td colSpan={3} align={"right"}><b>Payable Total:</b></td><td align={"right"}><b>{detail.payable}</b></td></tr>
  </table>
 
    
  </DialogContent>
  <DialogActions>

  <Button color="primary"  variant="contained" onClick={() => generateNonTaxInvoice(false)} >Generate Bill</Button>
    <Button  variant="contained" onClick={() => handleClose(false)} color="primary">
      Cancel
    </Button>
  </DialogActions>
</Dialog>)

  }