import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
export const Popup = ({Comp,handleClose}) => {
    const [open,setOpen] = useState(true);
   
    function handleCancel(newValue) {
        
        setOpen(false);
        handleClose()
      }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        {/* Bill No. {value.bill_invoice_num} */}
      </DialogTitle>
      <DialogContent dividers>
       {<Comp></Comp>} 
      </DialogContent>
      <DialogActions>
        <Button 
        onClick={handleCancel} 
        color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
