import React,{useState} from "react";
import { Route, NavLink, HashRouter, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Order from "./order/order_main";
import Bill from "./bill/main";
import Customer from "./client/main";
import Challan from "./challan/main";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { CssBaseline } from "@material-ui/core";

// import EntryForm from './EntryForm';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://www.nafeesenter.com/">
//         Nafees Enterprises
//       </Link>{' '}
//       {new Date().getFullYear()}

//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    // [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
    //   width: 800,
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    // },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(5),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px black",
    fontWeight: "bold",

    padding: "10px 30px",
  },
}));

export default function Main() {
  const classes = useStyles();
  const [title,setTitle] = useState("Bill & Sales Tax Invoice");
  const handleTitle =(t)=>{
title=t;

  }
  return (
    <HashRouter>

<AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap style={{ flex: 1 }}>
            {/* Nafees Enterprises */}
          
          </Typography>
          <Typography variant="h5" color="primary" noWrap style={{ flex: 1 }}>
          {title}

          </Typography>
          <nav>
            <NavLink className={classes.button} to="/order" activeClassName="active" onClick={()=>{setTitle("Orders");}}>
              Order
            </NavLink>
            &nbsp;&nbsp;
            <NavLink className={classes.button} to="/challan" activeClassName="active" onClick={()=>{setTitle("Create Challan");}}>
              Challan
            </NavLink>
            &nbsp;&nbsp;
            <NavLink className={classes.button} to="/billing" activeClassName="active" onClick={()=>{setTitle("Bill & Sales Tax Invoice");}}>
              Billing
            </NavLink>
            &nbsp;&nbsp;
            <NavLink className={classes.button} to="/customer" activeClassName="active" onClick={()=>{setTitle("Manage Customer");}}>
              Customer
            </NavLink>
          </nav>
          {/* <Button href="/" color="primary" variant="outlined"  className={classes.link}>
            Logout
          </Button> */}
        </Toolbar>
      </AppBar>
      <CssBaseline/>
      <main className={classes.layout}>
        <Redirect to="/order" />
        <Route path="/order" component={Order}/>
        <Route path="/billing" component={Bill}/>
        <Route path="/customer" component={Customer}/>
        <Route path="/challan" component={Challan}/>

        {/* <Copyright /> */}
      </main>
    </HashRouter>
  );
}
