import React, { useState} from "react";
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ChequeSetup from '../cheque/cheque_setup';

export const Popup = ({cheques,handleClose,updateList,customers}) => {
    const [open,setOpen] = useState(true);

    function handleCancel(newValue) {
        
        setOpen(false);
        handleClose()
      }
      function handleUpdate(value){
        // updateList(value)
        // setOpen(false);
        handleClose()
      }
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Enter New Cheque
      </DialogTitle>
      <DialogContent dividers>
      <ChequeSetup handleUpdate={handleUpdate} customers={customers} ></ChequeSetup>
      </DialogContent>
      <DialogActions>
        <Button 
        onClick={handleCancel} 
        color="primary">
          Cancel
        </Button>
        {/* <Button
        //  onClick={handleOk} 
         color="primary">
          Ok
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
