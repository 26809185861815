import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import OrderAPI,{CustomersAPI} from "../../BL";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { typeOf } from "mathjs";
var moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");
export default function OrderEntryForm({
  order,
  hscodes,
  handleClose,
  isAddMore,
}) {


  var obj = new OrderAPI();
  var objCust = new CustomersAPI();
  const {
    ont_cust_id,
    order_customer_name,
    order_item_code,
    order_vendor_code,
    ont_id,
  } = typeof order.ont_id === "object" ? order.ont_id : order;
  const intialState = {
   isLoading:true,
    ont_order_number: "",
    ont_order_date: today,
    ont_order_date2:  moment().format('YYYY-MM'),
    ont_po_num:'',
    
    ont_generated_by: "Test",
    ont_cust_id: ont_cust_id,
    ont_id: ont_id === undefined ? 0 : ont_id,
    cust_items:[],
    cust_items_added:[],
    ont_order_total:0,
    order_customer_name
  };
  const classes = useStyles();
  const [values, setValues] = useState(intialState);
  const dropdownRef = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (values.InvBuyerName == "") {
      alert("Please Select Client!");
      event.preventDefault();
      return false;
    }
    // if (values.order_hscode == "-1") {
    //   alert("Please Select HSCode!");
    //   event.preventDefault();
    //   return false;
    // }

    setValues({
      ...values,
      orderMsg: values.ont_id != 0 ? "Updating..." : "Generating..."
    });


    if (values.ont_id != 0) {
      values.ont_order_date2 = values.ont_order_date2+"-01";
      obj
        .UpdateNonTaxOrder(
         {...values,cust_items:values.cust_items_added}
         
        )
        .then(() => handleClose(true))
        .catch((err) => {alert(err);

          setValues({
            ...values,
            orderMsg: "Server Error!"
          });
        });
    } else {
      values.ont_order_date2 = values.ont_order_date2+"-01";
      obj
        .AddNonTaxOrder(
          
          {...values,cust_items:values.cust_items_added}
        
          
          )
        .then(() => handleClose(true))
        .catch((err) => alert(err));
    }
  };


  const handleChange = (name) => (event) => {
    try {
      values[name] = event.target.value;

      setValues({ ...values, [name]: event.target.value });
    } catch (ex) {
      alert(ex);
    }
  };

  const addDate = () => {
    const _temp = [...values.schedule];

    _temp.push({ order_demand_date: today, order_qty: "" });

    setValues({ ...values, schedule: [..._temp] });
  };
  const deleteDate = (idx) => {
    const _temp = [...values.schedule];

    _temp.splice(idx);

    setValues({ ...values, schedule: [..._temp] });
  };
  useEffect(() => {
    
    //  var c = [];
    // var h = [];
    fetchCustItems();
   

    
    async function fetchCustItems() {
  
    
   
   objCust.getCustomerItems(order.ont_cust_id).then((res)=>{
    const itemsWithQty = res.map(item => ({ ...item, cust_item_qty: 0, cust_item_total:0 }));
    setValues({ ...values, isLoading:false, cust_items: itemsWithQty });
  
  
  
  }).catch((ex)=>{alert(ex)})
   
   
    }
  }, []);
useEffect(()=>{
  if (order.ont_id != 0 && values.cust_items.length>0) {
    setValues({
      ...values,
      isLoading:true})
    fetchorderByID();
  } 
  async function fetchorderByID() {
 
    obj
      .getNonTaxOrderByID(ont_id)
      .then((result) => {
      
         setValues({
          ...values,
          isLoading:false,
           ont_bag:result.orderData.ont_bag,
           ont_order_date:result.orderData.ont_order_date.split('T')[0],
           ont_order_date2:moment(result.orderData.ont_order_date2).format('YYYY-MM'),
           ont_order_total:result.orderData.ont_order_total,
           ont_order_number:result.orderData.ont_order_number,
           ont_po_num:result.orderData.ont_po_num,
            ont_po_num:result.orderData.ont_package,
           cust_items_added:result.orderItemsData.map(function(obj) {
            return {
              'cust_item_rate': obj.oi_item_rate,
              'cust_item_qty': obj.oi_item_qty,
              'cust_item_total':obj.oi_item_total,
              'cust_item_description':obj.oi_item_desc,
              'cust_item_name':obj.oi_item_name,
              'cust_item_id':obj.oi_id,
              'cust_item_package':obj.oi_item_package
            };
          })
   
        });
      })
      .catch((err) => alert(err));
 
 
 
 
  }
},[values.cust_items])
  const handleChangeItemField = (field, value) => {
 // Create a copy of cust_items array
    let updatedItem = { ...selectedItem};
    updatedItem[field] = value;
    console.log(updatedItem)
    if (field === 'cust_item_rate' || field === 'cust_item_qty') {
      updatedItem.cust_item_total = parseInt(updatedItem.cust_item_rate * updatedItem.cust_item_qty);
    }

    setSelectedItem(updatedItem)
  
  };
  
  
  const [selectedItem, setSelectedItem] = useState({'cust_item_rate': 0,
    'cust_item_qty': 0,
    'cust_item_total':0,
    'cust_item_description':'',
    'cust_item_name':'',
    'cust_item_id':0,
    'cust_item_package':''});

const handleItemSelection = (index) => {
  //const selected = values.cust_items[index];
  setSelectedItem(values.cust_items[index] );
};


const AddItem =()=>{

    const orderTotal = [...values.cust_items_added,selectedItem].reduce((total, item) => total + (item.cust_item_total || 0), 0);
   
    setValues(prevState => ({
      ...prevState,
      cust_items_added: [...values.cust_items_added,selectedItem],
     ont_order_total: orderTotal
    }));
    setSelectedItem({'cust_item_rate': 0,
      'cust_item_qty': 0,
      'cust_item_total':0,
      'cust_item_description':'',
      'cust_item_name':'',
      'cust_item_id':0,
      'cust_item_package':''});
      dropdownRef.current.selectedIndex = 0;
}

const DeleteItem =(idx)=>{
  values.cust_items_added.splice(idx, 1)
  const orderTotal = values.cust_items_added.reduce((total, item) => total + (item.cust_item_total || 0), 0);
 setValues(prevState => ({
  ...prevState,
  cust_items_added: values.cust_items_added,
  ont_order_total: orderTotal
}));
}
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="lg"
      //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={true}

      //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        New Order For: <b style={{color:'blue'}}>{order_customer_name}{" "}</b>
        <Avatar
          alt="Remy Sharp"
          style={{ float: "right" }}
          className={classes.orange}
        >
          O
        </Avatar>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: "lightblue" }} dividers>

      {values.isLoading && <h2>Loading...</h2>}
       {!values.isLoading &&   <React.Fragment>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
              <InputLabel htmlFor="ont_order_number">
          Order Number
        </InputLabel>
                <TextField
                  required
                  id="ont_order_number"
                  name="ont_order_number"
                   
                  fullWidth
                  value={values.ont_order_number}
                  onChange={handleChange("ont_order_number")}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={4} sm={3}>

              <InputLabel htmlFor="ont_order_date">
          Date
        </InputLabel>
                <TextField
                  required
                id="ont_order_date"
                  fullWidth
                  type="date"
                  value={values.ont_order_date ? values.ont_order_date.split('T')[0] : ''}
                  onChange={handleChange("ont_order_date")}
                />
              </Grid>

              <Grid item xs={2} sm={2}>

              <InputLabel htmlFor="ont_bag">
              No. of Bags
        </InputLabel>
                <TextField
                      autoComplete="off"
                id="ont_bag"
                  fullWidth
                  
                  value={values.ont_bag}
                  onChange={handleChange("ont_bag")}
                />
              </Grid>
              <Grid item xs={3} sm={3}>

<InputLabel htmlFor="ont_order_date2">
Delivery Month
</InputLabel>

<input type='month' className="MuiInputBase-input MuiInput-input"  value={values.ont_order_date2}
onChange={handleChange("ont_order_date2")} /> 
</Grid>
   
              <Grid item xs={12} sm={12}>
              <table cellPadding={5} style={{width:'100%'}}>
      

  <tr style={{backgroundColor:'gray'}}>
    <td>Item</td>
    <td>Desc.</td>
    <td>Package</td>
    <td>Rate</td>
    <td>Qty</td>
    <td align="right"><b>Total: {values.ont_order_total}</b></td>
    <td>              </td>
  </tr>
  <tr>
  <td>
    <select ref={dropdownRef} onChange={(e) => handleItemSelection(e.target.value)}>
      <option value="">Select</option>
      {values.cust_items.map((m,idx) => (
        <option key={idx} value={idx}>
          {m.cust_item_name}
        </option>
      ))}
    </select>
  </td>
  <td>{selectedItem ? selectedItem.cust_item_description: ""}</td>
  <td>
    <TextField
      autoComplete=""
      fullWidth
      
      value={selectedItem ? selectedItem.cust_item_package:""}
      onChange={(e) => handleChangeItemField('cust_item_package', e.target.value)}
    />
  </td>
  <td>
    <TextField
      autoComplete=""
      fullWidth
      type="number"
      value={selectedItem ? selectedItem.cust_item_rate : ""}
      onChange={(e) => handleChangeItemField( 'cust_item_rate', e.target.value)}
    />
  </td>
  <td>
    <TextField
      autoComplete=""
      fullWidth
      type="number"
      value={selectedItem ?selectedItem.cust_item_qty : ""}
      onChange={(e) =>handleChangeItemField( 'cust_item_qty', e.target.value)}
    />
  </td>
  <td align="right">
    <b>{selectedItem ? selectedItem.cust_item_total  || 0 : 0}</b>
  </td>
  <td><IconButton
                    color="primary"
                    title="Add Item."
                    aria-label="Add Item."
                    component="span"
                    size="small"
                    onClick={() =>{AddItem()}
                     
                    }
                  >
                    <AddIcon />
                  </IconButton></td>
</tr> 
   {values.cust_items_added
   
   .map((m,idx) => (
    <tr key={m.cust_item_id}>
      <td>{m.cust_item_name}</td>
      <td>{m.cust_item_description}</td>
      <td>{m.cust_item_package}</td>
      <td>{m.cust_item_rate}
                
      </td>
      <td>
          {m.cust_item_qty}
      </td>
      <td align="right">
        <b>{ m.cust_item_total || 0}</b>
      </td>
      <td> <IconButton
                    color="primary"
                    title="Delete Item."
                    aria-label="Delete Item."
                    component="span"
                    size="small"
                    onClick={() =>{DeleteItem(idx)}
                     
                    }
                  >
                    <RemoveIcon />
                  </IconButton></td>
    </tr>
  ))} 
</table>

              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  className={classes.button}
                >
                  {values.ont_id != 0 && typeof order.ont_id !== "object"
                    ? "Update"
                    : "Submit"}
                </Button>
                &nbsp;&nbsp;
                <FormLabel>{values.orderMsg}</FormLabel>
              </Grid>
            </Grid>
          </form>
        </React.Fragment> } 
     
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
