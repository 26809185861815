import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { BillAPI } from "../../BL";
//import moment from "moment";
import axios from "axios";
import API from "../../config";
import { create, all } from "mathjs";
var moment = require("moment-timezone");
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");

export default function BillEntryForm({ billID, order, customer,handleClose }) {
 
  const objBillAPI = new BillAPI();
  if (billID === undefined) {
    billID = 0;
  }

  const config = {};
  const math = create(all, config);
  const classes = useStyles();
  // const [custs, setCusts] = useState([]);

  const [values, setValues] = useState({
    billNumber: "Loading...",
    billDate1: today,
    billDate2: today,
    billItemCode: order.order_item_code,
    billVendorCode: order.order_vendor_code,
    billOrderNumber: order.order_number,
    billMessrs: order.order_customer_name,
    billQty: order.order_qty,
    billParticullars: order.order_particullars,
    billRate: order.order_price,
    billAmount: 0,
    billGST: 18,
    billGSTAmount: 0,
    billSED: 0,
    billSEDAmount: 0,
    billAGST: 0,
    billAGSTAmount: 0,
    billTotal: 0,
    billMsg: "",
    bill_to_emails: "nafeesenter@gmail.com",
    bill_send_email: true,
    bill_cust_id: order.order_customer_id,
    bill_id: billID,
    bill_hscode: order.order_hscode,
    InvBuyerName: customer.InvBuyerName,
    InvBuyerAddr: customer.InvBuyerAddr,
    InvBuyerPhone: customer.InvBuyerPhone,
    InvBuyerSTRN: customer.InvBuyerSTRN,
    InvBuyerNTN: customer.InvBuyerNTN,
    InvDesc: "",
    InvQty: 0,
    InvUnitPrice: 0,
    InvVEST: 0,
    InvROST: 0,
    InvAOST: 0,
    InvSEDRate: 0,
    InvSEDAmt: 0,
    InvVIST: 0,
    InvTotal: 0,
    InvQtyKG:order.order_qty_kg,
    billChallan: order.challan_numbers
      .split(",")
      .map((m) => m.split("|")[0])
      .join(","),
    stock_weight: 0,
    stock_weight_paper: 0,
    bill_order_id:order.order_id
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    values.bill_to_emails = values.bill_send_email ? values.bill_to_emails : "";

    setValues({
      ...values,
      billMsg: values.bill_id != 0 ? "Updating..." : "Generating...",
    });

    objBillAPI.billGenerate(values)
    .then(response=>
      {
      if (response.data != "Internal Error!") {
        setValues({ ...values, billMsg: "Done!" });
        // alert(response.data);
       // window.open(response.data, "_blank").focus();
       handleClose(true);
       // window.location.reload();
      } else {
        alert("Something is wrong! Please try again");
      }

      console.log(response);
    })
    .catch(function (error) {
    
      setValues({ ...values, billMsg: "Failed Due To Server Error!" });
      //  alert(error);
      //  window.location.reload();

      // console.log(error);
    })
 
    };

  const handleChangeChk = (name) => (event) => {
    // values[name]=event.target.value;
    // setState({ ...state, [name]: event.target.checked });
    setValues({ ...values, [name]: event.target.checked });
    //console.log(event.target.checked)
  };
  const billRateChange=()=>{
    values["billAmount"] = math.evaluate(
      values["billQty"] * values["billRate"]
    );
    values["billGSTAmount"] = math.format(
      ((values["billGST"] / 100) ||0) * values["billAmount"],
      { notation: "fixed", precision: 2 }
    );
    values["billSEDAmount"] = math.format(
      (values["billSED"] / 100) * values["billAmount"],
      { notation: "fixed", precision: 2 }
    );
    values["billAGSTAmount"] = math.format(
      (values["billAGST"] / 100) * values["billAmount"],
      { notation: "fixed", precision: 2 }
    );
    values["billTotal"] = math.round(
      math.sum(
      parseFloat( values["billAmount"]),
      parseFloat( values["billGSTAmount"])||0,
      parseFloat(  values["billSEDAmount"]),
      parseFloat(  values["billAGSTAmount"])
      )
    );
  
  }
  const handleChange = (name) => (event) => {
    console.log(name);
    try {
      values[name] = event.target.value;
      switch (name) {
        case "billGST":
        case "billSED":
        case "billQty":
        case "billAGST":
        case "billRate": {
          // values["billAmount"] = math.evaluate(
          //   values["billQty"] * values["billRate"]
          // );
          // values["billGSTAmount"] = math.format(
          //   (values["billGST"] / 100) * values["billAmount"],
          //   { notation: "fixed", precision: 2 }
          // );
          // values["billSEDAmount"] = math.format(
          //   (values["billSED"] / 100) * values["billAmount"],
          //   { notation: "fixed", precision: 2 }
          // );
          // values["billAGSTAmount"] = math.format(
          //   (values["billAGST"] / 100) * values["billAmount"],
          //   { notation: "fixed", precision: 2 }
          // );
          // values["billTotal"] = math.round(
          //   math.sum(
          //     values["billAmount"],
          //     values["billGSTAmount"],
          //     values["billSEDAmount"],
          //     values["billAGSTAmount"]
          //   )
          // );
          billRateChange();
          break;
        }

        default: {
        }
      }

      setValues({ ...values, [name]: event.target.value });
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    console.log("Bill Entry Form");
    if (values.bill_id != 0) {
      fetchBillByID();
    } else {
      fetchNumber();
    }
    async function fetchNumber() {
      objBillAPI
        .billNewNumber()
        .then((result) => {setValues({ ...values, billNumber: result });
        
      })
        .catch((err) => alert(err));
      // const result = await axios(`${API}/invoice/billNewNumber`);

      // setValues({ ...values, billNumber: result.data });
    }

    async function fetchBillByID() {
      console.log("fetchBillByID");
      const result = await axios.post(`${API}/invoice/getBillByID`, {
        bill_id: values.bill_id,
      });

      //   setValues({...values,custs:c,billQty: result.data[0].bill_qty,bill_cust_id:result.data[0].bill_cust_id})
      setValues({
        ...values,
        bill_id: result.data[0].bill_id,
        billQty: result.data[0].bill_qty,
        InvQtyKG: result.data[0].bill_qty_kg,
        billNumber: result.data[0].bill_invoice_num,
        billDate1: result.data[0].bill_date1.split("T")[0],
        billRate: result.data[0].bill_price,
        billOrderNumber: result.data[0].bill_order_mumber,
        billTotal: result.data[0].bill_total,
        billp: result.data[0].bill_price,
        InvBuyerName: result.data[0].bill_client_name,
        bill_cust_id: result.data[0].bill_cust_id,
        billParticullars: result.data[0].bill_particullars,
        // billOrderNumber:result.data[0].bill_order_mumber,
        billItemCode: result.data[0].bill_item_code,
        billAmount: result.data[0].bill_amount,
        billGST: result.data[0].bill_gst_rate,
        billGSTAmount: result.data[0].bill_gst_amount,
        billSED: result.data[0].bill_sed_rate,
        billSEDAmount: result.data[0].bill_sed_amount,
        billAGST: result.data[0].bill_agst_rate,
        billAGSTAmount: result.data[0].bill_agst_amount,
        billVendorCode: result.data[0].bill_vendor_code,
        bill_hscode: result.data[0].bill_hscode,
      });
      //console.log(values.billOrderNumber)
      //console.log(moment(result.data[0].bill_date1.split('T')[0]).format("mm/dd/yyyy"))
    }
  }, []);

  useEffect(()=>{
    billRateChange();
    setValues({ ...values, billRate:values.billRate });
  },[])

  return (
    <React.Fragment>
      {/* 
        <Typography variant="h6" gutterBottom>
       Bill
      </Typography>
     */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="billNumber"
              name="billNumber"
              label="Bill Number"
              fullWidth
              value={values.billNumber}
              onChange={handleChange("billNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              label="Date1"
              fullWidth
              type="date"
              value={values.billDate1}
              onChange={handleChange("billDate1")}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="Rate"
              fullWidth
              value={values.billRate}
              onChange={handleChange("billRate")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.billAmount}
              onChange={handleChange("billAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="GST %"
              fullWidth
              value={values.billGST}
              inputProps={{
                style: { textAlign: "right" },
              }}
              onChange={handleChange("billGST")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="GST Amount"
              fullWidth
              
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.billGSTAmount}
              onChange={handleChange("billGSTAmount")}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="SED %"
              fullWidth
              value={values.billSED}
              onChange={handleChange("billSED")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="SED Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.billSEDAmount}
              onChange={handleChange("billSEDAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="AGST %"
              fullWidth
              value={values.billAGST}
              onChange={handleChange("billAGST")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="AGST Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.billAGSTAmount}
              onChange={handleChange("billAGSTAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              inputProps={{
                style: { textAlign: "right", fontWeight: "bold" },
              }}
              label="Total"
              fullWidth
              disabled
              value={values.billTotal}
              onChange={handleChange("billTotal")}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
            >
              {values.bill_id != 0 ? "Update" : "Generate"}
            </Button>
            &nbsp;&nbsp;
            <FormLabel>{values.billMsg}</FormLabel>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="bill_send_email"
                  checked={values.bill_send_email}
                />
              }
              label="Send Email?"
              onChange={handleChangeChk("bill_send_email")}
            />
            <TextField
              label="Email To Address(es):"
              fullWidth
              value={values.bill_send_email ? values.bill_to_emails : ""}
              onChange={handleChange("bill_to_emails")}
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
