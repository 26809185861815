import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import BillList from "./cheque_bill_list";
import numeral from "numeral";
export const Popup = ({ cheque, handleClose, customers}) => {
  const [open, setOpen] = useState(true);
  
  let bills =[];
  //const [cheque,setCheque] = useState(cheque);
  function handleCancel(newValue) {
    setOpen(false);
    handleClose();
  }
  function addSelectedBill(newBills) {
   bills = newBills;
  }
  function handleUpdate() {}
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth={true}
      //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        {cheque.cust_name} <span style={{'color':"green"}}>
          Cheque #: {cheque.ch_number} | Amount:{numeral(cheque.ch_amount).format("0,0")} | Remaining: {numeral(cheque.remaining).format("0,0")}
        </span>
      </DialogTitle>
      <DialogContent dividers>

        <BillList cheque={cheque}  handleCancel={handleCancel} ></BillList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
