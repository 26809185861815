import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";

import numeral from "numeral";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import NativeSelect from "@material-ui/core/NativeSelect";
import {AccordionSummary, Button} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {OrderEntryForm as InvoicePopup, PaymentEntryForm as PaymentPopup} from "./popup_non_tax_invoice"
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BillList from './billList'
import axios from "axios";
import API from '../../config';
import OrderAPI, { BillAPI, CustomersAPI, HSCodesAPI } from "../../BL";
var moment = require("moment-timezone");
function makeLinkNonTax(lnk) {
  return "/server/PDF/NonTax/" + lnk + ".pdf";
}
const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    link: {
      margin: theme.spacing(1, 1.5),
    
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    //   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //     width: 600,
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //   },
    },
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    boldInput:{
      textAlign:'right',
     fontSize:18 
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  
 // Function to get the previous month in 'YYYY-MM' format
const getPreviousMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth());
  //date.setMonth(date.getMonth() - 1);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${year}-${month}`;
};
export default function Checkout() {
  const objCustomersAPI = new CustomersAPI();
  const objBillAPI = new BillAPI();
  
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [showPopup, setShowPopup] = React.useState(false);
    const [showPaymentPopup, setShowPaymentPopup] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
  const classes = useStyles();
  const [customer, setCustomer] = useState({})
  const [customers, setCustomers] = useState([])
  const [invoices, setInvoices] = useState([])
  const [summary, setSummary] = useState({total:0,paid:0,dues:0})
  
  const [previousMonth, setPreviousMonth] = useState(getPreviousMonth());
  useEffect(() => {
    fetchCustomer();
   
   
  }, []);
  useEffect(() => {
    if(customer!=""){
      fetchBills();
    }

   
   
  }, [customer]);


  function fetchBills() {
    setIsLoading(true)
    setShowPopup(false)

    objBillAPI.getNonTaxBills({ntb_cust_id:customer.cust_id})
    
      .then((res) => {
        setInvoices(res.bills);
        var _temp = summary;
        _temp.paid=res.paid.paid;
       var _total = res.bills.reduce((accumulator, currentObject) => accumulator + currentObject.ntb_payable , 0);
        setSummary({...summary,paid:res.paid.paid,total:_total,dues:(_total-res.paid.paid)})
      })
      
      .catch((err) => alert(err)).finally(()=>setIsLoading(false));
  }
  function fetchCustomer() {
    objCustomersAPI
    .getCustomersNonTax()
      
      .then((customers) => {
        setCustomers(customers);
      })
      
      .catch((err) => alert(err));
  }
  function handleChangeTab(event, newValue) {
    setValue(newValue);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
  }
  const handleSelect = (event) => {
    //console.log(custs[event.target.value])
    if (event.target.value !== -1) {
      setCustomer( customers.find(f=>f.cust_id==event.target.value) );
    }
  };

  
  const handlePayment =(event)=>{
    event.preventDefault();
    console.log(customer)
    if( !customer.cust_name){

      alert('Please select client.')

    }
    else {
      setShowPaymentPopup(true)

    }
  }

  const handleGenerateInvoice =(event)=>{
    event.preventDefault();
    if(!customer.cust_name){

      alert('Please select client.')

    }
    else {
      setShowPopup(true)

    }
  }
  const handleClick = () => {
    alert('Button clicked!');
  };
const deleteInvoice =(id,number,_action)=>{

  var action = _action=='d'?'delete':'update';
  if (

    window.confirm("Do you want to "+action+" this invoice (" + number + ")?")
  ) {
    objBillAPI
      .deleteInvoiceNonTaxByID(id,action)
      .then(() =>{

fetchBills()

      } )
      .catch((err) => alert(err));
  }

}
  const handleClose =()=>{
setShowPaymentPopup(false)
    setShowPopup(false)
    fetchBills();
  }
  return (

    
      <React.Fragment>
    
    <NativeSelect
              
                required
                onChange={handleSelect}
              >
                <option value="-1" >Please Select Client</option>
                {customers.map((d, idx) => {
                  return (
                    <option value={d.cust_id} key={idx}>
                      {d.cust_name}
                    </option>
                  );
                })}
              </NativeSelect>&nbsp;
              {/* <Button  variant="contained" color="primary"  onClick={handleGenerateInvoice} >Generate Bill ({previousMonth})</Button> &nbsp; */}
              <Button  variant="contained" color="primary"  onClick={handlePayment} >Payment</Button> 
              &nbsp;
              {/* Total:&nbsp;{summary.total} &nbsp;Paid:&nbsp;{summary.paid}&nbsp;Dues:&nbsp;{summary.dues}  */}
       <br></br> <br></br>

       {showPopup && (
            <InvoicePopup
              handleClose={handleClose}
              customer={customer}
              previousMonth={previousMonth}
              
            ></InvoicePopup>
          )}

          
       {showPaymentPopup && (
            <PaymentPopup
              handleClose={handleClose}
              customer={customer}
              previousMonth={previousMonth}
              
            ></PaymentPopup>
          )}
             {isLoading? (
        <h2>Loading...</h2>
      ) : (
        <Table size="small"  padding="none">
          <TableHead>
            <TableRow className={classes.colorH}>
              <TableCell align="center" className="action" padding="none" size="small" width="100">
                Action
              </TableCell>
              <TableCell align="center" className="action" padding="none" size="small" width="50">
                Invoice#
              </TableCell>
              <TableCell align="center" width="100">
                Month
              </TableCell>
              <TableCell align="center"  width="100">
                Generated
              </TableCell>
            
              <TableCell align="center"  width="100">Total</TableCell>
       
              <TableCell align="center"  width="100">Adjustment</TableCell>
              <TableCell align="center"  width="100">Payable</TableCell>
              <TableCell align="center" ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              invoices.map((rowData, idx) => (
                <TableRow   style={rowData.ntb_status === 0 ? { textDecoration: 'line-through' } : {}} key={idx}>
                  <TableCell    style={{ border: '1px solid gray', padding: '3px' }} lassName="action">
           
                    <IconButton
                      color="secondary"
                      title="Delete Invoice."
                      aria-label="Delete Invoice."
                      component="span"
                      size="small"
                      onClick={() =>
                        deleteInvoice(rowData.ntb_id, rowData.ntb_number,'d')
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      title="Cancel Invoice."
                      aria-label="Cancel Invoice."
                      component="span"
                      size="small"
                      onClick={() =>
                        deleteInvoice(rowData.ntb_id, rowData.ntb_number,'c')
                      }
                    >
                      <CancelIcon />
                    </IconButton>
                    {/* <IconButton
                      color="primary"
                      aria-label="Edit Order"
                      component="span"
                      size="small"
                       onClick={() => editOrder(rowData.ont_id)}
                    >
                      <EditIcon />
                    </IconButton> */}
                   
                  </TableCell>
                  <TableCell style={{ border: '1px solid gray', padding:'3px' }} align="center"><a target='_blank' href={makeLinkNonTax(rowData.ntb_path)}>{rowData.ntb_number}</a></TableCell>
                  <TableCell style={{ border: '1px solid gray', padding:'3px' }} align="center"><a  target='_blank' href={makeLinkNonTax(rowData.ntb_path)}>{rowData.ntb_month}</a></TableCell>
                  <TableCell style={{ border: '1px solid gray', padding:'3px' }} align="center">
                    {moment(rowData.ont_order_date)
                      .tz("Asia/Karachi")
                      .format("DD-MM-YY")}
                  </TableCell>
                  
                  <TableCell style={{ border: '1px solid gray', padding:'3px' }} align="right">{ numeral(rowData.ntb_total).format('0,0')}</TableCell>
          
                  <TableCell style={{ border: '1px solid gray', padding:'3px' }} align="right">{numeral(rowData.ntb_adjustment).format('0,0')}</TableCell>
                  <TableCell style={{ border: '1px solid gray', padding:'3px' }} align="right">{numeral(rowData.ntb_payable).format('0,0')}</TableCell>
                
                  <TableCell></TableCell>
               
                  
                </TableRow>
              ))}
           
          </TableBody>
        </Table>
      )}
       
    </React.Fragment>
    

  );
}