import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import axios from "axios";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import numeral from "numeral";
import PaymentList from "./paymentList";
import _ from "underscore";
import API from '../../config';
const today = moment().format("YYYY-MM-DD")
const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    paper: {
        width: "80%",
        maxHeight: 435
    }
}));
function ConfirmationDialogRaw(props) {
    const { onClose, onUpdate, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    function handleEntering() {
        // if (radioGroupRef.current != null) {
        //   radioGroupRef.current.focus();
        // }
    }

    function handleCancel() {
        onClose();
    }
    function handleUpdate() {
        onUpdate();
    }
    function handleOk() {
        onClose(value);
    }

    if (open) {
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                onEntering={handleEntering}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">
                    Bill No. {value.bill_invoice_num}
                </DialogTitle>
                <DialogContent dividers>
                    <PaymentList
                        bill_id={value.bill_id}
                        update={handleUpdate}
                    ></PaymentList>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
          </Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
          </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return <b></b>;
    }
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object
};
export default function BillList() {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(-1);
    const [value, setValue] = React.useState({});
    const [values, setValues] = React.useState({
        
        
    })
    let custs = [];
    let custsHash ={}
    let  months={'1':'January','2': 'February','3': 'March','4': 'April','5': 'May','6': 'June','7': 'July','8': 'August','9': 'September','10': 'October','11': 'November','12': 'December'};

    async function fetchCustomer() {
        
        const customers = await axios.post(
            `${API}/customer/getCustomers`, { cust_type: 'input' }
        );
        custs = customers.data;

        // const arr = Object.keys(customers.data).map((key) => [key, json_data[key]]);

        // console.log(arr);

 custs.map((d,idx)=>
 custsHash[d.cust_id]=d.cust_name
      )


        fetchList();
    }
    async function fetchList() {
        const result = await axios(
            `${API}/invoice/getInputBills`
        );
var d = {...result.data,edit:false}

       setState({ ...state, data: result.data, custs: custs, custsHash });

    }

    useEffect(() => {
        fetchCustomer();


    }, []);


    function handleClose(newValue) {
        setOpen(false);
        setShow(false);
        setOpenEdit(-1);
        // if (newValue) {
        //   setValue(newValue);
        // }
    }


    function handleCancel(newValue) {
        setOpen(false);
    }
    function handleUpdate() {
        setOpen(false);
        // fetchList();
    }
    


    const [show, setShow] = React.useState(false);
    var selectedCust = { cust_id: 0, cust_name: "" }
    const [state, setState] = React.useState({
        columns: [
            {
                title: "",
                cellStyle:{ padding: '0px'},
                render: rowData => (
                    <span>
                        <a
                            rel="noopener noreferrer"
                            onClick={() => {
                                handleCancel(rowData);
                            }}
                            target="_blank"
                            style={{ cursor: "pointer" }}
                        >

                        </a>

                    </span>
                )
            },

            {
                title: "Inv/GD #",
                field: "ib_invoice_number",
                initialEditValue:"0",
                cellStyle:{ padding: '5px'},
            },


            {
                title: "Date",
                cellStyle:{ padding: '5px'},
                field: "ib_date",
                type: "date",
                initialEditValue:moment().format("YYYY-MM-DD"),
                render: rowData => (
                    <span>{moment(rowData.ib_date).format("DD-MM-YYYY")}</span>
                )
            },
            {
                title: "Month",
                cellStyle:{ padding: '5px'},
                field: "ib_month",
                lookup:months,
                initialEditValue:"1"
            },
            {
                title: "Claim Month",
                cellStyle:{ padding: '5px'},
                field: "ib_claim_month",
                lookup:months,
                initialEditValue:"1"
            },
            {
                
              
            
                    title: "Seller",
                    cellStyle:{ padding: '5px'},
                    field: "ib_cust_id",
                    lookup: custsHash 
            },

            {
                title: "Inv. Value",
                cellStyle:{ padding: '5px'},
                field: "ib_bill_amount",
                type: "numeric",
                initialEditValue:"0",
                render: rowData => (
                    <span>{numeral(rowData.ib_bill_amount).format("0,0")}</span>
                )
            },
            {
                title: "GST 17 %",
                field: "ib_gst_amount",
                cellStyle:{ padding: '5px'},
                type: "numeric",
                initialEditValue:"0",
                render: rowData => (
                    <span>
                        {numeral(rowData.ib_gst_amount).format("0,0")}
                    </span>
                )
            },
            {
                title: "Inv. Total",
                cellStyle:{ padding: '5px'},
                field: "ib_total",
                type: "numeric",
                initialEditValue:"0",
                render: rowData => (
                    <span>
                        {numeral(rowData.ib_total).format("0,0")}
                    </span>
                )
            },
            {
                title: "HSCode",
                cellStyle:{ padding: '5px'},
                field: "ib_hscode",
                initialEditValue:"",
                type:"string"
            },
            {
                title: "Material Consump.",
                cellStyle:{ padding: '5px'},
                field: "ib_material_consumption",
                type: "numeric",
                initialEditValue:"0",
                render: rowData => (
                    <span>
                        {numeral(rowData.ib_material_consumption).format("0,0")}
                    </span>
                )
            },
            {
                title: "Paper Consump.",
                cellStyle:{ padding: '5px'},
                field: "ib_paper_consumption",
                type: "numeric",
                initialEditValue:"0",
                render: rowData => (
                    <span>
                        {numeral(rowData.ib_paper_consumption).format("0,0")}
                    </span>
                )
            },
            {
                title: "Income Tax",
                cellStyle:{ padding: '5px'},
                field: "ib_income_tax",
                type: "numeric",
                initialEditValue:"0",
                render: rowData => (
                    <span>
                        {numeral(rowData.ib_income_tax).format("0,0")}
                    </span>
                )
            }
        ],
        data: [],
        custs: [],
        hscodes:[],
        months:{'1':'January','2': 'February','3': 'March','4': 'April','5': 'May','6': 'June','7': 'July','8': 'August','9': 'September','10': 'October','11': 'November','12': 'December'},
        selectedCust: 0,
        ib_invoice_number:"",
        ib_date: today,
        ib_gst_amount:"",
        ib_bill_amount:"",
        ib_total:"",
        ib_cust_id:"",
        ib_month:"",
        ib_claim_month:"",
        ib_material_consumption:0,
        ib_paper_consumption:0,
        ib_income_tax:0,
        ib_hscode:"",
        ib_cust_name:""
    });

    return (
        <div>
 

            <ConfirmationDialogRaw
                classes={{
                    paper: classes.paper
                }}
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                onUpdate={handleUpdate}
                value={value}
            />
            <MaterialTable
                title="Input Invoice Setup"
                columns={state.columns}
                data={state.data}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100],
                    toolbar: true,
                    paging: true,
                    padding: "dense",
                    sorting:false,
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise(resolve => {
                            newData.ib_cust_name = state.custsHash[newData.ib_cust_id];
                            axios.post(`${API}/invoice/insertInputBill/`, {

                                ...newData
                            })
                                .then(function (response) {

                                    resolve();
                                    const data = [...state.data];
                                    data.push(newData)
                                    // data[data.indexOf(oldData)] = newData;
                                    setState({ ...state, data });


                                })
                                .catch(function (error) {
                                    alert('Server Error');
                                    resolve();
                                    console.log(error);
                                });
                        })
                    ,
                    onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            newData.ib_cust_name = state.custsHash[newData.ib_cust_id];
                            console.log(newData)
                            axios.post(`${API}/invoice/updateInputBill/`, {

                                ...newData
                            })
                                .then(function (response) {

                                    resolve();
                                    const data = [...state.data];
                                    data[data.indexOf(oldData)] = newData;
                                    setState({ ...state, data });


                                })
                                .catch(function (error) {
                                    // message = error;
                                    console.log(error);
                                });
                        }),
                    onRowDelete: oldData =>
                    new Promise(resolve => {
                        axios
                          .post(
                            `${API}/invoice/deleteInputBill/`,
                            {
                              ib_id: oldData.ib_id
                            }
                          )
                          .then(function(response) {
                            // console.log(response);
          
                            const data = [...state.data];
                            data.splice(data.indexOf(oldData), 1);
                            setState({ ...state, data });
                            resolve();
                            // props.update();
                          })
                          .catch(function(error) {
                            // message = error;
                            console.log(error);
                          });
                      }),
                }}
            />








        </div>
    );

}
