import React, { useEffect } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import moment from "moment";
import numeral from "numeral";
import { Popup } from "./cheque_bill_popup";
import API from '../../config';
export default function ChequeList(props) {

  const [show, setShow] = React.useState(false);
  const [cheque, setCheque] = React.useState(false);
  function handleClose(newValue) {
    setShow(false);
    fetchList();
    // props.updateList()
    // if (newValue) {
    //   setValue(newValue);
    // }
  }


  const [state, setState] = React.useState({
    columns: [
      { title: "Customer", field: "cust_name" },
      { title: "Bank", field: "ch_bank" },
      { title: "Cheque #", field: "ch_number" },
      {
        title: "Date",
        field: "ch_date",
        type: "date",
        render: rowData => (
          <span>{moment(rowData.ch_date).format("DD-MM-YYYY")}</span>
        )
      },
      { title: "Amount", field: "ch_amount" },
      { title: "TAX", field: "ch_tax" },
      {
        title: "Remaining",
        field: "remaining",
        render: rowData => (
          <span>{numeral(rowData.remaining).format("0,0")}</span>
        )
      }
    ],
    data: []
  });
  async function fetchList() {
    const result = await axios(
      `${API}/cheque/getCheques`
    );

    setState({ ...state, data: result.data });
  }
  // if(Object.keys(props.data).length){
  //   const data = [...state.data];
  //           data.push(props.data);
  //           setState({ ...state, data });
  // }

  //    else
  useEffect(() => {
    fetchList();
  }, [props.data]);
  return (
    <div>
      {show && <Popup handleClose={handleClose} cheque={cheque}  customers={props.customers}></Popup>}
      <MaterialTable
        title="Cheque List"
        columns={state.columns}
        data={state.data}
        options={{
          paging: true ,
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          padding: "dense",
        }}
        editable={{
          // onRowAdd: newData =>
          //   new Promise(resolve => {
          //     setTimeout(() => {
          //       resolve();
          //       const data = [...state.data];
          //       data.push(newData);
          //       setState({ ...state, data });
          //     }, 600);
          //   }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              newData.ch_date = moment(newData.ch_date).format("YYYY-MM-DD");
              axios
                .post(
                  `${API}/cheque/updateCheque/`,
                  {
                    ...newData
                  }
                )
                .then(function(response) {
                  resolve();
                  const data = [...state.data];
                  data[data.indexOf(oldData)] = newData;
                  setState({ ...state, data });
                })
                .catch(function(error) {
                  // message = error;
                  console.log(error);
                });
            }),
          onRowDelete: oldData => {

if(oldData.ch_amount==oldData.remaining)
    {
     return    new Promise(resolve => {
              axios
                .post(
                 `${API}/cheque/deleteCheque/`,
                  {
                    ch_id: oldData.ch_id
                  }
                )
                .then(function(response) {
                  // console.log(response);
                  resolve();
                  const data = [...state.data];
                  data.splice(data.indexOf(oldData), 1);
                  setState({ ...state, data });
                })
                .catch(function(error) {
                  // message = error;
                  console.log(error);
                });
            })

    }

            else {
alert("Please, first delete its payments.");
             return  Promise.resolve();
            }
          }
   
        }}
      />
    </div>
  );
}
