import React, { useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import ChallanSetup from '../challan/entryform1';

import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
export default function ChallanPopup ({handleClose,onSubmitChallan,order}) {
  const classes = useStyles();

    const handleCancel =(newValue) => {
        console.log('handleCancel')
       // setOpen(false);
        handleClose(false)
      }
      const handleUpdate=(value)=>{
        // updateList(value)
        // setOpen(false);
        handleClose(true)
      }
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
    //   onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={true}
    //   {...other}
    >
      <DialogTitle id="confirmation-dialog-title" >
       Order No.: {order.order_number} | Order Date:  {moment(order.order_date)
                    .tz("Asia/Karachi")
                    .format("DD-MM-YYYY")} | Rate: {order.order_price}&nbsp; | Demand: {order.order_qty}&nbsp;<span style={{color:"gray", fontSize:'12px'}}>{order.order_qty_kg}</span>
                         <Avatar alt="Remy Sharp"  style={{float:"right"}} className={classes.orange}>
                    C
</Avatar>
     
      </DialogTitle>
      <DialogContent style={{backgroundColor:'lightyellow'}} dividers>
      <ChallanSetup handleUpdate={handleUpdate}  order={order} onSubmitChallan={onSubmitChallan} handleClose={handleClose} ></ChallanSetup>
      </DialogContent>
      <DialogActions>
        <Button 
        onClick={handleCancel} 
        color="primary">
          Cancel
        </Button>
        {/* <Button
        //  onClick={handleOk} 
         color="primary">
          Ok
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
