import React, {  useEffect,useState } from "react";

import PropTypes from 'prop-types';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EntryForm from './entryform';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChallanList from './List'
import StockList from './stockList';
import axios from "axios";
import API from '../../config';
const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    link: {
      margin: theme.spacing(1, 1.5),
    
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    //   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //     width: 600,
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //   },
    },
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    boldInput:{
      textAlign:'right',
     fontSize:18 
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
export default function Checkout() {
  const [forceRerender, setForceRerender] = React.useState(true);
  const [refreshEntryForm, setrefreshEntryForm] = React.useState(false);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  const classes = useStyles();
  function handleChangeTab(event, newValue) {
    setValue(newValue);
  }
  
  const [customers, setCustomers] = useState([]);
  const [hscodes, setHscodes] = useState([])
useEffect(() => {
  
  fetchCustomer();
  fetchHSCodes();
  async function fetchCustomer() {
    console.log('Main Challan')
    const customers = await axios.post(
      `${API}/customer/getCustomers`,{cust_type:'output'}
    );

    setCustomers(customers.data);
  
  }
  async function fetchHSCodes() {
    const hscode = await axios(
      `${API}/challan/getHSCodes`
    );
    setHscodes(hscode.data)
    
  }

}, [])
  function handleSubmitChallan ()  {

    setForceRerender(!forceRerender);
   // useRedirect('/challan/');

}
function handleDeleteChallan(){
console.log('handleDeleteChallan')
  setrefreshEntryForm(!setrefreshEntryForm);
}
  function handleChangeTabIndex(index) {
    setValue(index);
  }
  return (
    <React.Fragment>
     
   
      {customers.length==0 || hscodes.length==0  ?<h1>Loading...</h1>:
      <main className={classes.layout}>
       
          {/* <Typography component="h1" variant="h4" align="center">
            Challan
          </Typography> */}
          <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary">
          <Tab label="Add New" {...a11yProps(0)} />
          <Tab label="List" {...a11yProps(1)} />
          <Tab label="Stocks" {...a11yProps(2)} />
          </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeTabIndex}>
       
        <TabPanel value={value} index={0} dir={theme.direction}>
        
        <EntryForm  onSubmitChallan={()=>handleSubmitChallan()   } refreshEntryForm={refreshEntryForm} customers={customers} hscodes={hscodes} ></EntryForm>
        
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <ChallanList load={forceRerender}  onDeleteChallan={()=>handleDeleteChallan() } customers={customers} hscodes={hscodes}  ></ChallanList>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <StockList></StockList>
        </TabPanel>
        </SwipeableViews>
     
      
      </main>
}
    </React.Fragment>
  );
}