import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import numeral from "numeral";
import PaymentList from "./paymentList";
import API from '../../config';
const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));
function ConfirmationDialogRaw(props) {
  console.log('1')
  const { onClose, onUpdate, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {

    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  function handleEntering() {
   
    // if (radioGroupRef.current != null) {
    //   radioGroupRef.current.focus();
    // }
  }

  function handleCancel() {

    onClose();
  }
  function handleUpdate() {

    onUpdate();
  }
  function handleOk() {

    onClose(value);
  }

  if (open) {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        onEntering={handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">
          Bill No. {value.bill_invoice_num}
        </DialogTitle>
        <DialogContent dividers>
          <PaymentList
            bill_id={value.bill_id}
            update={handleUpdate}
          ></PaymentList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <b></b>;
  }
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.object,
};

export default function StockList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(-1);
  const [value, setValue] = React.useState({});
  const [values, setValues] = React.useState({
    hscode: "",
    fromDate: moment().tz("Asia/Karachi").subtract(1, 'year').format("YYYY-MM-DD"),
    toDate: moment().tz("Asia/Karachi").format("YYYY-MM-DD"), 
  });

  async function fetchList() {
    console.log('1')
    const result = values.hscode!=='5603'? await axios.post(
      `${API}/stock/getStocks`,
      { hscode: values.hscode  !=='5603'?values.hscode: '1',toDate:values.toDate,fromDate:values.fromDate }
    ) : await axios.post(
      `${API}/stock/getStocksPaper`,
      { hscode: values.hscode ,toDate:values.toDate,fromDate:values.fromDate }
    );
let stock =0;
var tempStock =0;
var tempChallanQty=0
let col = values.hscode==='5603'?'challan_paper_consumption' : 'challan_material_consumption'
 result.data.map((m,idx)=>{
     
    
     let weight = m[col];

     var bill_date1 = moment(m.bill_date1).format("YYYY-MM-DD");
     var fromDate = moment(values.fromDate).format("YYYY-MM-DD");
     var toDate = moment(values.toDate).format("YYYY-MM-DD");
     
     if(!moment(bill_date1).isBetween(fromDate,toDate)){

tempChallanQty += m.challan_qty
       if (m.type==='IN')
       tempStock += weight;
    else
    tempStock -=weight;

  
     }
//console.log(moment(m.bill_date1).format("YYYY-MM-DD"),moment(values.fromDate).format("YYYY-MM-DD"),moment(values.toDate).format("YYYY-MM-DD"),moment(bill_date1).isBetween(fromDate,toDate))

    })
    stock=tempStock;
  console.log(stock)
    result.data.unshift({ 'display':true,'stock_date': 'N/A',type:'N/A','bill_invoice_num':'Opening','challan_number':'N/A','challan_qty':tempChallanQty,  'stock':tempStock,'weight':tempStock,'bill_hscode':values.hscode})
    
    
    var d =   result.data.map((m,idx)=>{
     
    
      let weight = m[col];
 
      var bill_date1 = moment(m.bill_date1).format("YYYY-MM-DD");
      var fromDate = moment(values.fromDate).format("YYYY-MM-DD");
      var toDate = moment(values.toDate).format("YYYY-MM-DD");
      if(idx==0 ){
        return { bill_date1:'','display':true,'stock_date': 'N/A',type:'N/A','bill_invoice_num':'Till '+moment(values.fromDate).format("DD-MM-YYYY"),'challan_number':'N/A','challan_qty':'N/A',  'stock':tempStock,'weight':tempStock,'bill_hscode':values.hscode}
         }
      else if(!moment(bill_date1).isBetween(fromDate,toDate)){
     
   
      }
 //console.log(moment(m.bill_date1).format("YYYY-MM-DD"),moment(values.fromDate).format("YYYY-MM-DD"),moment(values.toDate).format("YYYY-MM-DD"),moment(bill_date1).isBetween(fromDate,toDate))
 else {   
 
   m.display=true;
 if (m.type==='IN')
     stock += weight;
     else
     stock -=weight;
     
 }
 let n = {...m,'bill_date1': moment(m.bill_date1).format("DD-MM-YYYY"),'stock':stock.toFixed(2),'weight':weight.toFixed(2),'bill_hscode':values.hscode}
 return n;
     })
//console.log(tempStock)

d= d.filter(function(f) { return f.display===true});
console.log(d)
    setState({ ...state, data: d });


  }

  function handleClose(newValue) {
    console.log('1')
    setOpen(false);
    setShow(false);
    setOpenEdit(-1);
    // if (newValue) {
    //   setValue(newValue);
    // }
  }
  const handleChange = (name) => (event) => {
    console.log('1')
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = (event) => {
    console.log('1')
    event.preventDefault();
    console.log(values.hscode);
    fetchList();
  };

  function handleUpdate() {
    console.log('1')
    setOpen(false);
    // fetchList();
  }

  const [show, setShow] = React.useState(false);

  const [state, setState] = React.useState({
    columns: [
      {
        title: "#",
        render: (rowData) => <span>{rowData.tableData.id + 1}</span>,
        
      },

      {
          
        title: "Date",
        field: "bill_date1",
        type: "date",
       // initialEditValue: moment().format("YYYY-MM-DD"),
        // render: (rowData) => (
        //   <span>{moment(rowData.bill_date1).format("DD-MM-YYYY")}</span>
        // ),
      },
      {
        title: "OUT/IN",
        field: "type",
        render: (rowData) => <span>{rowData.type}</span>,
      },
      {
        title: "Inv. #/ GD #",
        field: "bill_invoice_num",

        render: (rowData) => <span>{rowData.bill_invoice_num}</span>,
      },
      {
        title: "Challan #",
        field: "challan_number",
      
      },
      {
        title: "Qty.",
        field: "challan_qty",
        type: "numeric",
        render: (rowData) => <span>{ numeral(rowData.challan_qty).format("0,0.00")}</span>,
      },
      {
        title: "Code",
        field: "bill_hscode",
      },

      {
        title: "Weight (KG)",
        field: "weight",
        type: "numeric",
        render: (rowData) => <span>{ numeral(rowData.weight).format("0,0.00")}</span>,
      },
      {
        title: "Stock",
        
        type: "numeric",
        field: "stock",
        render: (rowData) => <span>{ numeral(rowData.stock).format("0,0.00")}</span>,
      },
    ],
    data: [],

    stock_date: today,
    stock_weight: 0,
    stock_hscode_id: "",
  });

  return (
    <div>
      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        onUpdate={handleUpdate}
        value={value}
      />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={1}>
            <TextField
              required
              id="hscode"
              name="hscode"
              label="HS. CODE"
              
              value={values.hscode}
              onChange={handleChange("hscode")}
            />
          </Grid>
          <Grid item  sm={2}>
            <TextField
              required
              label="From"
              
              type="date"
              value={values.fromDate}
              onChange={handleChange("fromDate")}
            />
          </Grid>
          <Grid item  sm={2}>
               <TextField
              required
              label="To"
              
              type="date"
              value={values.toDate}
              onChange={handleChange("toDate")}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </form>
      <MaterialTable
        title="Stock Register"
        columns={state.columns}
        data={state.data}
        options={{
          exportButton: true,
          exportAllData:true,
          exportFileName:"Stock Register ["+values.hscode+"] From: "+moment(values.fromDate).format("DD-MM-YYYY")+" To: "+moment(values.toDate).format("DD-MM-YYYY")+"",
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          toolbar: true,
          paging: true,
          padding: "dense",
          rowStyle: (rowData) => {
            if (rowData.type === "IN") {
              return {
                fontWeight: "bolder",
                backgroundColor: "#a5d6a7",
              };
            }
            else if (rowData.type === "N/A") {
              return {
                fontWeight: "bolder",
                backgroundColor: "Orange",
              };
            }
            else {
              return {
                backgroundColor: "#e8f5e9",
              };
            }
          },
        }}
      />
    </div>
  );
}
