import React,{ useEffect } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import API from '../../config';
export default function CustomerList(props) {
  const [state, setState] = React.useState({
    columns: [
    
      
      { title: 'Name',  field: 'cust_name'},
      { title: 'Address', field: 'cust_addr' },
      { title: 'STRN', field: 'cust_strn' },
      { title: 'NTN',  field: 'cust_ntn' },
      { title: 'Item Code',  field: 'cust_itemcode'},     
      { title: 'Vendor Code',  field: 'cust_vendorcode' },
    ],
    data: [ ],
  });
  useEffect( () => {
  
    async function fetchList(){
  
               
        const result = await axios.post(
          `${API}/customer/getCustomers`,{cust_type:''},
          );
       
         setState({ ...state, "data": result.data });
    
       }
    // if(Object.keys(props.data).length){
    //   const data = [...state.data];
    //           data.push(props.data);
    //           setState({ ...state, data });
    // }
      
    //    else
       fetchList()
    },[props.data]);
  return (

   

    <MaterialTable
      title="Customer List"
      columns={state.columns}
      data={state.data}
      options={{
        paging: false
      }}
       editable={{
        // onRowAdd: newData =>
        //   new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve();
        //       const data = [...state.data];
        //       data.push(newData);
        //       setState({ ...state, data });
        //     }, 600);
        //   }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            
            axios.post(`${API}/customer/updateCustomer/`, {
            
            ...newData
            })
            .then(function (response) {
      
              resolve();
              const data = [...state.data];
              data[data.indexOf(oldData)] = newData;
              setState({ ...state, data });
            
           
            })
            .catch(function (error) {
             // message = error;
             console.log(error);
            });
          }),
        onRowDelete: oldData =>

          new Promise(resolve => {

            axios.post(`${API}/customer/deleteCustomer/`, {

              cust_id: oldData.cust_id
            })
            .then(function (response) {
            // console.log(response);
             resolve();
             const data = [...state.data];
             data.splice(data.indexOf(oldData), 1);
             setState({ ...state, data });


            })
            .catch(function (error) {
             // message = error;
             console.log(error);
            });

       
          }),
    
  
  }}
    />

 
  );
}