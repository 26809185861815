import React,{ useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import  moment from 'moment';
import API from '../../config';
const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      width: '80%',
      maxHeight: 435,
    },
  }));
export default function Payments(props) {
    
    const { bill_id } = props;
   
    const [state, setState] = React.useState({
      columns: [
      
        
       
      { title: 'Type', field: 'p_transaction_type' ,lookup: { 0: "Cheque", 1: "Cash" }},
      { title: 'Description', field: 'p_description' },
        { title: 'Amount', field: 'p_amount' },
        { title: 'Date',  field: 'p_date', type: 'date', 
        render: rowData => <span>{moment(rowData.p_date).format('DD-MM-YYYY')}</span>
     
     },
        
       
      
  
      ],
      data: [ ],
      loading:true
    });
    useEffect( () => {
      async function fetchList(){
                  
       
          const result = await axios.post(
            `${API}/invoice/getPayments`,{
                 p_bill_id:bill_id
             }
            );
         
           setState({ ...state, "data": result.data,"loading":false });
         
         }
      
         fetchList()
      
      },[]);


if(state.loading){

    return (<b>Loading...</b>)
}

else {

    return (
  <div>

  
      <MaterialTable
        title="Payments"
        columns={state.columns}
        data={state.data}
        options={{
            paging: false
          }}
           editable={{

               onRowAdd: newData =>
               
               new Promise(resolve => {
                newData.p_bill_id =bill_id;
                axios.post(`${API}/invoice/insertPayment/`, {
                
                ...newData
                })
                .then(function (response) {
          
                  
                  const data = [...state.data];
                  data.push(newData);
                  setState({ ...state, data });
                  resolve();
                  props.update();
               
                })
                .catch(function (error) {
                 // message = error;
                 console.log(error);
                });
              }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            newData.p_date = moment(newData.p_date).format('YYYY-MM-DD HH:mm:ss')
            axios.post(`${API}/invoice/updatePayment/`, {
            
            ...newData
            })
            .then(function (response) {
      
              
              const data = [...state.data];
              data[data.indexOf(oldData)] = newData;
              setState({ ...state, data });
              resolve();
              props.update();
           
            })
            .catch(function (error) {
             // message = error;
             console.log(error);
            });
          }),
        onRowDelete: oldData =>

          new Promise(resolve => {
//######################## Don't use this becase it will delete from chbill table !!!!!!!!!!!!!!!!!!!!
            // axios.post('https://admin.nafeesenter.com/server/invoice/deletePayment/', {

            //   p_id:oldData.p_id
            // })
            // .then(function (response) {
            // // console.log(response);
             
            //  const data = [...state.data];
            //  data.splice(data.indexOf(oldData), 1);
            //  setState({ ...state, data });
            //  resolve();
            //  props.update();

            // })
            // .catch(function (error) {
            //  // message = error;
            //  console.log(error);
            // });

       
          }),
        }}

      //   editable={{
      //     onRowAdd: newData =>
      //       new Promise(resolve => {
      //         setTimeout(() => {
      //           resolve();
      //           const data = [...state.data];
      //           data.push(newData);
      //           setState({ ...state, data });
      //         }, 600);
      //       }),
      //     onRowUpdate: (newData, oldData) =>
      //       new Promise(resolve => {
      //         setTimeout(() => {
      //           resolve();
      //           const data = [...state.data];
      //           data[data.indexOf(oldData)] = newData;
      //           setState({ ...state, data });
      //         }, 600);
      //       }),
      //     onRowDelete: oldData =>
      //       new Promise(resolve => {
      //         setTimeout(() => {
      //           resolve();
      //           const data = [...state.data];
      //           data.splice(data.indexOf(oldData), 1);
      //           setState({ ...state, data });
      //         }, 600);
      //       }),
      //   }}
      />
      </div>
    );

}
  }