import React,{ useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import CustomerList from './list'
import { useTheme  } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import EntryForm from './entryform';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
 
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function Main() {
  const [value, setValue] = React.useState(0);
  const [values, setValues] = useState({
});
 
  const theme = useTheme();

  useEffect( () => {
 
    },[values.cust_name]);

  function handleChangeTab(event, newValue) {
    setValue(newValue);
  }

  function handleChangeTabIndex(index) {
   // console.log(index)
    setValue(index);
  }
  function updateList(data){
//     console.log(data.cust_name)
     setValues(data);
//    setValues({ ...values, "billMsg": "Saving..."});

setValue(0);
  }
  return (
   
    <React.Fragment>


          <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary">
          <Tab label="List" {...a11yProps(1)} />
          <Tab label="Add New" {...a11yProps(0)} />
          </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeTabIndex}>
       
        <TabPanel value={value} index={0} dir={theme.direction}>
        <CustomerList data={values}/>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
        <EntryForm  updateList={updateList} data={value}/>
        </TabPanel>
      
        </SwipeableViews>

      






          
    
      
   
    </React.Fragment>
  );
}