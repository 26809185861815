import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Form from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import formatDate from '../util/dateformat';
//import moment from "moment";
import axios from "axios";

import { create, all } from "mathjs";
import API from '../../config';


var moment = require('moment-timezone');
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const today = moment().tz("Asia/Karachi").format("YYYY-MM-DD");

export default function ChallanEntryForm({ challanID,onSubmitChallan,refreshEntryForm,customers,hscodes}) {

 const intialState = {
  custs: customers,
  hscodes: hscodes,
  challanNumber: "Loading...",
  challanDate1: today,
  challanDate2: today,
  challanItemCode: "",
  challanVendorCode: "",
  challanOrderNumber: "",
  challanMessrs: "",
  challanQty: "",
  challanParticullars: "",
  challanRate: "",
  challanAmount: 0,
  challanGST: 18,
  challanGSTAmount: 0,
  challanSED: 0,
  challanSEDAmount: 0,
  challanAGST: 0,
  challanAGSTAmount: 0,
  challanTotal: 0,
  challanMsg: "",
  challan_to_emails: "nafeesenter@gmail.com",
  challan_send_email: false,
  challan_cust_id: -1,
  challan_id: challanID === undefined?0:challanID,
  challan_hscode: -1,
  InvBuyerName: "",
  InvBuyerAddr: "",
  InvBuyerPhone: "",
  InvBuyerSTRN: "",
  InvBuyerNTN: "",
  InvDesc: "",
  InvQty: 0,
  InvUnitPrice: 0,
  InvVEST: 0,
  InvROST: 0,
  InvAOST: 0,
  InvSEDRate: 0,
  InvSEDAmt: 0,
  InvVIST: 0,
  InvTotal: 0,
  InvQtyKG: "Qty",
  
  challan_no_of_container: 0,
  challan_qty_per_container: 0,
  challan_total_qty_container: 0,
  challan_no_of_container2: 0,
  challan_qty_per_container2: 0,
  challan_total_qty_container2: 0,
  challan_stock: 0,
  challan_paper: 0,
  challan_lot_number: "",
  refreshEntryForm:refreshEntryForm,
  prevChallanDate:""
};

  const config = {};
  const math = create(all, config);
  const classes = useStyles();
  // const [custs, setCusts] = useState([]);

  const [values, setValues] = useState(intialState);
  const resetForm =()=>{
    let custsTemp = values.custs;
    let challanNumber = parseInt(values.challanNumber)+1;
    let hscodeTemp = values.hscodes;
    console.log(hscodeTemp)
setValues({...intialState,custs:custsTemp,hscodes:hscodeTemp,challanNumber})
  }
  const handleSubmit =  (event) => {
    event.preventDefault();



    values.challan_to_emails = values.challan_send_email
      ? values.challan_to_emails
      : "";
      // if ( new Date( values.challanDate1) < new Date(values.prevChallanDate)) {
      //   alert("Date must be equal or greater than previous challan date!");
      //   event.preventDefault();
      //   return false;
      // }
      
    if (values.InvBuyerName == "") {
      alert("Please Select Client!");
      event.preventDefault();
      return false;
    }
    if (values.challan_hscode == "-1") {
      alert("Please Select HSCode!");
      event.preventDefault();
      return false;
    }
    setValues({
      ...values,
      challanMsg: values.challan_id != 0 ? "Updating..." : "Generating...", prevChallanDate:values.challanDate1
    });
    axios
      .post(
        `${API}/challan/challangenerate`,
       
        {
          ...values,
        }
      )
      .then(function (response) {
        if (response.data != "Internal Error!") {
          setValues({ ...values, challanMsg: "Done!" });
          window.open(response.data, '_blank').focus();
          resetForm()
         // event.target.resetForm()
          onSubmitChallan();
         
         
          //alert("Challan created successfully!");
        //  window.location.reload(false);
        } else {
          alert("Some thing is wrong! Please try again");
        }

      })
      .catch(function (error) {
try {
        if(error.response.data.sqlMessage !== undefined ){
  
          setValues({ ...values, challanMsg: error.response.data.sqlMessage });
        }
        else{
          setValues({ ...values, challanMsg: "Server Error!" });
        }
      //  setValues({ ...values, challanMsg: error.response.data });
        //  alert(error);
        //  window.location.reload();

        // console.log(error);
      }
      catch(exx){
        window.location.reload();
      }
      });
  };
  const setCustomerValues = (cust_id) => {
    const cust = values.custs.filter((c) => c.cust_id == cust_id)[0];
    
    setValues({
      ...values,
      challanItemCode:
        values.challan_id != 0 ? values.challanItemCode : cust.cust_itemcode,
      challanVendorCode: cust.cust_vendorcode,
      InvBuyerName: cust.cust_name,
      InvBuyerAddr: cust.cust_addr,
      InvBuyerPhone: cust.cust_phone,
      InvBuyerSTRN: cust.cust_strn,
      InvBuyerNTN: cust.cust_ntn,
      challan_cust_id: cust.cust_id,
    });
  };
  const handleSelect = (event) => {
    //console.log(custs[event.target.value])
    if (event.target.value !== -1) {
      setCustomerValues(event.target.value);
    }
  };
  const handleSelectHSCode = (event) => {
    const hscode = values.hscodes.filter(
      (c) => c.value == event.target.value
    )[0];
    if (event.target.value !== -1) {
      setValues({
        ...values,
        challanParticullars: `${hscode.text}`,
        challan_hscode: hscode.value,
      });
    }
  };
  const handleChangeChk = (name) => (event) => {
    // values[name]=event.target.value;
    // setState({ ...state, [name]: event.target.checked });
    setValues({ ...values, [name]: event.target.checked });
    //console.log(event.target.checked)
  };
  const calcQtyTotal =()=>{
    let tot = values.challan_total_qty_container+values.challan_total_qty_container2;
  setValues(
    {...values,
      challanQty:tot
    
    })  
  
  }
const calcTotal =()=>{
  let tot = values.challan_no_of_container*values.challan_qty_per_container;
setValues(
  {...values,challan_total_qty_container:tot
  
  })  

}
const calcTotal2 =()=>{
  let tot = values.challan_no_of_container2*values.challan_qty_per_container2;
setValues(
  {...values,challan_total_qty_container2:tot
  
  })  

}
const calcTotal3 =()=>{
  let tot = values.challan_no_of_container3*values.challan_qty_per_container3;
setValues(
  {...values,challan_total_qty_container3:tot
  
  })  

}
  const handleChange = (name) => (event) => {
    console.log(name);
    try {
      values[name] = event.target.value;
      switch (name) {
        case "challanGST":
        case "challanSED":
        case "challanQty":
        case "challanAGST":
        case "challanRate": {
          values["challanAmount"] = math.evaluate(
            values["challanQty"] * values["challanRate"]
          );
          values["challanGSTAmount"] = math.format(
            (values["challanGST"] / 100) * values["challanAmount"],
            { notation: "fixed", precision: 2 }
          );
          values["challanSEDAmount"] = math.format(
            (values["challanSED"] / 100) * values["challanAmount"],
            { notation: "fixed", precision: 2 }
          );
          values["challanAGSTAmount"] = math.format(
            (values["challanAGST"] / 100) * values["challanAmount"],
            { notation: "fixed", precision: 2 }
          );
          values["challanTotal"] = math.round(
            math.sum(
              values["challanAmount"],
              values["challanGSTAmount"],
              values["challanSEDAmount"],
              values["challanAGSTAmount"]
            )
          );

          break;
        }

        default: {
        }
      }

      setValues({ ...values, [name]: event.target.value });
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    console.log("useEffect");
  //  var c = [];
   // var h = [];
  //  fetchHSCodes();
          if (values.challan_id != 0) {
        fetchchallanByID();
      } else {
        fetchNumber();
      }
    // async function fetchCustomer() {
    //   const customers = await axios.post(
    //     `${API}/customer/getCustomers`,
    //     { cust_type: "output" }
    //   );
    //   c = customers.data;
    //   //setValues({...values,custs:customers.data})
    //   fetchHSCodes();
    //   if (values.challan_id != 0) {
    //     fetchchallanByID();
    //   } else {
    //     fetchNumber();
    //   }
    // }

  
    async function fetchNumber() {
      const result = await axios(
        `${API}/challan/challanNewNumber`
      );

      setValues({
        ...values,
       challanNumber: result.data.challan_number+1,
       prevChallanDate:result.data.challan_date1
     //   custs: c,
     //   hscodes: h,
      });
    }
    // async function fetchHSCodes() {
    //   const hscode = await axios(
    //     `${API}/challan/getHSCodes`
    //   );
    //   h = hscode.data;
    // }
   // fetchCustomer();

    async function fetchchallanByID() {
      console.log("fetchchallanByID");
      const result = await axios.post(
        `${API}/challan/getchallanByID`,
        {
          challan_id: values.challan_id,
        }
      );

      //   setValues({...values,custs:c,challanQty: result.data.challan_qty,challan_cust_id:result.data.challan_cust_id})
      setValues({
        ...values,
        challan_id: result.data.challan_id,
        challanQty: result.data.challan_qty,
        InvQtyKG: result.data.challan_qty_kg,
        challanNumber: result.data.challan_number,
        challanDate1: result.data.challan_date1.split("T")[0],
        challanRate: result.data.challan_price,
        challanOrderNumber: result.data.challan_order_mumber,
        challanTotal: result.data.challan_total,
        challanp: result.data.challan_price,
        InvBuyerName: result.data.challan_client_name,
        challan_cust_id: result.data.challan_cust_id,
        challanParticullars: result.data.challan_particullars,
        // challanOrderNumber:result.data.challan_order_mumber,
        challanItemCode: result.data.challan_item_code,
        challanAmount: result.data.challan_amount,
        challanGST: result.data.challan_gst_rate,
        challanGSTAmount: result.data.challan_gst_amount,
        challanSED: result.data.challan_sed_rate,
        challanSEDAmount: result.data.challan_sed_amount,
        challanAGST: result.data.challan_agst_rate,
        challanAGSTAmount: result.data.challan_agst_amount,
        challanVendorCode: result.data.challan_vendor_code,
       hscodes: hscodes,
        custs: customers,
        challan_hscode: result.data.challan_hscode,
        challan_no_of_container: result.data.challan_no_of_container,
        challan_qty_per_container: result.data.challan_qty_per_container,
        challan_total_qty_container: result.data.challan_total_qty_container,
        challan_no_of_container2: result.data.challan_no_of_container2,
        challan_qty_per_container2: result.data.challan_qty_per_container2,
        challan_total_qty_container2: result.data.challan_total_qty_container2,
        challan_stock: result.data.challan_material_consumption,
        challan_paper:result.data.challan_paper_consumption,
        challan_lot_number:result.data.challan_lot_number,
        prevChallanDate:result.data.challan_date1.split("T")[0]
      });
      //console.log(values.challanOrderNumber)
      //console.log(moment(result.data.challan_date1.split('T')[0]).format("mm/dd/yyyy"))
    }
  }, []);
  useEffect(() => {
    if (values.challan_cust_id != -1) {
      setCustomerValues(values.challan_cust_id);
      // handleTotals('challanRate');
    }
  }, []);

  useEffect(()=>{

   // console.log('refreshEntryForm', refreshEntryForm)
  //  fetchNumber();
  if(refreshEntryForm){
    axios(
      `${API}/challan/challanNewNumber`
    ).then((result)=>{
      setValues({
        ...values,
        challanNumber: result.data.challan_number + 1,
     prevChallanDate:result.data.challan_date1
      });
    });
  }
   

  


  },[refreshEntryForm])
  return (
   
    <React.Fragment>
      



      {/* 
        <Typography variant="h6" gutterBottom>
       challan
      </Typography>
     */}


      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <NativeSelect
              className="width50"
              value={values.challan_cust_id}
              required
              onChange={handleSelect}
            >
              <option>Please Select Client</option>
              {values.custs.map((d, idx) => {
                return (
                  <option value={d.cust_id} key={idx}>
                    {d.cust_name}
                  </option>
                );
              })}
            </NativeSelect>

            {/* <TextField
            required
           
            label="Messrs"
            fullWidth
            autoComplete="challaning Messrs"
            value={values.challanMessrs}
            onChange={handleChange('challanMessrs')} 
          /> */}
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              id="challanNumber"
              name="challanNumber"
              label="challan Number"
              fullWidth
              value={values.challanNumber}
              onChange={handleChange("challanNumber")}
            />
          </Grid>
       
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Date1"
              fullWidth
              type="date"
              value={values.challanDate1}
              onChange={handleChange("challanDate1")}
            />
            
          </Grid>
          <Grid item xs={12} sm={4}>
            
            <TextField
              required
              label="Order Date"
              fullWidth
              type="date"
              value={values.challanDate2}
              onChange={handleChange("challanDate2")}
            />
          </Grid>

          {/* <Grid item xs={12} sm={4}>
          <TextField 
         
          label="Date2" 
          fullWidth  
          type="date"
          value={values.challanDate2}
          onChange={handleChange('challanDate2')}
          />
         
        </Grid> */}
   <Grid item xs={12} sm={4}>
            <TextField
              required
              id="challanLotNumber"
              name="challanLotNumber"
              label="Lot Number"
              fullWidth
              value={values.challan_lot_number}
              onChange={handleChange("challan_lot_number")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Order Number"
              fullWidth
              value={values.challanOrderNumber}
              onChange={handleChange("challanOrderNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Item Code"
              fullWidth
              autoComplete="challaning challanItemCode"
              value={values.challanItemCode}
              onChange={handleChange("challanItemCode")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="No. of Container"
              fullWidth
              value={values.challan_no_of_container}
              onChange={handleChange("challan_no_of_container")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Qty Per Container"
              fullWidth
              value={values.challan_qty_per_container}
              onChange={handleChange("challan_qty_per_container")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Total Qty"
              fullWidth
              value={values.challan_total_qty_container}
              onChange={handleChange("challan_total_qty_container")}
            />
            <b onClick={calcTotal} style={{cursor:'pointer'}} >?</b>
            </Grid>


            <Grid item xs={12} sm={4}>
            <TextField
              required
              label="No. of Container"
              fullWidth
              value={values.challan_no_of_container2}
              onChange={handleChange("challan_no_of_container2")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Qty Per Container"
              fullWidth
              value={values.challan_qty_per_container2}
              onChange={handleChange("challan_qty_per_container2")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="Total Qty"
              fullWidth
              value={values.challan_total_qty_container2}
              onChange={handleChange("challan_total_qty_container2")}
            />
            <b onClick={calcTotal2} style={{cursor:'pointer'}} >?</b>
            </Grid>
            <Grid item xs={12} sm={3}>
            <TextField
              required
              label="Material Consumption"
              fullWidth
              value={values.challan_stock}
              onChange={handleChange("challan_stock")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              label="Paper Consumption"
              fullWidth
              value={values.challan_paper}
              onChange={handleChange("challan_paper")}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <NativeSelect
              className="width50"
              value={values.challan_hscode}
              required
              onChange={handleSelectHSCode}
            >
              <option value="-1">Please Select HSCode</option>
              {values.hscodes.map((d, idx) => {
                return (
                  <option value={d.value} key={idx}>
                    {d.text}
                  </option>
                );
              })}
            </NativeSelect>
            <TextField
              required
              label="Particullars"
              fullWidth
              value={values.challanParticullars}
              onChange={handleChange("challanParticullars")}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <TextField
              required
              // label="Qty or KG"

              value={values.InvQtyKG}
              onChange={handleChange("InvQtyKG")}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              // label="Qty/KG"
              fullWidth
              value={values.challanQty}
              onChange={handleChange("challanQty")}
            /><b onClick={calcQtyTotal} style={{cursor:'pointer'}} >?</b>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="Rate"
              fullWidth
              value={values.challanRate}
              onChange={handleChange("challanRate")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.challanAmount}
              onChange={handleChange("challanAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="GST %"
              fullWidth
              value={values.challanGST}
              inputProps={{
                style: { textAlign: "right" },
              }}
              onChange={handleChange("challanGST")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="GST Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.challanGSTAmount}
              onChange={handleChange("challanGSTAmount")}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="SED %"
              fullWidth
              value={values.challanSED}
              onChange={handleChange("challanSED")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="SED Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.challanSEDAmount}
              onChange={handleChange("challanSEDAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" },
              }}
              label="AGST %"
              fullWidth
              value={values.challanAGST}
              onChange={handleChange("challanAGST")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="AGST Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" },
              }}
              value={values.challanAGSTAmount}
              onChange={handleChange("challanAGSTAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              inputProps={{
                style: { textAlign: "right", fontWeight: "bold" },
              }}
              label="Total"
              fullWidth
              disabled
              value={values.challanTotal}
              onChange={handleChange("challanTotal")}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
            >
              {values.challan_id != 0 ? "Update" : "Generate"}
            </Button>
            &nbsp;&nbsp;
            <FormLabel>{values.challanMsg}</FormLabel>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="challan_send_email"
                  checked={values.challan_send_email}
                />
              }
              label="Send Email?"
              onChange={handleChangeChk("challan_send_email")}
            />
            <TextField
              label="Email To Address(es):"
              fullWidth
              value={values.challan_send_email ? values.challan_to_emails : ""}
              onChange={handleChange("challan_to_emails")}
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
