import React,{useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EntryForm from './EntryForm';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BillList from './billList'
import MonthlySummary from '../reports/monthlysummary'
import YearlySummary from '../reports/yearlysummary'
import Payments from '../reports/payments'
import InputInv from '../bill/inputinvList'
import Cheque from '../cheque/cheque_main'
import axios from "axios";
import API from '../../config';
const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative',
    },
    link: {
      margin: theme.spacing(1, 1.5),
    
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    //   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //     width: 600,
    //     marginLeft: 'auto',
    //     marginRight: 'auto',
    //   },
    },
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    boldInput:{
      textAlign:'right',
     fontSize:18 
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
export default function Checkout() {
 
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const [customers, setCustomers] = useState([])
useEffect(() => {
  console.log('Main Bill & Sales Tax')
  fetchCustomer();
  async function fetchCustomer() {
   
    const customers = await axios.post(
      `${API}/customer/getCustomers`,{cust_type:'output'}
    );

    setCustomers(customers.data);
  
  }

}, [])
  function handleChangeTab(event, newValue) {
    setValue(newValue);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
  }
  return (
    
     
      <React.Fragment>
    
{customers.length==0?<h1>Loading...</h1>: <main className={classes.layout}>
  
          <AppBar position="static" color="default">
      
      
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary">
          <Tab label="Add New" {...a11yProps(1)} />
          <Tab label="List" {...a11yProps(0)} />
          <Tab label="Add Cheque" {...a11yProps(2)} />
          {/* <Tab label="Payment Register" {...a11yProps(3)} /> */}
          <Tab label="Payments" {...a11yProps(3)} />
          <Tab label="Input Invoice" {...a11yProps(4)} />
          <Tab label="Monthly Summary" {...a11yProps(5)} />
          <Tab label="Yearly Summary" {...a11yProps(6)} />
          </Tabs>
   
   
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeTabIndex}>
       
        <TabPanel value={value} index={0} dir={theme.direction}>
        <EntryForm  customers={customers}></EntryForm>
        
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <BillList customers={customers} ></BillList>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
         <Cheque  customers={customers}></Cheque>
        </TabPanel>
        {/* <TabPanel value={value} index={3} dir={theme.direction}>
        <PaymentRegister></PaymentRegister>
        </TabPanel> */}
        <TabPanel value={value} index={3} dir={theme.direction}>
        <Payments  customers={customers}></Payments>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
        <InputInv></InputInv>
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
        <MonthlySummary></MonthlySummary>
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
        <YearlySummary></YearlySummary>
        </TabPanel>
        </SwipeableViews>
   
      
      </main>
   }
     
    </React.Fragment>
    

  );
}