import React, { useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
// import moment from "moment";
import numeral from "numeral";
import Pdf from "@material-ui/icons/PictureAsPdf";
import Cancel from "@material-ui/icons/Cancel";
import PaymentList from "./paymentList";
import _ from "underscore";
import EntryForm from "./entryform";
import { Popup } from "../hooks/popup-hook.js";
import API from '../../config';
var moment = require('moment-timezone');
function makeLink(lnk) {
  return "/server/PDF/Challan/" + lnk + ".pdf";
}
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: "80%",
    maxHeight: 435
  }
}));
function ConfirmationDialogRaw(props) {

 
  const { onClose, onUpdate, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);




  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  function handleEntering() {
    // if (radioGroupRef.current != null) {
    //   radioGroupRef.current.focus();
    // }
  }

  function handleCancel() {
    onClose();
  }
  function handleUpdate() {
    onUpdate();
  }
  function handleOk() {
    onClose(value);
  }

  if (open) {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        onEntering={handleEntering}
        aria-labelledby="confirmation-dialog-title"
        open={open}
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">
          Challan No. {value.challan_invoice_num}
        </DialogTitle>
        <DialogContent dividers>
          <PaymentList
            challan_id={value.challan_id}
            update={handleUpdate}
          ></PaymentList>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <b></b>;
  }
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.object
};
export default function ChallanList({load,onDeleteChallan,customers,hscodes}) {
  console.log(load)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(-1);
  const [value, setValue] = React.useState({});

  async function fetchList() {
    const result = await axios(
      `${API}/challan/getchallans`
    );

    setState({ ...state, ["data"]: result.data });
  }

  useEffect(() => {
    fetchList();
  }, [load]);
  function handleClose(newValue) {
    setOpen(false);
    setShow(false);
    setOpenEdit(-1);
    // if (newValue) {
    //   setValue(newValue);
    // }
  }

  function handleCancel(newValue) {
    setOpen(false);
  }
  function handleUpdate() {
    setOpen(false);
    // fetchList();
  }
  function handleEdit(d) {
    setOpenEdit(d.challan_id);
  }

  function handleCancel(oldData) {
    if (
      oldData.challan_status == 1
        ? window.confirm("Do you want to cancel this challan?")
        : window.confirm("Do you want to restore this challan?")
    ) {
      new Promise(resolve => {
        axios
          .post(`${API}/challan/cancelChallan/`, {
            challan_id: oldData.challan_id,
            challan_status: oldData.challan_status == 1 ? 0 : 1
          })
          .then(function(response) {
            fetchList();
          })
          .catch(function(error) {
            // message = error;
            console.log(error);
          });
      });
    }

    // if (newValue) {
    //   setValue(newValue);
    // }
  }
  const [show, setShow] = React.useState(false);
  const [challans, setChallans] = React.useState([]);
  const [state, setState] = React.useState({
    columns: [
      {
        title: "",
        // field: "challan_pdf_path",
        // cellStyle: { padding: "8px", width: "50px" },
        // headerStyle: { padding: "0px", width: "50px" },
        render: rowData => (
          <span>
            <a
              rel="noopener noreferrer"
              href={makeLink(rowData.challan_pdf_path)}
              target="_blank"
            >
              <Pdf></Pdf>
            </a>
            &nbsp;|&nbsp;
            <a
              rel="noopener noreferrer"
              onClick={() => {
                handleCancel(rowData);
              }}
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <Cancel></Cancel>
            </a>
            &nbsp;|&nbsp;
            <a
              rel="noopener noreferrer"
              onClick={() => {
                handleEdit(rowData);
              }}
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              Edit
            </a>
          </span>
        )
      },

      {
        title: "Challan# : Invoice#",
        field: "challan_number",
        cellStyle: { padding: "0px", width: "20px",fontWeight:'bold' },
        headerStyle: { padding: "0px", width: "20px" },
        render: rowData => (
          <span>
            <b style={{color:'blue'}}>{rowData.challan_number}</b>&nbsp;:&nbsp;<b style={{color:'maroon'}}>{rowData.challan_invoice_num}</b>
          </span>
        )
      },
      
      // {
      //   title: "Invoice #",
      //   cellStyle: { padding: "0px", width: "20px",fontWeight:'bold' },
      //   render: rowData => (
      //     <b>
      //       {rowData.challan_invoice_num}
      //     </b>
      //   )
      // },
      { title: "Messrs", field: "challan_client_name" },
      {
        title: "Date",
        field: "challan_date1",
        type: "date",
        render: rowData => (
           <span>{moment(rowData.challan_date1).tz('Asia/Karachi').format("DD-MM-YYYY")}</span>
      
        )

      },
      {
        title: "Total",
        field: "challan_total",
        type: "numeric",
        render: rowData => (
          <span>{numeral(rowData.challan_total).format("0,0")}</span>
        )
      }
    ],
    data: []
  });

  return (
    <div>
   
      {openEdit != -1 && (
        <Popup
          handleClose={handleClose}
          Comp={() => EntryForm({ challanID: openEdit,customers,hscodes })}
        ></Popup>
      )}

      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper
        }}
        id="ringtone-menu"
        keepMounted
        open={open}
        onClose={handleClose}
        onUpdate={handleUpdate}
        value={value}
      />
      <MaterialTable
        title="Generated Challans"
        columns={state.columns}
        data={state.data.sort(function(a, b){return b.challan_number-a.challan_number})}
        options={{
          paginationPosition:'both',
          initialPage:1,
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          toolbar: true,
          paging: true,
          padding: "dense",
          rowStyle: rowData => {
            if (rowData.challan_status === 0) {
              return {
                backgroundColor: "yellow",
                textDecoration: "line-through"
              };
            } else if (rowData.received > 0) {
              return { backgroundColor: "#a5d6a7" };
            }
            return {
              backgroundColor: "#e8f5e9"
            };
          }
        }}

        editable={{
          //     onRowAdd: newData =>
          //       new Promise(resolve => {
          //         setTimeout(() => {
          //           resolve();
          //           const data = [...state.data];
          //           data.push(newData);
          //           setState({ ...state, data });
          //         }, 600);
          //       }),
          //     onRowUpdate: (newData, oldData) =>
          //       new Promise(resolve => {
          //         setTimeout(() => {
          //           resolve();
          //           const data = [...state.data];
          //           data[data.indexOf(oldData)] = newData;
          //           setState({ ...state, data });
          //         }, 600);
          //       }),
          onRowDelete: oldData => {

if(window.confirm('Do you want to delete challan number ' + oldData.challan_number+'?')){
  if(oldData.challan_invoice_num==null ||oldData.challan_invoice_num==0){
  

    return new Promise(resolve => {
        axios
          .post(
            `${API}/challan/deleteChallan/`,
            {
              challan_id: oldData.challan_id
            }
          )
          .then(function(response) {
            // console.log(response);
    
            const data = [...state.data];
            data.splice(data.indexOf(oldData), 1);
            setState({ ...state, data });
            onDeleteChallan();
            resolve();
            // props.update();
          })
          .catch(function(error) {
            // message = error;
            console.log(error);
          });
    
    
    
        })
     
    }
    else {
    
    
            alert(`Please, first delete its invoice (${oldData.challan_invoice_num}).`)
      return Promise.resolve();
    
    
    
        
    
    
    
       
      
    }
    
}





          }
           
            }}
      />
    </div>
  );
}
