import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Datetime from "react-datetime";
import Button from "@material-ui/core/Button";
import moment from "moment";
import API from '../../config';
export default function MonthlySummary() {
 
   
    const [values, setValues] = useState({
   
      billDate1: moment().subtract(1, 'year').format("YYYY-07-01"),
      billDate2: moment().format("YYYY-06-30"),  
     
  });
 
  useEffect(() => {
  
  }, []);



  const handleSubmit = () => {
    setValues({ ...values, loading: true });

    axios
      .all([
        axios.post(
          `${API}/reports/getYearlySummary`,
          {
            from: values.billDate1,
            to:values.billDate2
          }
        )
      ])
      .then(
        axios.spread(res1 => {
         
          setValues({ ...values, loading: false });
          window.open(res1.data, '_blank');

        })
      )
      .catch(err => {
        alert(err);
        setValues({ ...values, loading: false });
      });
  };
  const handleSubmitAllInv = () => {
    setValues({ ...values, loading: true });

    axios
      .all([
        axios.post(
          `${API}/reports/getYearlyInvoices`,
          {
            from: values.billDate1,
            to:values.billDate2
          }
        )
      ])
      .then(
        axios.spread(res1 => {
         
          setValues({ ...values, loading: false });
          window.open(res1.data, '_blank');

        })
      )
      .catch(err => {
        alert(err);
        setValues({ ...values, loading: false });
      });
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div>
     
      <Grid container spacing={2}>
      <Grid item xs={2}>
         From: <TextField
            style={{}}
            required
         
            fullWidth
            type="date"
            value={values.billDate1}
            onChange={handleChange("billDate1")}
          />
        </Grid>
        <Grid item xs={2}>
         To: <TextField
            style={{}}
            required
         
            fullWidth
            type="date"
            value={values.billDate2}
            onChange={handleChange("billDate2")}
          />
        </Grid>


        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}

            // className={classes.button}
          >
            Generate
          </Button>
          &nbsp;&nbsp;
          {values.loading && <b>Loading...</b>}
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmitAllInv}

            // className={classes.button}
          >
            All Invoices
          </Button>
          &nbsp;&nbsp;
          
        </Grid>
      </Grid>
      <hr></hr>
    </div>
  );
}
