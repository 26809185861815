import axios from "axios";
import API from "./config";

export default class OrderAPI {
  constructor() {}

  fetchListById(order_customer_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/getOrdersByCust`, {
          order_customer_id: order_customer_id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  fetchNonTaxListById(ont_cust_id) {
    console.log('fetchNonTaxListById')
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/getNonTaxOrdersByCust`, {
          ont_cust_id: ont_cust_id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  



  getNonTaxInvoiceByCustID(ont_cust_id,ntb_month,prevMonth) {
    console.log('fetchNonTaxInvoiceByCustId')
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/getNonTaxInvoiceByCustID`, {
          ntb_cust_id: ont_cust_id,
          ntb_month,
          prevMonth
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }

  generateNonTaxInvoice(data) {
    console.log(data)
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/bill/generateNonTaxInvoice`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  getorderByID(order_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/getorderByID`, {
          order_id: order_id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  getNonTaxOrderByID(ont_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/getNonTaxOrderByID`, {
          ont_id: ont_id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  deleteOrderById(order_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/deleteOrderById`, {
          order_id: order_id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }

  deleteNonTaxOrderById(ont_id) {
    console.log('deleteNonTaxOrderById')
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/deleteNonTaxOrderById`, {
          ont_id
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }

  
  AddOrder(order) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/AddOrder`, {
          ...order
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  AddNonTaxOrder(order) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/AddNonTaxOrder`, {
          ...order
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  UpdateOrder(order) {
console.log(order)
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/order/UpdateOrder`, {
          ...order
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  UpdateNonTaxOrder(order) {
    console.log(order)
        return new Promise((resolve, reject) => {
          axios
            .post(`${API}/order/updateNonTaxOrder`, {
              ...order
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
    
        //     await
      }
}

export class ChallanAPI{
  constructor() {}
  AddChallan(order) {


    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/challan/AddChallan`, {
          ...order
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  getChallanByID(challan_id) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/challan/getChallanByID`, {
          challan_id: challan_id
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  deleteChallanByID(challan_id) {

    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/challan/deleteChallan`, {
          challan_id: challan_id
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  challanNewNumber() {

    return new Promise((resolve, reject) => {
      axios
        (`${API}/challan/challanNewNumber`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
}

export class CustomersAPI {

  constructor(){}

  getCustomers(){
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/customer/getCustomers`,{cust_type:"output"})
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }
  getCustomersNonTax(){
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/customer/getCustomersNonTax`,{cust_type:"output"})
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }
  getCustomerItems(ont_cust_id){
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/customer/getCustomerItems`,{ont_cust_id})
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }
}
export class BillAPI{

  constructor(){}
  generateNonTaxLedger(ont_cust_id,fromDate,toDate,cust_name) {
    
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/invoice/generateNonTaxLedger`, {
          ntb_cust_id: ont_cust_id,
          fromDate,
          toDate,
          cust_name

        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  getNonTaxPaymentsByCustID(ont_cust_id) {
    
    return new Promise((resolve, reject) => {
      axios
        .post(`${API}/invoice/getNonTaxPaymentsByCustID`, {
          ntb_cust_id: ont_cust_id
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    //     await
  }
  billNewNumber(){
    return new Promise((resolve, reject) => {
      axios
        (`${API}/invoice/billNewNumber`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    
  }
  billGenerate(values){
    return new Promise((resolve, reject) => {
      axios
      .post
        (`${API}/invoice/billgenerate`,values)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });


    
}

getNonTaxBills(values){
  return new Promise((resolve, reject) => {
    axios
    .post
      (`${API}/invoice/getNonTaxBills`,values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });


  
}
deleteBillByID(bill_id){

  return new Promise((resolve, reject) => {
    axios
    .post(
      `${API}/invoice/deleteBill/`,
      {
        bill_id: bill_id
      }
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
 
}


deleteInvoiceNonTaxByID(ntb_id,action){

  return new Promise((resolve, reject) => {
    axios
    .post(
      `${API}/invoice/deleteInvoiceNonTaxByID/`,
      {
        ntb_id: ntb_id,
        action
      }
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
 
}

deleteNonTaxPaymentByID(ntp_id){

  return new Promise((resolve, reject) => {
    axios
    .post(
      `${API}/invoice/deleteNonTaxPaymentByID/`,
      {
        ntp_id: ntp_id
      }
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
 
}



cancelBillByID(bill_id,bill_status){

  return new Promise((resolve, reject) => {
    axios
    .post(
      `${API}/invoice/cancelBill/`,
      {
        bill_id: bill_id,
        bill_status:bill_status
      }
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
 
}


generateNonTaxInvoice(values){
  return new Promise((resolve, reject) => {
    axios
    .post
      (`${API}/invoice/generateNonTaxInvoice`,values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });


  
}


submitNonTaxPayment(values){
  return new Promise((resolve, reject) => {
    axios
    .post
      (`${API}/invoice/submitNonTaxPayment`,values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });


  
}




}

export class HSCodesAPI {

  constructor(){}

  getHSCodes(){
    return new Promise((resolve, reject) => {
      axios
        .get(`${API}/challan/getHSCodes`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }
}