import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Datetime from "react-datetime";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Popup } from "./emailpopup";
import { Sum } from "../util/mathUtil";
import moment from "moment";
import numeral from "numeral";
import API from '../../config';
import MonthYearPicker from 'react-month-year-picker';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  colorH: {
    backgroundColor: "#795548",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  colorH1: {
    backgroundColor: "#d26936",
    "& th": {
      color: "#ffffff",
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  color1: {
    backgroundColor: "#d7ccc8"
  },
  color2: {
    backgroundColor: "#efebe9"
  },
  color3: {
    backgroundColor: "white"
  },
  color4: {
    backgroundColor: "#e5e872"
  },
  Hyperlink: {
    textDecoration: "underline",
    color: "blue",
    fontSize: "10px",
    cursor: "pointer"
  }
});
function makeLink(lnk) {
  return "/server/PDF/" + lnk + ".pdf";
}
export default function MonthlySummary() {
  const [show, setShow] = React.useState(false);
  const [values, setValues] = useState({
   
    month: new Date(),
     
  });
 
  useEffect(() => {
  
  }, []);



  const handleTaxSubmit = () => {
    setValues({ ...values, loading: true });
  
    axios
      .all([
        axios.post(
          `${API}/reports/getMonthlyTax`,
          {
            month: values.month
          }
        )
      ])
      .then(
        axios.spread(res1 => {
         
          setValues({ ...values, loading: false });
          window.open(res1.data, '_blank');

        })
      )
      .catch(err => {
        alert(err);
        setValues({ ...values, loading: false });
      });
  };
  const handleTaxSubmitXLS = () => {
    setValues({ ...values, loading: true });
  
    axios
      .all([
        axios.post(
          `${API}/reports/getMonthlyTaxExcel`,
          {
            month: values.month
          }
        )
      ])
      .then(
        axios.spread(res1 => {
         
          setValues({ ...values, loading: false });
          window.open(res1.data, '_blank');

        })
      )
      .catch(err => {
        alert(err);
        setValues({ ...values, loading: false });
      });
  };
 const handleDate = date => {
     var dt = new Date(date._d)

     setValues({ ...values, month: moment(dt).format("YYYY-MM-DD")});
    // this.setState({
    //   startDate: date
    // });
  };

  return (
    <div>
     
      <Grid container spacing={0}>
        <Grid item xs={2}>
        <Datetime dateFormat="YYYY-MM" onChange={handleDate}  timeFormat={false} />
        </Grid>

        <Grid item xs={5}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleTaxSubmit}

            // className={classes.button}
          >
            TAX Invoices
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleTaxSubmitXLS}

            // className={classes.button}
          >
            TAX Invoices Excel
          </Button>
          &nbsp;&nbsp;
          {values.loading && <b>Loading...</b>}
        </Grid>
      </Grid>
      <hr></hr>
    </div>
  );
}
