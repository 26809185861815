import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NativeSelect from "@material-ui/core/NativeSelect";
import OrderList from "./order_list_non_tax";
import OrderEntryForm from "./entryform_non_tax";
import {NonTaxInvoiceEntryForm} from "../bill/popup_non_tax_invoice";
import ChallanPopup from "../challan/challan_popup";
import BillPopup from "../bill/bill_popup";
import OrderAPI, { ChallanAPI, CustomersAPI, HSCodesAPI,BillAPI } from "../../BL";
var moment = require("moment");
export default function Order({}) {
  const objOrderAPI = new OrderAPI();
  const objChallanAPI = new ChallanAPI();
  const objCustomersAPI = new CustomersAPI();
  const objHSCodesAPI = new HSCodesAPI();
  const objBillAPI = new BillAPI();
  const [loadAgain, setloadAgain] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [hscodes, setHscodes] = useState([]);
  const intialState = {
    //customer_id:-1,// 163, //-1
    order: {
      ont_cust_id: -1,
      ont_order_date: "",
      ont_bag: "",
      ont_order_date2: "",
      ont_cust_name:""
      
    },
    customer: {
      InvBuyerName: "",
      InvBuyerAddr: "",
      InvBuyerPhone: "",
      InvBuyerSTRN: "",
      InvBuyerNTN: "",
    },
    bill_id: 0,
    show: false,
    showChallan: false,
    loadAgain: false,
    showInvoice: false,
    popup:'new'
  };
  const [ledger, setLedger] = useState({fromDate:moment().format('YYYY-MM-01'),toDate:moment().format('YYYY-MM-DD')});
  const [values, setValues] = useState(intialState);
// useEffect(()=>{
//   if(customers.length>0)
//  setCustomerValues(132);
// },[customers])
const handleLedger =(e)=>{

setLedger({...ledger,[e.target.name]:e.target.value})
}
  useEffect(() => {
    fetchCustomer();
   
   
  }, []);
  function fetchCustomer() {
    objCustomersAPI
    .getCustomersNonTax()
      
      .then((customers) => {
        setCustomers(customers);
      })
      
      .catch((err) => alert(err));
  }

  const handleClose = (isLoadAgain) => {
    console.log('handleClose',isLoadAgain)
    setValues({
      ...values,
      show: false,
      order: { ...values.order, ont_id: 0 },
      showChallan: false,
      showInvoice: false,
    });
    if (isLoadAgain) {
      setloadAgain(!loadAgain);
    }
    else if(isLoadAgain==1){

      setloadAgain(true);
    }
  };
  const handleSelect = (event) => {
    //console.log(custs[event.target.value])
    if (event.target.value !== -1) {
      setCustomerValues(event.target.value);
    }
  };
  const setCustomerValues = (cust_id) => {
    if(cust_id==-1){
      setValues({...values,order:{...values.order, ont_cust_id:-1}});
      return false;
    }
    

    const cust = customers.filter((c) => c.cust_id == cust_id)[0];
console.log(cust)
    setValues({
      ...values,
      order: {
        order_item_code:
          values.order.ont_id != 0
            ? values.order.order_item_code
            : cust.cust_itemcode,
        order_vendor_code: cust.cust_vendorcode,
        order_customer_name: cust.cust_name,
        ont_cust_id: cust.cust_id,
        challan_id: 0,
        order_number: 0,
        order_qty_kg:"Pcs"
      },
      customer: {
        InvBuyerName: cust.cust_name,
        InvBuyerAddr: cust.cust_addr,
        InvBuyerPhone: cust.cust_phone,
        InvBuyerSTRN: cust.cust_strn,
        InvBuyerNTN: cust.cust_ntn,
      },
    });
  };
  const handleEdit = (ont_id) => {
    //alert(ont_id)
    
    setValues({ ...values, show: true, order: { ...values.order, ont_id } });
  };
  const handleAddChallan = (order) => {
    //alert(ont_id)

    setValues({ ...values, showChallan: true, order });
  };
  const handleAddInvoice = (order) => {

    setValues({ ...values,bill_id:0 ,showInvoice: true, order });
  };

  const handleUpdateChallan = (challan_id, order_number) => {
    setValues({
      ...values,
      showChallan: true,
      order: { ...values.order, challan_id, order_number },
    });
  };
  const handleUpdateBill = (bill_id, order) => {
    setValues({
      ...values,
      showInvoice: true,
      bill_id: bill_id,
      order: order,
      
    });
  };
  const openPopup = () => {
   
    if (values.order.ont_cust_id <1) {
      alert("Please select customer!");
      return false;
    }
    setValues({
      ...values,
      show: true,
      order: { ...values.order, ont_id: 0 },
    
    });
  };

  const generateNonTaxLedger =()=>{
    if (values.order.ont_cust_id <1) {
      alert("Please select customer!");
      return false;
    }

    const cust = customers.filter((c) => c.cust_id == values.order.ont_cust_id )[0];
    objBillAPI
    .generateNonTaxLedger(values.order.ont_cust_id ,ledger.fromDate,ledger.toDate,cust.cust_name)
      
      .then((data) => {
        window.open("server/PDF/NonTax/LEDGER/"+data+".pdf", '_blank');
       // setCustomers(customers);
      })
      
      .catch((err) => alert(err));
  }
  return (
    <div>
      {customers.length == 0 ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <NativeSelect
                value={values.ont_cust_id}
                required
                onChange={handleSelect}
              >
                <option value="-1" >Please Select Client</option>
                {customers.map((d, idx) => {
                  return (
                    <option value={d.cust_id} key={idx}>
                      {d.cust_name} ({d.cust_id})
                    </option>
                  );
                })}
              </NativeSelect>
            </Grid>
            <Grid item xs={9} style={{backgroundColor:'skyblue', textAlign:'center'}} >
            <input type="date" name="fromDate" onChange={handleLedger} value={ ledger.fromDate}></input>&nbsp;- &nbsp;
            <input type="date" name="toDate" onChange={handleLedger} value={ ledger.toDate}></input>&nbsp;
               <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={()=>generateNonTaxLedger()}
              >
                Ledger
              </Button> &nbsp;&nbsp;
            </Grid>

            <Grid item xs={1} >&nbsp;&nbsp;&nbsp;
               <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={openPopup}
              >
                Add Order
              </Button> 
            </Grid>
          </Grid>

          {values.show && (
            <OrderEntryForm
              handleClose={handleClose}
              order={values.order}
              hscodes={hscodes}
            ></OrderEntryForm>
          )}
          {values.showChallan && (
            <ChallanPopup
              handleClose={handleClose}
              order={values.order}
            ></ChallanPopup>
          )}
          {values.showInvoice && (
            <NonTaxInvoiceEntryForm
              handleClose={handleClose}
              order={values.order}
              customer={values.customer}
            ></NonTaxInvoiceEntryForm>
          )}
          <hr />

          <OrderList
            loadAgain={loadAgain}
            ont_cust_id={values.order.ont_cust_id}
            handleEdit={handleEdit}
            handleAddChallan={handleAddChallan}
            handleUpdateChallan={handleUpdateChallan}
            handleAddInvoice={handleAddInvoice}
            handleUpdateBill={handleUpdateBill}
          ></OrderList>
        </div>
      )}
    </div>
  );
}
