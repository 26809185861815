import React, { useState, useEffect } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import axios from "axios";
import API from '../../config';
const useStyles = makeStyles(theme => ({}));
const today = moment().format("YYYY-MM-DD");
const options = ['Al Baraka Bank Ltd.',
 'Allied Bank Ltd.', 
 'Askari Bank Ltd.', 
'Bank Al Habib Ltd.',
 'Bank Alfalah Ltd.', 
 'BAHL Islamic Bank',
 'BOK',
'Cash', 
'Faysal Bank Ltd.',
'Habib Bank Ltd.',
'Habib Metro Bank', 
'IBFT Faysal Bank Ltd.',
 'IBFT HBL', 
 'JS Bank Ltd.', 
 'MCB', 
 'Meezan Bank Ltd.',
 'Standard Chartered',
'Soneri Bank Ltd.', 
 'UBL'];
export default function BillEntryForm(params) {
  console.log(params.customers)
  const [custs, setCusts] = useState(params.customers);
  const handleSelect = event => {

    if (event.target.value !== -1) {
      const cust = custs[event.target.value];

      setValues({
        ...values,
        ch_cust_id: cust.cust_id
        //      billVendorCode:cust.cust_vendorcode,
        //      InvBuyerName:cust.cust_name,
        //      InvBuyerAddr:cust.cust_addr,
        //      InvBuyerPhone:cust.cust_phone,
        //      InvBuyerSTRN:cust.cust_strn,
        //      InvBuyerNTN:cust.cust_ntn
      });
    }
  };


  const classes = useStyles();

  useEffect(() => {}, [params.data]);

  const initialState = {
    ch_number: "",
    ch_date: today,
    ch_bank: "",
    ch_description: "",
    ch_amount: 0,
    ch_tax: 0,
    ch_status: "",
    ch_cust_id: "-1"
  };
  //  const theme = useTheme();
  //  const [value, setValue] = React.useState(0);
  const [values, setValues] = useState(initialState);

  const handleSubmit = () => {
    if (values.ch_cust_id == "-1") {
      alert("Please select customer!");
      return;
    }
    setValues({ ...values, billMsg: "Saving..." });
    axios
      .post(`${API}/cheque/insertCheque`, {
        ...values
      })
      .then(function(response) {
        // console.log('done')
        // setValues({...initialState});
        // console.log(params)
        params.handleUpdate(values);

        // updateList()
        // browserHistory.push("/main/customer");
        //  window.location.reload();
        // history.replace(`#/customer`);
        // history.push('#/billing');
        // history.go(1)
        // setValue(0);
        // console.log(response);
        // history.pushState(null, '/customer');
      })
      .catch(function(error) {
        //  setValues({ ...values, "billMsg": error});
        //  alert(error);
        //  window.location.reload();
        console.log(error);
      });
  };

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <NativeSelect className="width50" required onChange={handleSelect}>
            <option key={-1} value={-1}>
              Please Select Client
            </option>
            {custs.map((d, idx) => {
              return (
                <option value={idx} key={idx}>
                  {d.cust_name}
                </option>
              );
            })}
          </NativeSelect>

          {/* <TextField
    required
   
    label="Messrs"
    fullWidth
    autoComplete="billing Messrs"
    value={values.billMessrs}
    onChange={handleChange('billMessrs')} 
  /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Cheque Number"
            fullWidth
            value={values.ch_number}
            onChange={handleChange("ch_number")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            label="Cheque Date"
            fullWidth
            type="date"
            value={values.ch_date}
            onChange={handleChange("ch_date")}
          />
        </Grid>
        {/* <Grid item xs={12} sm={4}>
            <TextField
            
            label="Buyer's Phone"
            fullWidth
            
            value={values.InvBuyerPhone}
            onChange={handleChange('InvBuyerPhone')} 
          /> 
        
        </Grid> */}
        <Grid item xs={12} sm={6}>
          {/* <TextField
            label="Bank"
            fullWidth
            value={values.ch_bank}
            onChange={handleChange("ch_bank")}
          /> */}

<Autocomplete
        value={values.ch_bank}
        onChange={(event, newValue) => {
        //  setValue(newValue);
          setValues({ ...values, ["ch_bank"]: newValue });
        }}
        inputValue={values.ch_bank}
        onInputChange={(event, newInputValue) => {
        //  setInputValue(newInputValue);
          setValues({ ...values, ["ch_bank"]: newInputValue });
        }}
        
        options={options}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Controllable" variant="outlined" />}
      />

{/* 
 setValues({ ...values, [name]: event.target.value });
options={[ 
     { label: 'Al Baraka Bank Ltd.', id: 1 },
     { label: 'Allied Bank Ltd.', id: 2 },
     { label: 'Askari Bank Ltd.', id: 3 },
     { label: 'Bank Al Habib Ltd.', id: 4 },
     { label: 'Bank Alfalah Ltd.', id: 5 },
     { label: 'BAHL Islamic Bank', id: 6 },
     { label: 'BOK', id: 7 },
     { label: 'Cash', id: 8 },
     { label: 'Faysal Bank Ltd.', id: 9 },
     { label: 'Habib Bank Ltd.', id: 10 },
     { label: 'Habib Metro Bank', id: 10 },
     { label: 'IBFT Faysal Bank Ltd.', id: 12 },
     { label: 'IBFT HBL', id: 13 },
     { label: 'JS Bank Ltd.', id: 14 },
     { label: 'MCB', id: 15 },
     { label: 'Meezan Bank Ltd.', id: 16 },
     { label: 'Soneri Bank Ltd.', id: 17 },
     { label: 'Standard Chartered', id: 18 },
     { label: 'UBL', id: 19 },
]} */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className="boldInput"
            label="Amount"
            fullWidth
            value={values.ch_amount}
            onChange={handleChange("ch_amount")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Tax"
            fullWidth
            value={values.ch_tax}
            onChange={handleChange("ch_tax")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Description"
            fullWidth
            value={values.ch_description}
            onChange={handleChange("ch_description")}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
          >
            Submit
          </Button>
          &nbsp;&nbsp;
          <FormLabel>{values.billMsg}</FormLabel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
