import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Form from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
//import moment from "moment";
import axios from "axios";
import API from '../../config';
import { create, all } from "mathjs";
var moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  boldInput: {
    textAlign: "right",
    fontSize: 18
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const today = moment().tz('Asia/Karachi').format("YYYY-MM-DD");



export default function BillEntryForm({ billID,customers }) {
 
  if (billID === undefined) {
    billID = 0;
  }
 
  const config = {};
  const math = create(all, config);
  const classes = useStyles();
  // const [custs, setCusts] = useState([]);


  const [values, setValues] = useState({
    custs: customers,
    hscodes: [],
    billNumber: "Loading...",
    billDate1: today,
    billDate2: today,
    billItemCode: "",
    billVendorCode: "",
    billOrderNumber: "",
    billMessrs: "",
    billQty: "",
    billParticullars: "",
    billRate: "",
    billAmount: 0,
    billGST: 18,
    billGSTAmount: 0,
    billSED: 0,
    billSEDAmount: 0,
    billAGST: 0,
    billAGSTAmount: 0,
    billTotal: 0,
    billMsg: "",
    bill_to_emails: "nafeesenter@gmail.com",
    bill_send_email: true,
    bill_cust_id: -1,
    bill_id: billID,
    bill_hscode:-1,
    InvBuyerName: "",
    InvBuyerAddr: "",
    InvBuyerPhone: "",
    InvBuyerSTRN: "",
    InvBuyerNTN: "",
    InvDesc: "",
    InvQty: 0,
    InvUnitPrice: 0,
    InvVEST: 0,
    InvROST: 0,
    InvAOST: 0,
    InvSEDRate: 0,
    InvSEDAmt: 0,
    InvVIST: 0,
    InvTotal: 0,
    InvQtyKG: "Qty",
    billChallan:"",
    stock_weight:0,
    stock_weight_paper:0,
    
  });
const loadByChallan=()=>{
  
//let challans = values.billChallan

  axios
  .post(
    `${API}/invoice/loadBillByChallan`,
 
    {
     'chllan_number':values.billChallan
    }
  )
  .then(function(response) {
    let challan_qty=0;
    let challan_amount=0;
    let challan_total=0;
    let challan_gst_amount=0;
    let challan_sed_amount=0;
    let challan_agst_amount=0;
    let challan_material_consumption=0;
    let challan_paper_consumption=0;
    if( response.data.length==0)
    {
      alert('Challan number is not found!');
      setValues({ ...values, "billChallan": '' });
      return;
    }
    response.data.forEach((m,idx)=>{

      challan_qty+=m.challan_qty;
      challan_amount+=m.challan_amount;
      challan_total+=m.challan_total;
      challan_gst_amount+=m.challan_gst_amount;
      challan_sed_amount+=m.challan_sed_amount;
      challan_agst_amount+=m.challan_agst_amount;
      challan_material_consumption+=m.challan_material_consumption;
      challan_paper_consumption+=m.challan_paper_consumption;
    });
   let ch = response.data[0];
   console.log(ch)
    setValues({
   ...values,
   billQty: challan_qty,
   InvQtyKG:ch.challan_qty_kg,
   //billDate1: ch.challan_date1.split("T")[0],
   billRate: ch.challan_price,
   billOrderNumber: ch.challan_order_mumber,
   billTotal: challan_total,
   billp: ch.challan_price,
   InvBuyerName: ch.challan_client_name,
   bill_cust_id: ch.challan_cust_id,
   billParticullars: ch.challan_particullars,
   // billOrderNumber:ch.challan_order_mumber,
   billItemCode: ch.challan_item_code,
   billAmount: challan_amount,
   billGST: ch.challan_gst_rate,
   billGSTAmount: challan_gst_amount,
   billSED: ch.challan_sed_rate,
   billSEDAmount: challan_sed_amount,
   billAGST: ch.challan_agst_rate,
   billAGSTAmount: challan_agst_amount,
   billVendorCode: ch.challan_vendor_code,
   stock_weight:challan_material_consumption,
   stock_weight_paper:challan_paper_consumption,
   //hscodes:h,
   //custs: c,
   bill_hscode:ch.challan_hscode
    });

  })
  .catch(function(error) {
    console.log(error.response);
    setValues({ ...values, billMsg: "Failed Due To Server Error!" });
    //  alert(error);
    //  window.location.reload();

    // console.log(error);
  });








}
  const handleSubmit = event => {

    console.log('handleSubmit')
    event.preventDefault();
    values.bill_to_emails = values.bill_send_email ? values.bill_to_emails : "";

    if (values.InvBuyerName == "") {
      alert("Please Select Client!");
      event.preventDefault();
      return false;
    }
    if (values.bill_hscode== "-1") {
      alert("Please Select HSCode!");
      event.preventDefault();
      return false;
    }
    setValues({
      ...values,
      billMsg: values.bill_id != 0 ? "Updating..." : "Generating..."
    });

axios.post(`${API}/challan/isChallanUsed/`,{
  challan_number:values.billChallan
}).then(function(response){
  if (response.data == "" || billID!==0) {
    axios
    .post(
      `${API}/invoice/billgenerate`,
      //'http://localhost:3001/invoice/billgenerate',
      {
        ...values
      }
    )
    .then(function(response) {
      if (response.data != "Internal Error!") {
        setValues({ ...values, billMsg: "Done!" });
       // alert(response.data);
       window.open(response.data, '_blank').focus();
       
        window.location.reload();
      } else {
        alert("Something is wrong! Please try again");
      }

      console.log(response);
    })
    .catch(function(error) {
      console.log(error.response.data);
      setValues({ ...values, billMsg: "Failed Due To Server Error!" });
      //  alert(error);
      //  window.location.reload();

      // console.log(error);
    });

  }
  else{
    setValues({ ...values, billMsg: response.data });
  }
}).catch(function(error){
  console.log(error.response.data);
  setValues({ ...values, billMsg: "Failed Due To Server Error!" });
})

  
  };
  const setCustomerValues = cust_id => {
    const cust = values.custs.filter(c => c.cust_id == cust_id)[0];

    setValues({
      ...values,
      billItemCode:
        values.bill_id != 0 ? values.billItemCode : cust.cust_itemcode,
      billVendorCode: cust.cust_vendorcode,
      InvBuyerName: cust.cust_name,
      InvBuyerAddr: cust.cust_addr,
      InvBuyerPhone: cust.cust_phone,
      InvBuyerSTRN: cust.cust_strn,
      InvBuyerNTN: cust.cust_ntn,
      bill_cust_id: cust.cust_id
    });
  };
  const handleSelect = event => {
    //console.log(custs[event.target.value])
    if (event.target.value !== -1) {
      setCustomerValues(event.target.value);
    }
  };
  const handleSelectHSCode = event => {
    const hscode = values.hscodes.filter(c => c.value == event.target.value)[0];
    if (event.target.value !== -1) {
    setValues({
      ...values,
      billParticullars:`${hscode.text}`,
      bill_hscode:hscode.value
    });
  }

  };
  const handleChangeChk = name => event => {
    // values[name]=event.target.value;
    // setState({ ...state, [name]: event.target.checked });
    setValues({ ...values, [name]: event.target.checked });
    //console.log(event.target.checked)
  };

  const handleChange = name => event => {
    console.log(name);
    try {
      values[name] = event.target.value;
      switch (name) {
        case "billGST":
        case "billSED":
        case "billQty":
        case "billAGST":
        case "billRate": {
          values["billAmount"] = math.evaluate(
            values["billQty"] * values["billRate"]
          );
          values["billGSTAmount"] = math.format(
            (values["billGST"] / 100) * values["billAmount"],
            { notation: "fixed", precision: 2 }
          );
          values["billSEDAmount"] = math.format(
            (values["billSED"] / 100) * values["billAmount"],
            { notation: "fixed", precision: 2 }
          );
          values["billAGSTAmount"] = math.format(
            (values["billAGST"] / 100) * values["billAmount"],
            { notation: "fixed", precision: 2 }
          );
          values["billTotal"] = math.round(
            math.sum(
              values["billAmount"],
              values["billGSTAmount"],
              values["billSEDAmount"],
              values["billAGSTAmount"]
            )
          );

          break;
        }

        default: {
        }
      }

      setValues({ ...values, [name]: event.target.value });
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    console.log("Bill Entry Form");
        var h= [];
    fetchHSCodes()
   
    async function fetchNumber() {
      const result = await axios(
        `${API}/invoice/billNewNumber`
      );
     
      setValues({ ...values, billNumber: result.data, hscodes:h });
    }
    async function fetchHSCodes() {

      const hscode = await axios(
        `${API}/invoice/getHSCodes`
      );
      h = hscode.data;
      if (values.bill_id != 0) {
        fetchBillByID();
      } else {
        fetchNumber();
      }
  
    }
  

    async function fetchBillByID() {
      console.log("fetchBillByID");
      const result = await axios.post(
        `${API}/invoice/getBillByID`,
        {
          bill_id: values.bill_id
        }
      );

      //   setValues({...values,custs:c,billQty: result.data[0].bill_qty,bill_cust_id:result.data[0].bill_cust_id})
      setValues({
        ...values,
        bill_id: result.data[0].bill_id,
        billQty: result.data[0].bill_qty,
        InvQtyKG:result.data[0].bill_qty_kg,
        billNumber: result.data[0].bill_invoice_num,
        billDate1: result.data[0].bill_date1.split("T")[0],
        billRate: result.data[0].bill_price,
        billOrderNumber: result.data[0].bill_order_mumber,
        billTotal: result.data[0].bill_total,
        billp: result.data[0].bill_price,
        InvBuyerName: result.data[0].bill_client_name,
        bill_cust_id: result.data[0].bill_cust_id,
        billParticullars: result.data[0].bill_particullars,
        // billOrderNumber:result.data[0].bill_order_mumber,
        billItemCode: result.data[0].bill_item_code,
        billAmount: result.data[0].bill_amount,
        billGST: result.data[0].bill_gst_rate,
        billGSTAmount: result.data[0].bill_gst_amount,
        billSED: result.data[0].bill_sed_rate,
        billSEDAmount: result.data[0].bill_sed_amount,
        billAGST: result.data[0].bill_agst_rate,
        billAGSTAmount: result.data[0].bill_agst_amount,
        billVendorCode: result.data[0].bill_vendor_code,
        hscodes:h,
        bill_hscode:result.data[0].bill_hscode
      });
      //console.log(values.billOrderNumber)
      //console.log(moment(result.data[0].bill_date1.split('T')[0]).format("mm/dd/yyyy"))
    }
  }, []);



  useEffect(() => {
    if (values.bill_cust_id != -1) {
      setCustomerValues(values.bill_cust_id);
      // handleTotals('billRate');
    }
  }, [values.bill_cust_id]);
  return (
    <React.Fragment>
      {/* 
        <Typography variant="h6" gutterBottom>
       Bill
      </Typography>
     */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <NativeSelect
              className="width50"
              value={values.bill_cust_id}
              required
              onChange={handleSelect}
            >
              <option>Please Select Client</option>
              {values.custs.map((d, idx) => {
                return (
                  <option value={d.cust_id} key={idx}>
                    {d.cust_name}
                  </option>
                );
              })}
            </NativeSelect>

          </Grid>
          <Grid item xs={3}>
 

            <TextField
      required
           
            label="Challan #"
         
            value={values.billChallan}
            onChange={handleChange('billChallan')} 
          /> 
               <Button
              variant="contained"
              type="button"
              color="primary"
              className={classes.button}
              onClick={()=>loadByChallan()}
            >
             Load
            </Button>
          </Grid>
          <Grid item xs={3}>
 

 <TextField
required

 label="Material Consum."

 value={values.stock_weight}
 onChange={handleChange('stock_weight')} 
/> 
<TextField
required

 label="Paper Consum."

 value={values.stock_weight_paper}
 onChange={handleChange('stock_weight_paper')} 
/> 
</Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              id="billNumber"
              name="billNumber"
              label="Bill Number"
              fullWidth
              value={values.billNumber}
              onChange={handleChange("billNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              label="Date1"
              fullWidth
              type="date"
              value={values.billDate1}
              onChange={handleChange("billDate1")}
            />
          </Grid>

          {/* <Grid item xs={12} sm={4}>
          <TextField 
         
          label="Date2" 
          fullWidth  
          type="date"
          value={values.billDate2}
          onChange={handleChange('billDate2')}
          />
         
        </Grid> */}

          <Grid item xs={12} sm={3}>
            <TextField
              required
              label="Order Number"
              fullWidth
              value={values.billOrderNumber}
              onChange={handleChange("billOrderNumber")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Item Code"
              fullWidth
              autoComplete="billing billItemCode"
              value={values.billItemCode}
              onChange={handleChange("billItemCode")}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
       
          <NativeSelect
              className="width50"
              value={values.bill_hscode}
              required
              onChange={handleSelectHSCode}
            >
              <option value="-1">Please Select HSCode</option>
              {values.hscodes.map((d, idx) => {
                return (
                  <option value={d.value} key={idx}>
                    {d.text}
                  </option>
                );
              })}
            </NativeSelect>
            <TextField
              required
              label="Particullars"
              fullWidth
              value={values.billParticullars}
              onChange={handleChange("billParticullars")}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <TextField
              required
              // label="Qty or KG"

              value={values.InvQtyKG}
              onChange={handleChange("InvQtyKG")}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" }
              }}
              // label="Qty/KG"
              fullWidth
              value={values.billQty}
              onChange={handleChange("billQty")}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" }
              }}
              label="Rate"
              fullWidth
              value={values.billRate}
              onChange={handleChange("billRate")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" }
              }}
              value={values.billAmount}
              onChange={handleChange("billAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              required
              label="GST %"
              fullWidth
              value={values.billGST}
              inputProps={{
                style: { textAlign: "right" }
              }}
              onChange={handleChange("billGST")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="GST Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" }
              }}
              value={values.billGSTAmount}
              onChange={handleChange("billGSTAmount")}
            />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" }
              }}
              label="SED %"
              fullWidth
              value={values.billSED}
              onChange={handleChange("billSED")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="SED Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" }
              }}
              value={values.billSEDAmount}
              onChange={handleChange("billSEDAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              inputProps={{
                style: { textAlign: "right" }
              }}
              label="AGST %"
              fullWidth
              value={values.billAGST}
              onChange={handleChange("billAGST")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="AGST Amount"
              fullWidth
              disabled
              inputProps={{
                style: { textAlign: "right" }
              }}
              value={values.billAGSTAmount}
              onChange={handleChange("billAGSTAmount")}
            />
          </Grid>

          <Grid item xs={12} sm={4}></Grid>

          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              inputProps={{
                style: { textAlign: "right", fontWeight: "bold" }
              }}
              label="Total"
              fullWidth
              disabled
              value={values.billTotal}
              onChange={handleChange("billTotal")}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className={classes.button}
            >
              {values.bill_id != 0 ? "Update" : "Generate"}
            </Button>
            &nbsp;&nbsp;
            <FormLabel>{values.billMsg}</FormLabel>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="bill_send_email"
                  checked={values.bill_send_email}
                />
              }
              label="Send Email?"
              onChange={handleChangeChk("bill_send_email")}
            />
            <TextField
              label="Email To Address(es):"
              fullWidth
              value={values.bill_send_email ? values.bill_to_emails : ""}
              onChange={handleChange("bill_to_emails")}
            />
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
