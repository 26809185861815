import React,{ useState,useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import NativeSelect from "@material-ui/core/NativeSelect";
import axios from 'axios';
import API from '../../config';
const useStyles = makeStyles(theme => ({
   
  }));
  
  export default function BillEntryForm(params) {

    const classes = useStyles();  

    useEffect(()=>{

    },[params.data]);

    const handleSelect = event => {
      //console.log(custs[event.target.value])
      if (event.target.value !== -1) {
        setValues({...values,"cust_type":event.target.value});
      }
    };
    const initialState = { 
    cust_name:"", 
    cust_addr:"", 
    cust_phone:"",
    cust_email:"",
    cust_strn:"",
    cust_ntn:"",
    cust_itemcode:"",
    cust_vendorcode:"",
    billMsg:"",
    cust_type:"output"
  }
  //  const theme = useTheme();
  //  const [value, setValue] = React.useState(0);
    const [values, setValues] = useState(initialState);

      const handleSubmit =()=>{

        setValues({ ...values, "billMsg": "Saving..."});
 axios.post(
             
               `${API}/customer/insertCustomer`, 
              {
               ...values
              })
              .then(function (response) {
               // console.log('done')
                setValues({...initialState});
               // console.log(params)
                params.updateList(values)
               // browserHistory.push("/main/customer");
               // window.location.reload();
               // history.replace(`#/customer`);
               // history.push('#/billing');
              // history.go(1)
              // setValue(0);
               // console.log(response);
              // history.pushState(null, '/customer');
              })
              .catch(function (error) {
              //  setValues({ ...values, "billMsg": error});
              //  alert(error);
              //  window.location.reload();
                console.log(error);
              });
    
      }

  const handleChange = name => event => {

   setValues({ ...values, [name]: event.target.value });

   

  };



  return (
    <React.Fragment>


       
          
   
          <Grid container spacing={3}>
           <Grid item xs={12} sm={12}>
            <TextField
            label="Buyer's Name"
            fullWidth
            
            value={values.cust_name}
            onChange={handleChange('cust_name')} 
          />
       
        </Grid> 
           <Grid item xs={12} sm={12}>
              <TextField
            
            label="Buyer's Address"
            fullWidth
            
            value={values.cust_addr}
            onChange={handleChange('cust_addr')} 
          />
        
        </Grid>
           {/* <Grid item xs={12} sm={4}>
            <TextField
            
            label="Buyer's Phone"
            fullWidth
            
            value={values.InvBuyerPhone}
            onChange={handleChange('InvBuyerPhone')} 
          /> 
        
        </Grid> */}
           <Grid item xs={12} sm={6}>
        <TextField
            
            label="Buyer's STRN"
            fullWidth
            
            value={values.cust_strn}
            onChange={handleChange('cust_strn')} 
          />
        
        </Grid>
           <Grid item xs={12} sm={6}>
        <TextField
            className="boldInput"
            label="Buyer's NTN"
            fullWidth
            
            value={values.cust_ntn}
            onChange={handleChange('cust_ntn')} 
          />
        
        </Grid>
           <Grid item xs={12} sm={6}>
                  <TextField
                    
                   
                    label="Item Code"
                    fullWidth
                    autoComplete="billing billItemCode"
                    value={values.cust_itemcode}
                    onChange={handleChange('cust_itemcode')}
                  />
                </Grid>
           <Grid item xs={12} sm={6}>
                  <TextField
                   
                    label="Vendor Code"
                    fullWidth
                    autoComplete="billing billVendorCode"
                    value={values.cust_vendorcode}
                    onChange={handleChange('cust_vendorcode')} 
                  />
                </Grid> 
                <Grid item xs={12} sm={6}>
                <NativeSelect
              className="width50"
              value={values.cust_type}
              required
              onChange={handleSelect}
            >
            
              <option value="output" >
                    Output Inv Customer
                  </option>
                  <option value="input" >
                    Input Inv Customer
                  </option>
            </NativeSelect>
                </Grid> 
           <Grid item xs={12}>
              <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.button}
                          >
                            Submit
                          </Button>&nbsp;&nbsp;
                          <FormLabel>{values.billMsg}</FormLabel>
                </Grid>
          </Grid>
           

  
        
         </React.Fragment>
  );
}