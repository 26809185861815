import React,{ useEffect,useState } from 'react';
import axios from 'axios';
import MaterialTable from 'material-table';
import { Modal, Button, Dialog } from '@material-ui/core';
import API from '../../config';
import OrderAPI, { ChallanAPI, CustomersAPI, HSCodesAPI } from "../../BL";
export default function CustomerList(props) {

  const custAPI =   new CustomersAPI();
  const [state, setState] = React.useState({
    columns: [
    
      
      { title: 'Name',  field: 'cust_name'},
      { title: 'Address', field: 'cust_addr' },
      {
        title: 'Items',
        render: rowData => (
          <button onClick={() => handleButtonClick(rowData)}>View</button>
        )
      }
   
    ],
    data: [ ]
  });
const [items,setItems]=React.useState(
  {
    columns: [
  { title: 'Item',  field: 'cust_item_name'},
  { title: 'Desc.',  field: 'cust_item_description'},
  { title: 'Rate', field: 'cust_item_rate' , 
  render: rowData => (
    rowData.cust_item_rate !== undefined ? 
    (rowData.cust_item_rate % 1 === 0 ? rowData.cust_item_rate.toFixed(0) : rowData.cust_item_rate.toFixed(2))
    : ""
  )
}
],
data: [ ],
isLoading:true
});
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  useEffect( () => {


    // if(Object.keys(props.data).length){
    //   const data = [...state.data];
    //           data.push(props.data);
    //           setState({ ...state, data });
    // }
      
    //    else
       fetchList()
    },[props.data]);
    async function fetchList(){
  
custAPI.getCustomersNonTax().then((result)=>{
  setState({ ...state, "data":result});
}).catch((e)=>{alert(e)})
               
          
  
     }
    const handleButtonClick =  (rowData)=>{
      
      setSelectedRow(rowData);
      setShowModal(true);
      fetchItems(rowData);
        
    }
    const fetchItems = async(rowData)=>{
      setItems({ ...items, data:[],isLoading:true})


      custAPI.getCustomerItems(rowData.cust_id).then((result)=>{

        setItems({ ...items, data:result,isLoading:false})
      }).catch((e)=>{alert(e)})


      // const result = await axios.post(
      //   `${API}/customer/getCustomerItems`,{cust_non_tax_id:rowData.cust_id},
      //   );
      //  setItems({ ...items, data:result.data,isLoading:false})
    }
  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
<div>
    <MaterialTable
      title="Non-Tax Customer List"
      columns={state.columns}
      data={state.data}
      options={{      
        addRowPosition: 'first',
        paging: false
      }}
       editable={{
        
          onRowAdd: newData =>
            new Promise(resolve => {
         
              axios.post(`${API}/customer/insertCustomerNonTax/`, {
    
                  ...newData
              })
                  .then(function (response) {
    
                      resolve();
                      fetchList();
    
    
                  })
                  .catch(function (error) {
                      alert('Server Error');
                      resolve();
                      console.log(error);
                  });
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            
            axios.post(`${API}/customer/updateCustomerNonTax/`, {
            
            ...newData
            })
            .then(function (response) {
      
              resolve();
              const data = [...state.data];
              data[data.indexOf(oldData)] = newData;
              setState({ ...state, data });
            
           
            })
            .catch(function (error) {
             // message = error;
             alert(error);
            });
          }),
        onRowDelete: oldData =>

          new Promise(resolve => {

            axios.post(`${API}/customer/deleteCustomerNonTax/`, {

              cust_id: oldData.cust_id
            })
            .then(function (response) {
            // console.log(response);
             resolve();
             const data = [...state.data];
             data.splice(data.indexOf(oldData), 1);
             setState({ ...state, data });


            })
            .catch(function (error) {
             // message = error;
             console.log(error);
            });

       
          }),
    
  
  }}

  
    />
    {showModal && (
        <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        fullWidth="true"
        maxWidth="lg"
      
        >
       <div   style={{padding:'5px 5px 5px 5px'}}>
       <div style={{ textAlign: 'right' }}>  <Button justify="flex-end" color='primary' onClick={handleCloseModal}>Close</Button> </div>
          <h2 id="simple-modal-title">{selectedRow?.cust_name} &nbsp;{items.isLoading &&  "Loading..."} 
         
          </h2>
          <MaterialTable
      title="Customer Item List"
      columns={items.columns}
      data={items.data} 
    
      options={{
        addRowPosition: 'first',
        paging: false
      }}
       editable={{
        onRowAdd: newData =>
        new Promise(resolve => {
          newData.cust_non_tax_id = selectedRow.cust_id;
          axios.post(`${API}/customer/insertCustomerItem/`, {

              ...newData
          })
              .then(function (response) {

                  resolve();
                  fetchItems(selectedRow);


              })
              .catch(function (error) {
                  alert('Server Error');
                  resolve();
                  console.log(error);
              });
      }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            
            axios.post(`${API}/customer/updateCustomerItems/`, {
            
            ...newData
            })
            .then(function (response) {
      
              resolve();
          fetchItems(selectedRow);
            
           
            })
            .catch(function (error) {
              alert(error)
             // message = error;
             console.log(error);
            });
          }),
        onRowDelete: oldData =>

          new Promise(resolve => {

            axios.post(`${API}/customer/deleteCustomerItem/`, {

              cust_item_id: oldData.cust_item_id
            })
            .then(function (response) {
            // console.log(response);
             resolve();
            fetchItems(selectedRow);


            })
            .catch(function (error) {
             // message = error;
             console.log(error);
            });

       
          }),
    
  
  }}
    />
          
        </div>

        </Dialog>
      )}
</div>
 
  );
}