import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';


import App from './App';
import * as serviceWorker from './serviceWorker';
import { StoreProvider } from "./views/store/useStore";
ReactDOM.render(<StoreProvider><App /></StoreProvider>, document.getElementById('root'));
serviceWorker.unregister();

    
