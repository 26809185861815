import React from "react";
import Button from "@material-ui/core/Button";
import ChequeList from "./cheque_list";
import { Popup } from "./cheque_popup";

export default function Cheque({customers}) {

  const [show, setShow] = React.useState(false);
  const [loadAgain, setloadAgain] = React.useState(false);


  function updateList() {

    console.log('setloadAgain')
    setloadAgain(true)
  }
  function handleClose(newValue) {
      console.log('handleClose')
    setShow(false);
    setloadAgain(!loadAgain)
    // if (newValue) {
    //   setValue(newValue);
    // }
  }
  const openPopup = () => {
    setShow(true);
  };
  return (
    <div>
        
      {show && <Popup handleClose={handleClose} customers={customers}  cheques={[]}></Popup>}
      <Button variant="contained" color="primary" onClick={() => openPopup()}>
        Add Cheque
      </Button>
     
      <hr />

      <ChequeList data={loadAgain} updateList={updateList} customers={customers} ></ChequeList>
    </div>
  );
}
